export const notificationData = [
    {
      data: "Some data 1",
      image: "image1.jpg",
      name: "Joe Doe",
      content: "Followed your collection",
      day: "Monday"
    },
    {
      data: "Some data 2",
      image: "image2.jpg",
      name: "John Van",
      content: "Your Summary is ready",
      day: "Tuesday"
    },
    {
      data: "Some data 3",
      image: "image3.jpg",
      name: "Tikka",
      content: "XYZ added to your collection",
      day: "Wednesday"
    },
    {
      data: "Some data 4",
      image: "image4.jpg",
      name: "Joe Don",
      content: "commented on your bookmark Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut laoreet dolore",
      day: "Thursday"
    },
    {
      data: "Some data 5",
      image: "image5.jpg",
      name: "Sooth",
      content: " your bookmark Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed",
      day: "Friday"
    }
  ];
  
  