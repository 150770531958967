import dayjs from "lib/dayjs";

export class User {
  #id;
  #created;
  #email;
  #emailConfirmed;
  #lastLogin;
  #groupId;

  constructor({
    userId,
    userName,
    email,
    emailConfirmed,
    registerationDate,
    roleName,
    lastLoginTime,
    isDeleted,
    organizationId,
  }) {
    this.#id = userId;
    this.#created = registerationDate;
    this.#email = email;
    this.#emailConfirmed = emailConfirmed;
    this.#lastLogin = lastLoginTime;
    this.#groupId = organizationId;
    this.userName = userName;
    this.roleName = roleName;
    this.isDeleted = isDeleted;
  }

  get id() {
    return this.#id;
  }
  get registerationDate() {
    return this.#created;
  }
  get createdDateCSV() {
    return dayjs(this.#created).format("lll");
  }
  get email() {
    return this.#email;
  }
  get emailConfirmed() {
    return this.#emailConfirmed;
  }
  get lastLoginTime() {
    return this.#lastLogin;
  }
  get lastLoginCSV() {
    return this.#lastLogin == null ? "" : dayjs(this.#lastLogin).format("lll");
  }
  get isInGroup() {
    return this.#groupId != null;
  }
}
