import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";

import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import { useAuth } from "hooks/use-auth.hook";

import { ChangePassword, ProfileQuickBox, useUserData } from "features/User";
import {
  Edit,
  GroupOutlined,
  LogoutOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { NavLink } from "react-router-dom";
import { Masonry } from "@mui/lab";
import { useGroup } from "features/Group";
// import EditUsername from "features/User/components/popups/EditUsername";
// import Stats from "features/User/components/Stats";

export default function ProfileIndexPage() {
  const { groupId, groupRole } = useUserData();
  const { group } = useGroup();
  // const [editUsername, setEditUsername] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const theme = useTheme();
  const { microsoftUser, user } = useSelector((state) => state.auth);
  const { instance } = useMsal();

  const { handleLogout } = useAuth({
    handleLoading: () => {},
  });

  const buttonStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  };

  // const openEditUsername = () => {
  //   setEditUsername(true);
  // };

  return (
    <Box sx={{ ...fillParent, ...scrollingBox, ...footerFlex }}>
      <Stack direction="column" gap={2}>
        <PageTitle sx={{ wordBreak: "break-word" }}>
          Hey, {user.userName}.
        </PageTitle>
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }}>
          {/* <Box
          sx={{
            width: { sm: "100%", md: "30%" },
            backgroundColor: "#e7edf2",
            p: 4,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "#406da4",
              fontSize: "2rem",
              fontStyle: "italic",
              mb: 2,
            }}
          >
            Time for a new user name?
          </Typography>
          <Typography sx={{ mt: "auto" }}>
            Though you can’t type your own username, you can generate a new one
            anytime
          </Typography>
          <Box sx={{ marginLeft: "auto", mt: "auto" }}>
            <Button
              onClick={openEditUsername}
              variant="outlined"
              sx={{
                mt: 2,
              }}
            >
              <HiOutlinePencil size={"1.8rem"} color="#406da4" />
              <Typography sx={{ color: "black", ml: 1 }}>
                Edit username
              </Typography>
            </Button>
          </Box>
        </Box> */}
          {group != null && (
            <ProfileQuickBox
              title="Group Membership"
              description="You are currently a member of a group."
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="caption" fontWeight="bold">Group:</Typography>
                <Typography variant="body2">{group.name}</Typography>
              </Stack>
            </ProfileQuickBox>
          )}
          {groupId != null && groupRole === "OrganizationAdmin" && (
            <Fragment>
              <ProfileQuickBox
                title="Manage your group's users?"
                description="Add, remove or update user information from the user management screen."
              >
                <Button
                  LinkComponent={NavLink}
                  to="/profile/group/user-management"
                  variant="outlined"
                  startIcon={<GroupOutlined />}
                  sx={buttonStyles}
                >
                  User Management
                </Button>
              </ProfileQuickBox>
              <ProfileQuickBox
                title="Update Group Controls?"
                description="Manage group codes, auto-join domains and more with the group controls."
              >
                <Button
                  LinkComponent={NavLink}
                  to="/profile/group/group-controls"
                  variant="outlined"
                  startIcon={<TuneOutlined />}
                  sx={buttonStyles}
                >
                  Group Controls
                </Button>
              </ProfileQuickBox>
            </Fragment>
          )}
          <ProfileQuickBox
            title="Need a new password?"
            description="Always use strong passwords to keep your account secure."
          >
            <Button
              onClick={() => setChangePassword(true)}
              variant="outlined"
              startIcon={<Edit />}
              sx={buttonStyles}
            >
              Change Password
            </Button>
          </ProfileQuickBox>
          <ProfileQuickBox
            title="Public Computer?"
            description="Keep your account safe by logging out when you leave a public computer."
          >
            <Button
              onClick={() => {
                handleLogout(microsoftUser, instance);
              }}
              variant="outlined"
              sx={buttonStyles}
              startIcon={<LogoutOutlined />}
            >
              Logout
            </Button>
          </ProfileQuickBox>
        </Masonry>
        {/* <Stats /> */}
      </Stack>
      <Footer />
      {/*editUsername && (
        <EditUsername
          editUsername={editUsername}
          setEditUsername={editUsername}
          onClose={() => setEditUsername(false)}
        />
      )*/}
      {changePassword && (
        <ChangePassword
          changePassword={changePassword}
          setChangePassword={setChangePassword}
          onClose={() => setChangePassword(false)}
        />
      )}
    </Box>
  );
}
