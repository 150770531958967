import useInfiniteScrollCategory from "../hooks/use-infinite-scroll-category.hook";

const CategoryScrollComponent = ({
  offset,
  setLoading,
  categoryData,
  setcategoryData,
  setoffset,
  setShouldLoad,
  setHasMore,
  setScrollLoading,
}) => {
  const { error } = useInfiniteScrollCategory(
    offset,
    setLoading,
    setcategoryData,
    setHasMore,
  );
};

export default CategoryScrollComponent;
