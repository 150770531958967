export const individualBasePricing = {
  k12: {
    yearly: 40,
    monthly: 4,
  },
  highEd: {
    yearly: 60,
    monthly: 6,
  },
  gov: {
    yearly: 80,
    monthly: 8
  }
};

export const prorationMonths = [
  { label: "January", key: 0 },
  { label: "February", key: 1 },
  { label: "March", key: 2 },
  { label: "April", key: 3 },
  { label: "May", key: 4 },
  { label: "June", key: 5, default: true },
  { label: "July", key: 6 },
  { label: "August", key: 7 },
  { label: "September", key: 8 },
  { label: "October", key: 9 },
  { label: "November", key: 10 },
  { label: "December", key: 11 },
]
