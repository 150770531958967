export const scrollingBox = {
  overflowY: { xs: "unset", sm: "auto" },
  px: 3,
};
export const fillParent = {
  position: { xs: "unset", sm: "absolute" },
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
export const footerFlex = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
