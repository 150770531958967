import { find } from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  Alert,
  AlertTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";

import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import {
  useSubscriptions,
  IndividualPricing,
  useUserData,
} from "features/User";

import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { FeatureHelpVideo } from "features/Support";

let stripePromise = null;

export default function IndividualSignUpPage() {
  const { shape } = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isMobile = useIsMobile();
  const { groupId, groupRole } = useUserData();
  const {
    checkPromoCode,
    coupons,
    createCheckoutSession,
    expireCheckoutSession,
    getIndividualPlanBasePrice,
    products,
    subsLoading,
  } = useSubscriptions();

  const [gradeLevel, setGradeLevel] = useState("k12");
  const [planLength, setPlanLength] = useState("yearly");
  const [basePrice, setBasePrice] = useState(
    getIndividualPlanBasePrice(gradeLevel, planLength)
  );
  const [autoRenew] = useState(true);
  const [activeProduct, setActiveProduct] = useState(null);
  const [checkoutSession, setCheckoutSession] = useState(null);
  const [activeCoupon, setActiveCoupon] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoError, setPromoError] = useState(null);

  function handleSelectionChange(value, setFn) {
    setFn(value);
  }

  async function handleProductSelectionContinue() {
    if (activeProduct == null) {
      return;
    }
    setLoading(true);
    try {
      const session = await createCheckoutSession(
        activeProduct.id,
        activeCoupon != null ? activeCoupon.id : "",
        autoRenew ? "subscription" : "payment"
      );
      setCheckoutSession(session);
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  async function handleProductSelectionBack() {
    setLoading(true);
    try {
      const success = await expireCheckoutSession(checkoutSession.id);
      if (success) {
        setCheckoutSession(null);
      } else {
        toast.error("Oops! Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  function handleClearCoupon() {
    setActiveCoupon(null);
    setPromoError(null);
  }

  async function handleApplyCoupon() {
    if (promoCode.trim() === "") {
      setPromoError("Must enter a promo code.");
      return;
    }
    const coupon = await checkPromoCode(
      activeProduct.id,
      promoCode.trim().toUpperCase()
    );
    // const coupon = find(coupons, (coupon) => {
    //   return find(coupon.promoCodes, { code: promoCode }) != null;
    // });
    if (coupon != null) {
      setActiveCoupon(coupon);
      setPromoError(null);
    } else {
      setPromoError("Not a valid promo code.");
    }
  }

  function handleEnterKey(event, callback) {
    if (event.key === "Enter") {
      callback();
    }
  }

  useEffect(() => {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    return () => {
      stripePromise = null;
    };
  }, []);

  useEffect(() => {
    setBasePrice(getIndividualPlanBasePrice(gradeLevel, planLength));
    const planType = `individual-${gradeLevel}-${planLength}-${
      autoRenew ? "sub" : "nosub"
    }`;
    const activeProduct = find(
      products,
      (product) =>
        product.metadata.planType &&
        product.metadata.planType.toLowerCase() === planType.toLowerCase()
    );
    setActiveProduct(activeProduct);
  }, [gradeLevel, planLength, autoRenew, products, getIndividualPlanBasePrice]);

  useEffect(() => {
    async function findDefaultCoupon() {
      if (activeProduct == null) {
        return;
      }
      const defaultCoupon = find(coupons, (coupon) =>
        coupon.isForProduct(activeProduct.id)
      );
      if (defaultCoupon != null) {
        const defaultCode = find(
          defaultCoupon.promoCodes,
          (code) => code.isDefault
        );
        if (defaultCode) {
          const checkedCoupon = await checkPromoCode(
            activeProduct.id,
            defaultCode.code
          );
          if (checkedCoupon != null) {
            setPromoCode(defaultCode.code);
            setActiveCoupon(checkedCoupon);
          } else {
            setActiveCoupon(null);
            setPromoCode("");
          }
        }
      } else {
        setActiveCoupon(null);
        setPromoCode("");
      }
    }
    findDefaultCoupon();
  }, [coupons, activeProduct]);

  useEffect(() => {
    if (groupId != null) {
      if (groupRole === "OrganizationAdmin") {
        navigate("/profile/subscription", {
          replace: true,
        });
      } else {
        navigate("/profile", { replace: true });
      }
    }
  }, []);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack
        direction="column"
        sx={{
          pr: isMobile ? 0 : "25vw",
        }}
      >
        <PageTitle>
          Individual Plan{" "}
          <FeatureHelpVideo
            videoId="984391120"
            title="Tip: How do I complete this page?"
          />
        </PageTitle>
        <Typography>
          You are purchasing an individual plan for yourself.
        </Typography>
        <Typography>
          Please select the appropriate plan for you from the options below:
        </Typography>
        {checkoutSession == null ? (
          <Fragment>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent={isMobile ? "center" : "space-between"}
              alignItems={isMobile ? "normal" : "center"}
            >
              <Box sx={{ flex: 6 }}>
                <ToggleButtonGroup
                  fullWidth
                  value={gradeLevel}
                  onChange={(event, value) =>
                    handleSelectionChange(value, setGradeLevel)
                  }
                  exclusive
                  sx={{
                    "& > button": {
                      flex: 1,
                    },
                  }}
                >
                  <ToggleButton value="k12">K-12</ToggleButton>
                  <ToggleButton value="highEd">Higher Ed</ToggleButton>
                  <ToggleButton value="gov">Gov</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box
                sx={{
                  flex: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ToggleButtonGroup
                  value={planLength}
                  onChange={(event, value) =>
                    handleSelectionChange(value, setPlanLength)
                  }
                  exclusive
                >
                  <ToggleButton value="yearly">Yearly</ToggleButton>
                  <ToggleButton value="monthly">Monthly</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {basePrice && (
                <IndividualPricing
                  base={basePrice}
                  coupon={activeCoupon}
                  planLength={planLength}
                  autoRenew={autoRenew}
                  sx={{
                    flex: 3,
                  }}
                />
              )}
            </Stack>
            {/* <FormGroup
              sx={{
                alignSelf: isMobile ? "flex-start" : "flex-end",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={autoRenew}
                    onChange={(event) =>
                      handleSelectionChange(event.target.checked, setAutoRenew)
                    }
                    size="small"
                  />
                }
                label="Auto-renew my subscription"
              />
            </FormGroup> */}
            {activeCoupon && promoCode !== "" ? (
              <Alert
                action={
                  <Button
                    color="inherit"
                    variant="text"
                    onClick={handleClearCoupon}
                  >
                    CLEAR
                  </Button>
                }
              >
                <AlertTitle>
                  {
                    find(
                      activeCoupon.promoCodes,
                      (promo) => promo.code === promoCode.toUpperCase()
                    ).code
                  }
                </AlertTitle>
                {
                  find(
                    activeCoupon.promoCodes,
                    (promo) => promo.code === promoCode.toUpperCase()
                  ).description
                }
              </Alert>
            ) : (
              <Stack direction="row" alignItems="flex-start">
                <TextField
                  value={promoCode}
                  onChange={(event) =>
                    setPromoCode(event.target.value.toUpperCase())
                  }
                  fullWidth
                  label="Promo Code"
                  helperText={promoError}
                  error={promoError != null}
                  onKeyUp={(event) => handleEnterKey(event, handleApplyCoupon)}
                />
                <Button onClick={handleApplyCoupon}>Apply</Button>
              </Stack>
            )}
            <Stack direction="row" sx={{ alignSelf: "flex-end" }}>
              <LoadingButton
                variant="contained"
                disabled={subsLoading || activeProduct == null}
                onClick={handleProductSelectionContinue}
                loading={loading}
              >
                Continue
              </LoadingButton>
            </Stack>
          </Fragment>
        ) : (
          <Stack direction={isMobile ? "column" : "row"}>
            <Box
              sx={{
                flex: 1,
                justifySelf: "start",
              }}
            >
              <Button
                startIcon={<ArrowBack />}
                variant="text"
                onClick={handleProductSelectionBack}
              >
                Back
              </Button>
            </Box>
            <Box
              sx={{
                flex: "auto",
                borderRadius: shape.borderRadius / 4,
                overflow: "hidden",
              }}
            >
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  clientSecret: checkoutSession.clientSecret,
                }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Box>
          </Stack>
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
