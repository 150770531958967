import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { authAPI } from "services/apis.service";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";

const EmailConfirm = () => {
  const [message, setMessage] = useState("Confirming your email.");
  const [checkDone, setCheckDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get(authAPI.confirmEmail, {
          params: {
            userId: searchParams.get("userId"),
            code: searchParams.get("code"),
          },
        });

        if (res.data) {
          setMessage("Email has been successfully confirmed");
        }
      } catch (error) {
        if (
          String(error.response.data).toLowerCase() ===
          "User Already Confirmed the email".toLowerCase()
        ) {
          setMessage("Email has already been confirmed");
        } else {
          console.log(error);
          setMessage("Some error occured. Please try again!");
          setIsError(true);
        }
      } finally {
        setCheckDone(true);
      }
    }

   setTimeout(() => getData(), 5000);
  }, []);

  return (
      <Stack direction="column" gap={2}>
        <Typography variant="h3">Email Confirmation</Typography>
        {!checkDone ? (
          <Alert severity="info">{message}</Alert>
        ) : (
          !isError ? (
            <Alert severity="success">{message}</Alert>
          ) : (
            <Alert severity="warning">{message}</Alert>
          )
        )}
        {!checkDone ? (
          <CircularProgress />
        ) : (
          <Button LinkComponent={Link} to="/">
            Sign In
          </Button>
        )}
      </Stack>
  );
};

export default EmailConfirm;
