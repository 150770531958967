import { Box, Stack, Tooltip, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import ProfileAvatar from "components/base/ProfileAvatar";
import { getFormattedDate } from "utils/time.util";
import { useSelector } from "react-redux";

export default function MarkDown({ note, item, onClick }) {
  const { user } = useSelector((state) => state.auth);

  const shortenedText =
    note.noteText_chr.length > 100
      ? `${note.noteText_chr.slice(0, 100)}...`
      : note.noteText_chr;

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      mb={1}
      onClick={() => onClick(item)}
      sx={{
        boxSizing: "border-box",
        cursor: "pointer",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ProfileAvatar userId={user.userId} />
        <Tooltip title={note.userName_chr}>
          <Typography variant="h6" color="grey" noWrap>
            @{note.userName_chr}
          </Typography>
        </Tooltip>
        <Typography variant="body1" color="grey">
          {`${getFormattedDate(note.lastUpdatedDateTime_dtm)}`}
        </Typography>
      </Stack>
      <ReactMarkdown
        children={shortenedText}
        components={{
          strong: ({ node, ...props }) => (
            <strong style={{ fontWeight: "bold" }} {...props} />
          ),
        }}
      />
    </Stack>
  );
}
