export const mediaBiasContent = (rating) => {
  if (rating == "Lean Left") {
    return "Sources with a Lean Left AllSides Media Bias Rating™ display bias in ways that moderately align with liberal, progressive, or left-wing thought and/or policy agendas. A Lean Left bias is a moderately liberal rating on the political spectrum.";
  } else if (rating == "Left") {
    return "Sources with a Left AllSides Media Bias Rating™ display bias in ways that strongly align with liberal, progressive, or left-wing thought and/or policy agendas. Some of these sources may be considered left-wing news.";
  } else if (rating == "Center") {
    return "Sources with a Center AllSides Media Bias Rating™ either do not show much political bias or display a balance of articles with left and right perspectives.";
  } else if (rating == "Left Right") {
    return "Sources with a Lean Right AllSides Media Bias Rating™ display media bias in ways that moderately align with conservative, traditional, libertarian, or right-wing thought and/or policy agendas. A Lean Right bias is a moderately conservative rating on the political spectrum.";
  } else if (rating == "Right") {
    return "Sources with a Right AllSides Media Bias Rating™ display media bias in ways that strongly align with conservative, traditional, or right-wing thought and/or policy agendas. Some of these sources or writers may be considered right-wing news.";
  }
  return "Sources with a Mixed AllSides Media Bias Rating™ aggregate news, perspectives, or content from multiple sources on the left, center, and right and transparently label those perspectives.";
};
