import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";

export default function GenericDialog({
  isOpen,
  handleClose,
  title,
  actions,
  children,
  ...props
}) {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          backgroundColor: palette.secondary.main,
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
}
