import { Box, useTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";
import { fillParent } from "utils/base-styles";
import Logo from "./Logo";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

export default function LoginSignUpLayout() {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
      <Box
        sx={{
          backgroundImage: "url(/images/bg2.jpg)",
          backgroundOrigin: "border-box",
          backgroundClip: "border-box",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ...fillParent,
        }}
      >
        {!isMobile && (
          <Logo
            isLandingPage={!isMobile}
            sx={{
              position: "absolute",
              top: "2rem",
              left: "2rem",
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: !isMobile ? "absolute" : "relative",
            right: 0,
            top: 0,
            bottom: 0,
            bgcolor: theme.palette.background.default,
            pt: { xs: 10, sm: 20 },
            pr: 2,
            pl: !isMobile ? 5 : 2,
            borderTopLeftRadius: { xs: 0, sm: theme.shape.borderRadius * 3 },
            borderBottomLeftRadius: {
              xs: 0,
              sm: theme.shape.borderRadius * 3,
            },
            overflow: "hidden",
            width: { xs: "100vw", sm: "70vw", md: "50vw" },
          }}
        >
          {isMobile && (
            <Logo isLandingPage={false} sx={{ marginBottom: "2rem" }} />
          )}
          <Outlet />
        </Box>
      </Box>
    </GoogleOAuthProvider>
  );
}
