import axios from "axios";
import { openaiAPI } from "services/apis.service";

export const customSummarize = async (summaryText) => {
  const data = await axios.post(
    openaiAPI.getSummary,
    {
      summary: summaryText,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
