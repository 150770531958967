import { CircularProgress } from "@mui/material";
import React from "react";

const LoaderPage = () => {
  return (
    <div className="loader-page">
      <CircularProgress />
    </div>
  );
};

export default LoaderPage;
