// BooksScrollComponent.js
import useInfiniteScrollBooks from "../hooks/use-infnite-book-scroll.hook";

const BooksScrollComponent = ({
  offset,
  setLoading,
  categoryData,
  setcategoryData,
  setoffset,
  setShouldLoad,
  setHasMore,
  setScrollLoading,
}) => {
  const { error } = useInfiniteScrollBooks(
    offset,
    setLoading,
    setcategoryData,
    setHasMore,
  );
};

export default BooksScrollComponent;
