import { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@mui/material";

export default function FormikTextField({
  handleChange,
  handleBlur,
  value,
  error,
  touched,
  label,
  helperText,
  size,
  sx,
  inputSx,
  fullWidth,
  ...otherProps
}) {
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (touched === undefined) {
      setIsTouched(true);
    } else {
      setIsTouched(!!touched);
    }
  }, [touched]);
  return (
    <FormControl
      error={isTouched && !!error}
      fullWidth={fullWidth}
      sx={{ ...sx }}
      size={size != null ? `${size}` : null}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            onBlur={handleBlur}
            sx={{ ...inputSx }}
            {...otherProps}
          />
        }
        label={label}
      />
      {(helperText || (isTouched && error)) && (
        <FormHelperText>{error ? error : helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
