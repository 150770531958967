import { useMemo } from "react";
import ReactPlayer from "react-player";
import { Button, Dialog, DialogTitle, IconButton, Link } from "@mui/material";
import { CloseOutlined, HelpOutlineOutlined } from "@mui/icons-material";
import { useIsMobile } from "hooks/is-mobile.hook";

export default function VideoDialog({ open, handleClose, title, id }) {
  const isMobile = useIsMobile();
  const maxWidth = useMemo(() => (isMobile ? "95vw" : "60vw"), [isMobile]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          maxWidth: isMobile ? null : maxWidth,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pr: 10
        }}
      >
        {title}{" "}
        <Button
          LinkComponent="a"
          href={`https://vimeo.com/video/${id}`}
          target="_blank"
          variant="text"
          size="small"
          startIcon={<HelpOutlineOutlined size="small" />}
          sx={({ typography }) => ({
            ...typography.body2,
          })}
        >
          Can't view video?
        </Button>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseOutlined />
      </IconButton>
      <ReactPlayer
        url={`https://vimeo.com/${id}`}
        width="100%"
        height={isMobile ? "53.44vw" : "33.75vw"}
        controls={true}
      />
    </Dialog>
  );
}
