import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";

import SigninButton from "./SigninButton";

import { loginRequest } from "msal";
import { setMicrosoftUser } from "context/features/authSlice";
import { authAPI } from "services/apis.service";
import { useAuth } from "hooks/use-auth.hook";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

import CleverImg from "assets/images/clever_icon.svg";
import ClassLinkImg from "assets/images/ClassLink.svg";
import GoogleImg from "assets/images/google_icon.svg";
import OfficeImg from "assets/images/microsoft-logo.png";
import { useDispatch } from "react-redux";

export default function ServiceSignin({ handleLoading }) {
  const {
    handleExternalLogin,
    redirectToCleverOAuth,
    redirectToClasslinkOAuth,
  } = useAuth({
    handleLoading,
    toast,
  });
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { instance } = useMsal();

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        handleExternalLogin(
          response.access_token,
          "Google",
          authAPI.googleLogin
        );
      } catch (error) {
        console.error(error);
      }
    },
  });

  function handleCustomMicrosoft() {
    dispatch(setMicrosoftUser(true));
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <React.Fragment>
      <Typography variant="body1">
        Use your preferred service to sign in
      </Typography>
      <Stack direction={isMobile ? "column" : "row"} gap={2}>
        <SigninButton onClick={googleLogin} image={GoogleImg} title="Google" />
        <SigninButton
          onClick={handleCustomMicrosoft}
          image={OfficeImg}
          title="Microsoft"
        />
        <SigninButton
          link={redirectToCleverOAuth()}
          image={CleverImg}
          title="Clever"
        />
        <SigninButton
          link={redirectToClasslinkOAuth()}
          image={ClassLinkImg}
          title="ClassLink"
        />
      </Stack>
    </React.Fragment>
  );
}
