import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import {
  setCollectionsSidebar,
  setIsBookmarkSidebar,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setShowAddToCollectionsField,
} from "context/features/dataSlice";

import MuiNavbar from "components/layout/MuiNavbar";
import Sidebar from "components/base/Sidebar";
import LabelBottomNavigation from "components/layout/MuiNavbar/BottomBar";
import SideMenu from "features/Collections/components/SideMenu";
import NewCollectionPopup from "features/Collections/components/NewCollection";
import NewSubCollectionPopup from "features/Collections/components/CreateNewSubFolder";
import CustomBookmark from "features/Collections/components/CustomBookmark";
import useCollection from "features/Collections/hooks/use-collection.hook";
import ExternalBookmarkDrawer from "features/ExternalBookmark";
import BookmarkDrawer from "features/Bookmarks";
import SourceSidebar from "features/SourceInfo";

export default function CollectionsLayout() {
  const [newPopup, setNewPopup] = useState(false);
  const [newBookmarkPopup, setNewBookmarkPopup] = useState(false);
  const [subPopup, setSubPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    isSourceSidebarOpen,
    collectionsSidebar,
    isBookmarkSidebar,
    isSidebarOpen,
  } = useSelector((state) => state.data);
  const isMobile = useIsMobile();

  const externalBookmarkCloseHandler = () => {
    dispatch(setIsBookmarkSidebar(false));
    dispatch(setShowAddToCollectionsField(false));
  };

  const showExternalBookmarkSidebar = () => {
    dispatch(isBookmarkSidebar(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };
  const sidebarClosedHandler = () => {
    dispatch(setIsSidebarOpen(false));
    document.body.style.overflow = "unset";
  };
  const showSidebar = () => {
    dispatch(setIsSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const sourceSidebarClosedHandler = () => {
    dispatch(setIsSourceSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const showSourceSidebar = () => {
    dispatch(setIsSourceSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const { collections } = useSelector((state) => state.data);
  const { collectionIdArray, saveCollection, handleSaveCustomBookmark } =
    useCollection(setLoading);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "auto 1fr",
          marginBottom: isMobile ? "5vh" : "auto",
          height: { xs: "100%", sm: "100vh" },
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            gridColumnEnd: "span 2",
          }}
        >
          <MuiNavbar />
        </Box>
        <Sidebar setFn={setCollectionsSidebar} toggle={collectionsSidebar}>
          <SideMenu
            collectionIdArray={collectionIdArray}
            collections={collections}
            setNewPopup={setNewPopup}
            setNewBookmarkPopup={setNewBookmarkPopup}
            setSubPopup={setSubPopup}
          />
        </Sidebar>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {isMobile && (
            <Box sx={{ pt: 2, px: 3, mb: 2 }}>
              <Button onClick={() => dispatch(setCollectionsSidebar(true))}>
                All
              </Button>
            </Box>
          )}
          <Outlet />
        </Box>
      </Box>
      {/* {newBookmarkPopup && (
        <CustomBookmark
          newBookmarkPopup={newBookmarkPopup}
          setNewBookmarkPopup={setNewBookmarkPopup}
          handleSaveCustomBookmark={handleSaveCustomBookmark}
        />
      )} */}
      {newPopup && (
        <NewCollectionPopup
          saveCollection={saveCollection}
          newPopup={newPopup}
          setNewPopup={setNewPopup}
        />
      )}
      {subPopup && (
        <NewSubCollectionPopup
          subPopup={subPopup}
          setSubPopup={setSubPopup}
          saveCollection={saveCollection}
        />
      )}
      <LabelBottomNavigation />
      <ExternalBookmarkDrawer
        isBookmarkSidebar={isBookmarkSidebar}
        externalBookmarkCloseHandler={externalBookmarkCloseHandler}
        showExternalBookmarkSidebar={showExternalBookmarkSidebar}
      />
      <BookmarkDrawer
        isSidebarOpen={isSidebarOpen}
        sidebarClosedHandler={sidebarClosedHandler}
        showSidebar={showSidebar}
      />
      <SourceSidebar
        isSourceSidebarOpen={isSourceSidebarOpen}
        sourceSidebarClosedHandler={sourceSidebarClosedHandler}
        showSourceSidebar={showSourceSidebar}
      />
    </React.Fragment>
  );
}
