import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useCategory = (
  scrollLoading,
  hasMore,
  setoffset,
  offset,
  categoryType,
  subCategory
) => {
  const dataCount = (academiaSearch) => {
    return academiaSearch === "Academia" || academiaSearch === "Bing" ? 21 : 10;
  };

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (scrollLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (subCategory) {
            setoffset((prevOffset) => prevOffset + 10);
          } else if (categoryType === "books") {
            setoffset((prevOffset) => prevOffset + 9);
          } else if (categoryType === "wiki") {
            setoffset((prevOffset) => prevOffset + 21);
          } else {
            setoffset((prevOffset) => (prevOffset + 21));
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [scrollLoading, hasMore]
  );

  return { lastElementRef, dataCount, setoffset, offset };
};

export default useCategory;
