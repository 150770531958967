import { axiosInstance } from "lib/axios";
import { authAPI, searchAPI } from "services/apis.service";

export const getCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getCollections,
    {
      callingUserId: user.userId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getrecentCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getRecentCollections,
    {
      callingUserId: user.userId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getrecentCollectionSource = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getRecentCollectionSource,
    {
      filter: "",
      callingUserId: user.userId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getBookmarksFromCollection = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getCollectionsSource,
    {
      callingUserId: user.userId,
      collectionId: id,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getParentChainCollection = async (id, user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getParentChainCollection,
    {
      collectionId: id,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getSharedUserList = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.sharedUserList,
    {
      userId: user.userId,
      collectionId: id,
    },
    { cache: false }
  );

  return data;
};

export const addUserToCollection = async (id, user, email) => {
  const requestsData = {
    collectionId: id,
    userId: user.userId,
    email: email,
  };
  await axiosInstance.post(searchAPI.shareCollections, requestsData, {
    cache: false,
  });
};

export const deleteSharedUser = async (id, sharedId, user, email) => {
  const requestsData = {
    collectionId: id,
    sharedWithUserId: sharedId,
    callingUserId: user.userId,
    sharedWithEmailId: email,
  };
  const res = await axiosInstance.post(
    searchAPI.removeSharedUser,
    requestsData,
    { cache: false }
  );
};

export const addUseToPublicCollection = async (user, id, isPublic) => {
  const requestsData = {
    callingUserId: user.userId,
    collectionId: id,
    isPublic: isPublic,
  };
  const { data } = await axiosInstance.post(
    searchAPI.updateCollectionPublic,
    requestsData,
    { cache: false }
  );
  return data;
};

export const getPublicCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollections,
    {
      callingUserId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getPublicCollectionsSource = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollectionsSource,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    },
    {
      cache: false,
    }
  );

  return data;
};

export const deleteCollection = async (user, id) => {
  await axiosInstance.delete(
    searchAPI.deleteCollections,
    {
      data: {
        callingUserId: user.userId,
        collectionId: id,
        isHardDelete: false,
      },
    },
    {
      cache: false,
    }
  );
};

export const publicCollection = async (user, id, name) => {
  const requestsData = {
    callingUserId: user.userId,
    userId: user.userId,
    collectionId: id,
    collectionName: name,
  };
  await axiosInstance.post(searchAPI.updateCollections, requestsData, {
    cache: false,
  });
};

export const saveCollectionFolder = async (user, name, parent) => {
  const requestsData = {
    callingUserId: user.userId,
    userId: user.userId,
    collectionName: name,
    ...parent,
  };
  const { data } = await axiosInstance.post(
    searchAPI.updateCollections,
    requestsData,
    {
      cache: false,
    }
  );
  return data.collectionID_ids;
};

export const saveCustomBookmark = async (json_res) => {
  const { data } = await axiosInstance.post(
    searchAPI.AddUpdateCollectionsSource,
    json_res,
    {
      cache: false,
    }
  );

  return data.collectionSourceID_ids;
};

export const removeAllSharedUsers = async (id, user) => {
  const { data } = await axiosInstance.post(
    searchAPI.removeAllUsersFromSharedCollection,
    {
      collectionId: id,
      callingUserId: user.userId,
    },
    { cache: false }
  );
};

export const getCollectionOverview = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.collectionOverView,
    {
      callingUserId: user.userId,
      userId: user.userId,
      collectionId: id,
    },
    {
      cache: false,
    }
  );

  return data;
};

export const getPublicCollectionOverview = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.publicCollectionOverView,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    },
    {
      cache: false,
    }
  );

  return data;
};

export const followUnFollowUser = async (user, id, username) => {
  const { data } = axiosInstance.post(
    authAPI.followUnFollowUser,
    {
      callingUserId: user.userId,
      followerUserId: id,
      followingUserName: username,
      followAction: true,
    },
    {
      cache: false,
    }
  );

  return data;
};
