import { Box } from "@mui/material";
import YourPulse from "features/Pulse/components/YourPulse";
import React from "react";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";

const PulseHomePage = () => {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <YourPulse />
    </Box>
  );
};

export default PulseHomePage;
