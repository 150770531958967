import { uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import useCategory from "./hooks/use-category.hook";
import { scrollingBox, fillParent } from "utils/base-styles";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { useSourceSearch } from "features/SourceSearch";
import { useIsTab } from "hooks/is-tab.hook";
import SectionCategory from "./component/section-category";
import { SidebarCard } from "./component/sidebar-card";
import { useSelector } from "react-redux";
import BooksScrollComponent from "./component/BookScrollComponent";
import WikiScrollComponent from "./component/WikiScrollComponent";
import CategoryScrollComponent from "./component/CategoryScrollComponent";
import ItemSkeleton from "components/base/ItemSkeleton";
import RelevantSection from "features/News/components/relevant-section";

const CategoryNews = () => {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const { currentCategory, currentSource, currentSubCategory, getCategory } =
    useSourceSearch();
  const { bookmarkedData } = useSelector((state) => state.data);

  const [loading, setLoading] = useState(true);
  const [categoryData, setcategoryData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [categoryType, setCategoryType] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const scrollRef = useRef(null);

  const { lastElementRef } = useCategory(
    loading,
    hasMore,
    setOffset,
    offset,
    categoryType,
    currentSubCategory
  );

  function isLoading() {
    return (
      loading ||
      (currentSource !== "books" &&
        currentSource !== "wiki" &&
        currentCategory == null) ||
      (currentCategory !== "academia" && currentSubCategory != null)
    );
  }

  function handleDataUpdate(newData, idProp, clear = false) {
    if (clear) {
      setTimeout(() => setcategoryData(() => []), 0);
      setOffset(0);
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return;
    } else {
      setTimeout(
        () =>
          setcategoryData((previousData) => {
            const catData = uniqBy([...previousData, ...newData], idProp);
            return catData;
          }),
        0
      );
    }
  }

  useEffect(() => {
    if (currentCategory) {
      setCategoryType(null);
    } else {
      setCategoryType(currentSource);
    }
  }, [currentCategory, currentSource]);

  let ScrollComponent;
  switch (categoryType) {
    case "books":
      ScrollComponent = BooksScrollComponent;
      break;
    case "wiki":
      ScrollComponent = WikiScrollComponent;
      break;
    default:
      ScrollComponent = CategoryScrollComponent;
      break;
  }

  useEffect(() => {
    if (bookmarkedData.id && categoryData.length) {
      const bookmarkedNewsData = categoryData.map((item) => {
        if (item.url === bookmarkedData.id) {
          const itemData = {
            ...item,
            collectionSourceId: bookmarkedData.collectionSourceId,
            collectionId: bookmarkedData.collectionId,
            isCitationAvailable: bookmarkedData.isCitationAvailable,
            isSummaryAvailable: bookmarkedData.isSummaryAvailable,
          };
          return itemData;
        }
        return item;
      });
      setcategoryData(bookmarkedNewsData);
    }
  }, [
    bookmarkedData.collectionId,
    bookmarkedData.collectionSourceId,
    bookmarkedData.id,
  ]);

  return categoryType !== "unknown" ? (
    <React.Fragment>
      <RelevantSection />
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateColumns:{xs: 'auto 1fr', md: "20vw 1fr"},
          gap: 2,
          ...fillParent,
          p: 2,
          pr: { xs: 2, sm: 0 },
          mb: 4,
          mt: 5
        }}
      >
        <Box
          sx={{
            width: { sm: "35vw", md: "auto" },
          }}
        >
          <SidebarCard
            categoryType={categoryType}
            category={
              getCategory(currentCategory)
                ? getCategory(currentCategory).label
                : ""
            }
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Box sx={{ ...(!isMobile && { ...fillParent, ...scrollingBox }) }}>
            <ScrollComponent
              offset={offset}
              setLoading={setLoading}
              setcategoryData={handleDataUpdate}
              setoffset={setOffset}
              setShouldLoad={setLoading}
              setHasMore={setHasMore}
              setScrollLoading={setLoading}
            />
            <Stack direction="row">
              <Box ref={scrollRef} />
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <SectionCategory
                  lastElementRef={lastElementRef}
                  categoryData={categoryData}
                  setcategoryData={handleDataUpdate}
                  setHoverIndex={() => false}
                  categoryType={categoryType}
                />
                {loading && (
                  <Stack
                    direction="column"
                    sx={{
                      maxWidth: isTab ? "100%" : "70%",
                      pt: 2,
                    }}
                  >
                    {Array.from(Array(5), (e, i) => {
                      return (
                        <React.Fragment>
                          <Box>
                            <ItemSkeleton
                              sx={{
                                minHeight: "200px",
                              }}
                              key={`dummy-${i}`}
                            />
                          </Box>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export default CategoryNews;
