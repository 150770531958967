import React, { useEffect, useState } from 'react'
import { relevantSearches } from '../data/relevant-search';
import { useSourceSearch } from 'features/SourceSearch';
import fetchRelatedSearchService from "../services/use-relevant-search.service";

const useRelevantSearch = () => {
    const [relatedSearches, setRelatedSearches] = useState([]);

     const {
       currentSearchTerm,
       currentSource,
       currentCategory,
       currentTimeframe,
       currentSubCategory,
     } = useSourceSearch();

    const fetchRelevantSearch = async () => {
      try{
        const data = await fetchRelatedSearchService(currentSearchTerm);
        setRelatedSearches(data);
      }catch(e){
        console.error(e);
      }
    }

    useEffect(() => {
        fetchRelevantSearch()
    }, [
      currentSearchTerm,
      currentSource,
      currentCategory,
      currentTimeframe,
      currentSubCategory,
    ]);

    return {relatedSearches}
    
}

export default useRelevantSearch;