import { createSvgIcon } from "@mui/material";

export const GlobalNewsIcon = createSvgIcon(
  <svg
    id="global-news-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M66.11961,33.88054c-8.90259-8.90283-23.33667-8.90283-32.23926,0-8.90259,8.90234-8.90259,23.33643,0,32.23926,8.90259,8.90234,23.33667,8.90234,32.23926,0,8.90259-8.90283,8.90259-23.33691,0-32.23926Zm.36768,14.10455h-7.5473c-.23395-5.10553-1.35309-9.62378-3.00653-12.83704,1.81079,.98016,3.54547,2.23889,5.11237,3.80585,2.67181,2.67169,4.49084,5.81769,5.44147,9.03119Zm-16.48615,18.95105c-2.49634,0-4.55127-6.51947-4.8385-14.92126h9.677c-.28723,8.40179-2.34216,14.92126-4.8385,14.92126Zm-4.8385-18.95105c.28729-8.40192,2.34216-14.9209,4.8385-14.9209s4.55121,6.51898,4.8385,14.9209h-9.677Zm-8.42841-11.25122c2.37646-2.37653,5.48212-3.66217,8.81757-3.93042-2.45728,3.09784-4.19104,8.66711-4.48956,15.18164h-8.27246c-.27802-4.2392,.99786-8.30432,3.94446-11.25122Zm-3.22095,15.28101h7.54895c.23395,5.10596,1.35321,9.62451,3.00684,12.83771-1.81171-.98035-3.54724-2.2395-5.11481-3.80713-2.67163-2.67157-4.49042-5.81726-5.44098-9.03058Zm29.75262,11.25067c-2.37579,2.37604-5.48047,3.66174-8.815,3.93042,2.45703-3.0979,4.19061-8.66687,4.48907-15.18109h8.27069c.27802,4.23907-.9978,8.30408-3.94476,11.25067Z" />
  </svg>
);
