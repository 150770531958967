import useInfiniteScrollWiki from "../hooks/use-infinite-wikipedia-scroll.hook";

const WikiScrollComponent = ({
  offset,
  setLoading,
  categoryData,
  setcategoryData,
  setoffset,
  setShouldLoad,
  setHasMore,
  setScrollLoading,
}) => {
  const { error } = useInfiniteScrollWiki(
    offset,
    setLoading,
    setcategoryData,
    setHasMore,
  );
};

export default WikiScrollComponent;
