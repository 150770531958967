import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";

export default function IndividualPricing({
  base,
  coupon,
  planLength = "yearly",
  autoRenew = true,
  sx,
}) {
  const couponPrice = useCallback(() => {
    if (coupon != null) {
      const amountOff = base * coupon.percentage;
      return base - amountOff;
    }
    return base;
  }, [coupon, base]);
  return (
    <Stack direction="row" justifyContent="end" gap={1} sx={sx}>
      {coupon && (
        <Typography
          color="error"
          component="span"
          sx={{
            textDecoration: "line-through",
          }}
        >
          ${base.toFixed(2)}
        </Typography>
      )}
      <Typography>
        ${couponPrice().toFixed(2)}{" "}
        {autoRenew && `/ ${planLength === "yearly" ? "year" : "month"}`}
      </Typography>
    </Stack>
  );
}
