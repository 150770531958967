import { Box, Link, Typography, useTheme } from "@mui/material";
import { Link as DomLink } from "react-router-dom";

export default function ToolMenuItem({ children, title, link, sx = {} }) {
  const theme = useTheme();
  return (
    <Link
      sx={{
        p: 2,
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          bgcolor: theme.palette.secondary.dark,
        },
        "& > svg": {
          height: "2.5rem",
          width: "2.5rem",
          fill: theme.palette.primary.main,
        },
        ...sx,
      }}
      to={link}
      component={DomLink}
    >
      {/* <Box
        component={image}
        sx={{ height: "2.5rem", width: "2.5rem", fill: theme.palette.primary.main }}
        src={image}
        alt={`${title} Logo`}
      /> */}
      {children}
      <Typography variant="body2" align="center">
        {title}
      </Typography>
    </Link>
  );
}
