import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { emailValidation } from "utils/validation.util";
import { toast } from "react-toastify";
import PublicCollectionDetailCard from "./PublicCollectionDetailCard";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100vw", sm: "50vw", md: "35vw" },
  bgcolor: "background.paper",
  boxShadow: 0,
  borderRadius: 2,
};

function PublishConfirmationModal({
  open,
  onClose,
  collection,
  length,
  makeCollectionPublic,
  setView,
}) {
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const handlePublicButton = async () => {
    if (emailValidation(user.userName)) {
      toast.error(
        "Username cannot be a email. Please change it in the accounts section."
      );
      return;
    }
    await makeCollectionPublic();
    setView("public");
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
      }}
    >
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <IconButton
            sx={{ position: "absolute", top: 5, right: 20 }}
            edge="end"
            color="black"
            onClick={onClose}
          >
            <CloseIcon sx={{ color: "#a9baca" }} />
          </IconButton>
          <Typography
            mt={1}
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Ready to publish?
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            You're about to share your collection with the entire community. The
            Public version of your collection does not include notes, summaries,
            citations, or bookmarks added from URLs.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, color: "gray" }}>
            Preview
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 1,
              mt: 1,
            }}
          >
            <PublicCollectionDetailCard
              collection={collection}
              length={length}
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            p: 4,
            mt: 2,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handlePublicButton}
            sx={{
              width: "45%",
            }}
          >
            Publish Now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default PublishConfirmationModal;
