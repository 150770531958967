import React, { useState } from "react";

import Footer from "components/layout/Footer";

import QuickSearchResults from "features/quickSearch";
import Logo from "components/layout/Logo";
import SearchBox from "features/searchbar/muisearchbar/components/search-box";
import MenuAppBar from "components/layout/MuiNavbar";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import bg1 from "assets/images/bg1.jpg";
import LabelBottomNavigation from "components/layout/MuiNavbar/BottomBar";
import { PrimarySearchBox } from "features/SourceSearch";

const Landing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: `url(${bg1})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="center"
        width="100%"
        height="40vh"
        px={5}
        pt="15vh"
      >
        <MenuAppBar
          isLandingPage={true}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
        <Logo isLandingPage={true} />
        <PrimarySearchBox isDarkBackground />
        {/* <SearchBox
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isLandingPage={true}
        /> */}
      </Stack>
      <QuickSearchResults />
      <LabelBottomNavigation />
      {!isMobile && <Footer />}
    </Box>
  );
};

export default Landing;
