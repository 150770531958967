export const mapCitationData = (data) => {
  const res = {
    numberOfVolumes: data.numberOfVolumes_chr,
    callNumber: data.callNumber_chr,
    rights: data.rights_chr,
    extra: data.extra_chr,
    numPages: data.numPages_chr,
    place: data.place_chr,
    language: data.language_chr,
    volume: data.volume_chr,
    seriesNumber: data.seriesNumber_chr,
    libraryCatalog: data.libraryCatalog_chr,
    archive: data.archive_chr,
    series: data.series_chr,
    archiveLocation: data.archiveLocation_chr,
    publisher: data.publisher_chr,
    title: data.title_chr,
    publicationTitle: data.publicationTitle_chr,
    date: data.date_dtm,
    key: data.citationId_ids,
    version: data.version_chr,
    url: data.url_chr,
    language: data.language_chr,
    accessDate: data.accessDate_dtm,
    shortTitle: data.shortTitle_chr,
    collectionSourceId: data.collectionSourceId_chr,
    userId: data.userId_chr,
    citation: data.citation_chr,
    section: data.section_chr,
    ISSN: data.issN_chr,
    pages: data.pages_chr,
    edition: data.edition_chr,
    itemType: data.itemType_chr,
    author: data.author_chr,
    styles: data.styles_chr,
    citationId: data.citationId_ids,
    abstractNote: data.abstractNote_chr,
    ISBN: data.isbN_chr,
    creators: data.creators,
    // additional
    websiteTitle: data.websiteTitle_chr,
    websiteType: data.websiteType_chr,
    applicationNumber: data.applicationNumber_chr,
    archiveId: data.archiveId_chr,
    artworkMedium: data.artworkMedium_chr,
    artworkSize: data.artworkSize_chr,
    assignee: data.assignee_chr,
    audioFileType: data.audioFileType_chr,
    audioRecordingFormat: data.audioRecordingFormat_chr,
    billNumber: data.billNumber_chr,
    blogTitle: data.blogTitle_chr,
    caseName: data.caseName_chr,
    citationKey: data.citationKey_chr,
    code: data.code_chr,
    codeNumber: data.codeNumber_chr,
    codePages: data.codePages_chr,
    codeVolume: data.codeVolume_chr,
    committee: data.committee_chr,
    company: data.company_chr,
    conferenceName: data.conferenceName_chr,
    country: data.country_chr,
    court: data.court_chr,
    dateDecided: data.dateDecided_dtm,
    dateEnacted: data.dateEnacted_dtm,
    dictionaryTitle: data.dictionaryTitle_chr,
    distributor: data.distributor_chr,
    docketNumber: data.docketNumber_chr,
    documentNumber: data.documentNumber_chr,
    DOI: data.doI_chr,
    encyclopediaTitle: data.encyclopediaTitle_chr,
    episodeNumber: data.episodeNumber_chr,
    filingDate: data.filingDate_dtm,
    firstPage: data.firstPage_chr,
    format: data.format_chr,
    forumTitle: data.forumTitle_chr,
    genre: data.genre_chr,
    history: data.history_chr,
    identifier: data.identifier_chr,
    institution: data.institution_chr,
    interviewMedium: data.interviewMedium_chr,
    issue: data.issue_chr,
    issueDate: data.issueDate_dtm,
    issuingAuthority: data.issuingAuthority_chr,
    journalAbbreviation: data.journalAbbreviation_chr,
    label: data.label_chr,
    legalStatus: data.legalStatus_chr,
    legislativeBody: data.legislativeBody_chr,
    letterType: data.letterType_chr,
    manuscriptType: data.manuscriptType_chr,
    mapType: data.mapType_chr,
    meetingName: data.meetingName_chr,
    nameOfAct: data.nameOfAct_chr,
    network: data.network_chr,
    number: data.number_chr,
    organization: data.organization_chr,
    patentNumber: data.patentNumber_chr,
    postType: data.postType_chr,
    presentationType: data.presentationType_chr,
    priorityNumbers: data.priorityNumbers_chr,
    proceedingsTitle: data.proceedingsTitle_chr,
    programmingLanguage: data.programmingLanguage_chr,
    programTitle: data.programTitle_chr,
    publicLawNumber: data.publicLawNumber_chr,
    references: data.references_chr,
    reporter: data.reporter_chr,
    reporterVolume: data.reporterVolume_chr,
    reportNumber: data.reportNumber_chr,
    reportType: data.reportType_chr,
    repository: data.repository_chr,
    repositoryLocation: data.repositoryLocation_chr,
    runningTime: data.runningTime_chr,
    scale: data.scale_chr,
    seriesText: data.seriesText_chr,
    session: data.session_chr,
    status: data.status_chr,
    subject: data.subject_chr,
    system: data.system_chr,
    thesisType: data.thesisType_chr,
    type: data.type_chr,
    university: data.university_chr,
    versionNumber: data.versionNumber_chr,
    videoRecordingFormat: data.videoRecordingFormat_chr,
    lastUpdatedDateTime: data.lastUpdatedDateTime_dtm,
  };
  return res;
};
