import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMisinformationDetailsPage } from "../services/misinformation.service";
import { useSelector } from "react-redux";
// import { fingerprintDetailsDataSample } from "../data/fingerprint-details.data";

const useMisinformationFingerprints = () => {
  const [fingerprintDetails, setFingerprintDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const { user } = useSelector((state) => state.auth);

  const fetchMisinformationDetailsPage = async () => {
    try {
      setLoading(true);
      const data = await getMisinformationDetailsPage(params.id, user.userId);
      setFingerprintDetails(data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchMisinformationDetailsPage();
    }
  }, [params.id]);

  return { fingerprintDetails, loading };
};

export default useMisinformationFingerprints;
