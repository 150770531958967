import { filter } from "lodash";
import dayjs from "lib/dayjs";
import { axiosInstance } from "lib/axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";

import { fillParent, scrollingBox } from "utils/base-styles";
import { currentEnv } from "config";

export default function AfpPage() {
  const { afpId } = useParams();
  const { palette, typography } = useTheme();
  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [iframeHeight, setIframeHeight] = useState("100vh");

  const iframeRef = useRef(null);

  const styles = useMemo(
    () => `
      <head>
        <style>
          html {
            font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
          }
          body {
            color: ${palette.text.primary};
            background-color: ${palette.background.default};
            font-size: ${typography.body1.fontSize};
            font-family: ${typography.body1.fontFamily};
            line-height: ${typography.body1.lineHeight};
            font-weight: ${typography.body1.fontWeight};
          }
          img {
            margin: 0.7rem ${typography.body1.fontSize};
          }
        </style>
      </head>
    `,
    [palette, typography]
  );

  async function getAFPDetails(afpId) {
    setLoading(true);
    setErrorStatus(null);
    try {
      const { data } = await axiosInstance.post(
        `https://${currentEnv}feed-api.sooth.fyi/api/FeedSearch/GetAfpPage`,
        {
          Guid: afpId,
        }
      );

      setPageData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function onLoadIframe(event) {
    if (event.target != null && event.target.contentWindow != null) {
      const iframe = event.target;
      const height = iframe.contentWindow.document.body.scrollHeight + 100;
      setIframeHeight(height + "px");
    }
  }

  const iframeContent = useMemo(
    () => styles + (pageData != null ? pageData.content : ""),
    [pageData]
  );

  useEffect(() => {
    async function fetchData() {
      await getAFPDetails(afpId);
    }
    fetchData();
  }, [afpId]);

  return loading ? (
    <Stack
      direction="column"
      gap={1}
      sx={{
        px: 3,
      }}
    >
      <Skeleton height={typography.h3.fontSize} variant="text" />
      <Skeleton height={typography.h3.fontSize} variant="text" width="45%" />
      <Stack direction="row">
        <Skeleton variant="rectangular" width="20vw" height="11rem" />
        <Stack direction="column" gap={0} sx={{ flex: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="35%" />
        </Stack>
      </Stack>
      <Stack direction="column" gap={0} sx={{ flex: 1 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="35%" />
      </Stack>
      <Stack direction="row">
        <Stack direction="column" gap={0} sx={{ flex: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="35%" />
        </Stack>
        <Skeleton variant="rectangular" width="20vw" height="11rem" />
      </Stack>
      <Stack direction="column" gap={0} sx={{ flex: 1 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="35%" />
      </Stack>
    </Stack>
  ) : errorStatus === 404 ? (
    <Typography variant="h3">Could not find aricle</Typography>
  ) : (
    pageData != null && (
      <Box
        sx={{
          ...fillParent,
          ...scrollingBox,
          py: 5,
          px: "15vw",
        }}
      >
        <Typography component="h1" variant="h3">
          {pageData.title}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{
            textTransform: "uppercase",
          }}
        >
          by{" "}
          {filter(pageData.contributor, (contrib) =>
            contrib.roles.includes("forbyline")
          )
            .map((contrib) => contrib.name)
            .join(", ")}
          , Published: {dayjs(pageData.timestamp).format("LLL")}
        </Typography>
        <Box
          ref={iframeRef}
          component="iframe"
          srcdoc={iframeContent}
          onLoad={onLoadIframe}
          scroll="no"
          sx={{
            overflow: "hidden",
            border: "none",
            width: "100%",
            height: iframeHeight,
          }}
        />
      </Box>
    )
  );
}
