import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100vw", sm: "50vw", md: "35vw" },
  bgcolor: "background.paper",
  boxShadow: 0,
  borderRadius: 2,
};

const RemoveModal = ({ isOpen, onClose, onConfirm, email, removeState }) => {
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Remove Email
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="body1" mt={2}>
            Are you sure you want to remove{" "}
            {removeState === "all" ? "all the users" : email} from this
            collection?
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: navbarBackground,
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              width: "45%",
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemoveModal;
