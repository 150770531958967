import React from "react";
import { Avatar } from "@mui/material";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import { useSelector } from "react-redux";
import { Person } from "@mui/icons-material";

const ProfileAvatar = ({ size, userId }) => {
  const { user } = useSelector((state) => state.auth);
  const avatar = createAvatar(identicon, {
    seed: userId || "123456",
    size,
    radius: 50,
  });
  const svgString = avatar.toDataUriSync();

  return userId == null || userId === "" ? (
    <Avatar sx={{ width: "2.5rem", height: "2.5rem" }}>
      <Person />
    </Avatar>
  ) : (
    <Avatar
      src={svgString}
      sx={{ width: "2rem", height: "2rem", bgcolor: "grey.200" }}
    ></Avatar>
  );
};

export default ProfileAvatar;
