import { subtractYearsAndGetFormattedDate } from "utils/time.util";
import dayjs from "lib/dayjs";

const formatCustomDate = (fromDate, toDate) => {
  if (!fromDate || !toDate) return "";
  return `${fromDate.getFullYear()}-${("0" + (fromDate.getMonth() + 1)).slice(
    -2
  )}-${("0" + fromDate.getDate()).slice(-2)}..${toDate.getFullYear()}-${(
    "0" +
    (toDate.getMonth() + 1)
  ).slice(-2)}-${("0" + toDate.getDate()).slice(-2)}`;
};

export const getFilterDateValue = (filter, fromDate, toDate) => {
  const date = new Date();
  const currentDate = `${date.getFullYear()}-${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

  const customDate = `${formatCustomDate(fromDate, toDate)}`;

  const currentDateMinusOne = subtractYearsAndGetFormattedDate(date, 1);
  const currentDateMinusTwo = subtractYearsAndGetFormattedDate(date, 2);
  const currentDateMinusThree = subtractYearsAndGetFormattedDate(date, 3);

  const filterValue = {
    "Past 24 hours": "Day",
    "Past Week": "Week",
    "Past Month": "Month",
    "Past Year": `${currentDateMinusOne}..${currentDate}`,
    "Past 2 Years": `${currentDateMinusTwo}..${currentDate}`,
    "Past 3 Years": `${currentDateMinusThree}..${currentDate}`,
    "All time": "",
    "Custom Date": `${customDate}`,
  };

  return filterValue[filter] || null;
};

// Duplicated for now until we can fully refactor. Just trying to get the new Source Search working.

const formatCustomDateDayjs = (fromDate, toDate) => {
  if (!fromDate || !toDate) return "";
  const start = dayjs(fromDate);
  const end = dayjs(toDate);
  return `${start.year()}-${("0" + (start.month() + 1)).slice(-2)}-${(
    "0" + start.date()
  ).slice(-2)}..${end.year()}-${("0" + (end.month() + 1)).slice(-2)}-${(
    "0" + end.date()
  ).slice(-2)}`;
};

export const getFilterDateValueKeys = (filter, fromDate, toDate) => {
  const date = new Date();
  const currentDate = `${date.getFullYear()}-${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

  const customDate = `${formatCustomDateDayjs(fromDate, toDate)}`;

  const currentDateMinusOne = subtractYearsAndGetFormattedDate(date, 1);
  const currentDateMinusTwo = subtractYearsAndGetFormattedDate(date, 2);
  const currentDateMinusThree = subtractYearsAndGetFormattedDate(date, 3);

  const filterValue = {
    "past-day": "Day",
    "past-week": "Week",
    "past-month": "Month",
    "past-year": `${currentDateMinusOne}..${currentDate}`,
    "past-two-years": `${currentDateMinusTwo}..${currentDate}`,
    "past-three-years": `${currentDateMinusThree}..${currentDate}`,
    "all-time": "",
    "custom-date": `${customDate}`,
  };

  return filterValue[filter] || null;
};
