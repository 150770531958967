import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Tooltip, useTheme } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import ToolkitSearchBar from "features/searchbar/toolkitsearchbar";
import { axiosInstance } from "lib/axios";
import {
  fingerprintAPI,
  misInformationFingerprint,
} from "services/apis.service";
import { useSelector } from "react-redux";
import newsGuardImg from "assets/images/newsguard.png";
import FingerprintSummary from "features/MisinformationToolkit/components/Fingerprint/FingerprintSummary";
import MisinformationFingerprintFooter from "features/MisinformationToolkit/components/Fingerprint/FingerprintFooter";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { InfoOutlined } from "@mui/icons-material";
import NewsGuardLogo from "components/base/NewsGuardLogo";

export default function MisinformationFingerprintsPage() {
  const [fingerprintSummary, setFingerprintSummary] = useState([]);
  const [pendingQuota, setPendingQuota] = useState({});
  const [gotData, setGotData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { fingerprintSearch } = useParams();
  const navigate = useNavigate();
  const { typography } = useTheme();

  const getMisinformationFingerprintData = async (searchTerm) => {
    console.log(searchTerm);
    if (searchTerm == null) {
      setFingerprintSummary([]);
      setGotData(false);
      return;
    }
    setIsLoading(true);
    const requestsData = {
      userId: user.userId,
      searchTerm: searchTerm,
    };

    const res = await axiosInstance.post(
      fingerprintAPI.getMisinformationFingerprintSearch,
      requestsData
    );
    setFingerprintSummary(res.data);
    setIsLoading(false);
    setGotData(true);
  };

  const handleSearch = async (query) => {
    navigate(`/tools/misinformation-fingerprints/${encodeURI(query)}`);
  };

  const getQuota = async () => {
    try {
      const res = await axios.get(
        misInformationFingerprint.getMisInformationFingerprintPendingQuota,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setPendingQuota(res.data);
    } catch (error) {
      console.error("Error fetching quota:");
    }
  };

  useEffect(() => {
    getMisinformationFingerprintData(fingerprintSearch);
    getQuota();
  }, [fingerprintSearch]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <PageTitle>
          Misinformation Fingerprints
          <Typography fontSize="0.3em" component="sup">
            TM
          </Typography>
        </PageTitle>
        <Typography variant="caption">
          This powerful tool leverages NewsGuard's enormous database of
          demonstrably false stories and narratives that have spread online or
          on social media. Use keyword search to find the narrative you’re
          exploring; if there is verifiable evidence that it’s misinformation,
          click the result to read its Misinformation Fingerprint
          <Typography fontSize="0.5em" component="sup">
            TM
          </Typography>
          .
        </Typography>
        <ToolkitSearchBar
          placeholder="Lookup topics by keyword..."
          handleSearch={handleSearch}
          buttonText="Lookup"
          value={fingerprintSearch}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            {pendingQuota.pendingQuota && (
              <Stack direction="row" gap={0.5} alignItems="center">
                <Tooltip
                  title={`Your account is restricted to a maximum of ${pendingQuota.totalQuota} unique myth detail views a month.`}
                >
                  <InfoOutlined
                    sx={{ fontSize: typography.caption.fontSize }}
                  />{" "}
                  <Typography variant="caption">
                    Monthly Unique Myth Views:
                  </Typography>{" "}
                  <Typography variant="caption">
                    {pendingQuota.pendingQuota} / {pendingQuota.totalQuota}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Box>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="caption">Powered by</Typography>
            <NewsGuardLogo fontSize="2rem" />
          </Stack>
        </Stack>
        <FingerprintSummary
          fingerprintData={fingerprintSummary}
          isLoading={isLoading}
          gotData={gotData}
        />
        {!isLoading && gotData ? <MisinformationFingerprintFooter /> : null}
      </Box>
      <Footer />
    </Box>
  );
}
