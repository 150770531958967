import { find } from "lodash";

import licenseLevels from "../data/group-license-levels.json";
import states from "data/states-details.json";

export class Group {
  #id;
  #name;
  #planType;
  #created;
  #expiration;
  constructor({
    organizationId,
    name,
    planType,
    licenseLevels,
    expiration,
    created,
    contact,
    paymentStatus,
  }) {
    this.#id = organizationId;
    this.#created = created;
    this.#name = name;
    this.#planType = planType;
    this.#expiration = expiration;
    this.licenseLevels = licenseLevels.map((level) => new GroupLicense(level));
    this.paymentStatus = paymentStatus;
    this.contact = new GroupContact(contact);
  }
  get id() {
    return this.#id;
  }
  get name() {
    return this.#name;
  }
  get expiration() {
    return this.#expiration;
  }
  get created() {
    return this.#created;
  }
  get planType() {
    return this.#planType;
  }

  get isHigherEd() {
    return this.#planType.includes("highEd");
  }

  get isPaymentPending() {
    return this.paymentStatus.toLowerCase() === "unpaid";
  }
}

export class GroupLicense {
  #key;
  #used;
  #seats;
  constructor({ groupLicenseKey, licensedUsed, licensedSeats }) {
    const licenseLevel = find(licenseLevels, { key: groupLicenseKey });
    this.#key = groupLicenseKey;
    this.label = licenseLevel.licenseLabel;
    this.#used = licensedUsed == null ? 0 : licensedUsed;
    this.#seats = licensedSeats == null ? 0 : licensedSeats;
  }
  get key() {
    return this.#key;
  }
  get usedSeats() {
    return this.#used;
  }
  get seats() {
    return this.#seats;
  }
  get availableSeats() {
    return this.#seats - this.#used;
  }
}

export class GroupContact {
  #name;
  #phone;
  #email;
  #city;
  #state;
  #country;
  constructor({ name, phone, email, city, state, country }) {
    const stateObj = find(states, { name: state });
    this.#city = city;
    this.#email = email;
    this.#name = name;
    this.#phone = phone;
    this.#state = stateObj != null ? stateObj.state_code : state;
    this.#country = country;
  }
  get name() {
    return this.#name;
  }
  get phone() {
    return this.#phone;
  }
  get email() {
    return this.#email;
  }
  get city() {
    return this.#city;
  }
  get state() {
    if (!isNaN(this.#state) && !isNaN(parseFloat(this.#state))) {
      return `DZ-${this.#state}`;
    }
    return this.#state;
  }
  get country() {
    return this.#country;
  }
}

export class GroupUser {
  constructor({
    userId,
    userName,
    email,
    registerationDate,
    groupLicenseKey,
    roleName,
    lastLoginTime,
    emailConfirmed,
  }) {
    this.id = userId;
    this.userName = userName;
    this.email = email;
    this.registerationDate = registerationDate;
    this.lastLoginTime = lastLoginTime;
    this.groupLicenseKey = groupLicenseKey;
    this.roleName = roleName;
    this.confirmed = emailConfirmed;
  }
}

export class GroupCode {
  #id;
  #code;
  constructor({ codeId, generatedCode }) {
    this.#id = codeId;
    this.#code = generatedCode;
  }

  get id() {
    return this.#id;
  }
  get code() {
    return this.#code;
  }
}

export class GroupDomain {
  #id;
  constructor({ domainId, domain, isVerified, verificationCode }) {
    this.#id = domainId;
    this.domain = domain;
    this.verified = isVerified;
    this.verificationCode = verificationCode;
  }

  get id() {
    return this.#id;
  }
}
