import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const fetchSourceData = async (sourceUrl) => {
  const response = await axiosInstance.post(
    searchAPI.publisherInfo,
    {
      hostName: sourceUrl,
    },
    { cache: false }
  );
  return response.data;
};


export const getFullNutritionLabelLink = async (hostName, score) => {
  const { data } = await axiosInstance.post(searchAPI.getNutritionLink, {
    hostName: hostName,
    score: score,
  });
  return data.data;
};