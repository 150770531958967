import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCitationStyles } from "features/Bookmarks/hooks/useCitation";
import React, { useEffect, useState, useMemo } from "react";
import CollectionSourceTable from "./components/CollectionSourceTable";
import useWorkCited from "./hooks/use-work-cited.hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const WorkCited = ({
  workCitedName,
  setWorkCitedName,
  setWorkCitedFormatStyle,
  workCitedFormatStyle,
}) => {
  const [collectionSourceIds, setCollectionSourceIds] = useState([]);
  const [create, setCreate] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);

  const { citationStyles } = useCitationStyles(
    workCitedFormatStyle,
    () => {},
    "citation",
    false
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { workcitedid, id } = useParams();

  const { getCollectionSourceForWorkCited, addUpdateWorkCited } =
    useWorkCited();

  const isDisabled = useMemo(() => {
    if (!workCitedName) return true;
    if (!workCitedFormatStyle) return true;
    if (collectionSourceIds.length === 0) return true;
    return false;
  }, [workCitedName, workCitedFormatStyle, collectionSourceIds]);

  const handleFormSubmit = () => {
    if (isDisabled) {
      if (!workCitedName) toast.error("Name cannot be empty!");
      if (!workCitedFormatStyle) toast.error("Format Style cannot be empty!");
      if (collectionSourceIds.length === 0)
        toast.error("Please select some collection source checkbox!");
      return;
    }

    addUpdateWorkCited({
      workCitedId: workcitedid,
      collectionId: id,
      name: workCitedName,
      formatStyle: workCitedFormatStyle,
      collectionSourceIds,
      create,
    });
  };

  useEffect(() => {
    getCollectionSourceForWorkCited({
      workCitedId: workcitedid,
      collectionId: id,
      name: workCitedName,
      formatStyle: workCitedFormatStyle,
      seCollectionSourceIds: setBookmarks,
    });
  }, [workcitedid, workCitedFormatStyle]);

  useEffect(() => {
    if (!workcitedid) {
      setWorkCitedName("");
      setWorkCitedFormatStyle("");
      setCreate(true);
    }
  }, [workcitedid]);

  useEffect(() => {
    if (pathname.includes("/edit/")) {
      setCreate(false);
    }
  }, [pathname]);

  return (
    <Stack direction="column">
      <Typography color="#5c6d7e" variant="h3">
        {create ? "Create " : "Update "} Work Cited
      </Typography>
      <TextField
        value={workCitedName}
        onChange={(e) => setWorkCitedName(e.target.value)}
        sx={{ width: "70%", mt: 2 }}
        placeholder="Name"
      />
      <Typography variant="h5" color="grey">
        Give your works cited page a name to help remember which paper or
        project it is for.
      </Typography>
      <TextField
        select
        label="Format Type"
        value={workCitedFormatStyle}
        onChange={(e) => setWorkCitedFormatStyle(e.target.value)}
        fullWidth
        variant="outlined"
        sx={{ mb: 2, width: "70%" }}
      >
        {citationStyles?.map((style) => (
          <MenuItem key={style["key"]} value={style["key"]}>
            {style["name"]}
          </MenuItem>
        ))}
      </TextField>
      <CollectionSourceTable
        collectionSourceIds={collectionSourceIds}
        setCollectionSourceIds={setCollectionSourceIds}
        rows={bookmarks}
        workCitedFormatStyle={workCitedFormatStyle}
      />
      <Box
        sx={{ display: "flex", justifyContent: "end", gap: 2, pr: 8, mb: 3 }}
      >
        <Button
          onClick={() => navigate(`/collections/${id}/work-cited`)}
        >
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} disabled={isDisabled}>
          {create ? "Create " : "Update "}
        </Button>
      </Box>
    </Stack>
  );
};

export default WorkCited;
