import { createSvgIcon } from "@mui/material";

export const ThinkTankIcon = createSvgIcon(
  <svg
    id="think-tank-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M50.00046,25.66583c-9.65588,0-17.51221,7.85547-17.51221,17.51123,0,6.05176,3.08704,11.39453,7.76776,14.5415h19.48859c4.68042-3.14697,7.76715-8.48975,7.76715-14.5415,0-9.65576-7.85541-17.51123-17.51129-17.51123Zm7.30627,24.81769c-1.36053,1.36066-2.87616,2.42932-4.44379,3.20557h-11.16583c-.43292-.28918-.84863-.60712-1.22485-.98334-4.02893-4.02893-3.03223-11.58099,2.22174-16.8349,5.25323-5.2533,12.80518-6.25018,16.83417-2.22113,4.02844,4.02844,3.0321,11.5802-2.22144,16.8338Z" />
    <rect x="39.68747" y="61.32379" width="20.38391" height="4.0293" />
    <path d="M49.87943,74.33417c2.93896,0,5.32141-2.38245,5.32141-5.32141h-10.64282c0,2.93896,2.38251,5.32141,5.32141,5.32141Z" />
  </svg>
);
