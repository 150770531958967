import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setsidebarData, setsidebarDataCopy } from "context/features/dataSlice";
import { CategoryService } from "features/SourceSearch";
import { axiosInstance } from "lib/axios";

export const useSidebarData = () => {
  const dispatch = useDispatch();
  const { getCategories } = CategoryService(axiosInstance);
  const { user } = useSelector((state) => state.auth);

  const fetchData = async () => {
    try {
      const response = await getCategories(user.userId);

      dispatch(setsidebarData(response.data));
      dispatch(setsidebarDataCopy(response.data));
    } catch (error) {
      console.error("Failed to fetch sidebar data:", error);
    }
  };

  useEffect(() => {
    if (user?.userId) fetchData();
  }, []);

  return { fetchData };
};
