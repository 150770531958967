import React from "react";
import { Grid } from "@mui/material";
import SummaryInfoBox from "./SummaryInfoBox";
import NewsguardSummaryInfoBox from "./NewsguardSummaryInfoBox";
import TrafficSummaryInfoBox from "./TrafficSummaryInfoBox";

const SummaryInfo = ({ data, trafficData, whoIsData, sx }) => {
  const registrantState = whoIsData?.registrantState?.toUpperCase();
  const registrantCountry = whoIsData?.registrantCountry?.toUpperCase();

  return (
    <Grid container spacing={2} display={"flex"} flexGrow={1} width="100%" sx={sx}>
      <Grid item xs={12} sm={6} md={3}>
        {data?.score ? (
          <NewsguardSummaryInfoBox score={data.score} />
        ) : (
          <NewsguardSummaryInfoBox />
        )}
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        {data?.mediaBiasRating ? (
          <SummaryInfoBox header="Media Bias" content={data.mediaBiasRating} />
        ) : (
          <SummaryInfoBox content="Media bias rating unavailable" />
        )}
      </Grid>

      {data && (data.charityNavigatorScore || data.charityNavigatorRating) && (
        <Grid item xs={12} sm={6} md={3}>
          <SummaryInfoBox
            header="Charity Navigator"
            content={`${data.charityNavigatorScore}%` || "Rating not available"}
          />
        </Grid>
      )}

      {trafficData?.totalVisits && (
        <Grid item xs={12} sm={6} md={3}>
          <TrafficSummaryInfoBox
            header="Total Visits"
            totalVisits={trafficData.totalVisits}
            duration={trafficData.totalVisitsPeriod}
          />
        </Grid>
      )}

      {registrantState && registrantCountry && (
        <Grid item xs={12} sm={6} md={3}>
          <SummaryInfoBox
            header="Registrant Location"
            content={`${registrantState}, ${registrantCountry}`}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SummaryInfo;
