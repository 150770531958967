import React from "react";
import { Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function RightDrawer({
  children,
  maxWidth = { xs: "100vw", sm: "50vw", xl: "35vw" },
  open = true,
  minWidth = { xs: "100vw", sm: "35vw" },
  onClose = () => {},
}) {
  const theme = useTheme();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          maxWidth,
          minWidth: minWidth,
          borderTopLeftRadius: { xs: 0, sm: theme.shape.borderRadius * 3 },
          borderBottomLeftRadius: { xs: 0, sm: theme.shape.borderRadius * 3 },
        },
      }}
    >
      {children}
    </Drawer>
  );
}
