import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { useEffect, useState } from "react";
import {
  fetchPublisherData,
  fetchTrafficEngagementData,
  fetchWhoIsData,
} from "../services/misinformation.service";

const useDomainChecker = (domainName) => {
  const [data, setData] = useState({});
  const [trafficData, setTrafficData] = useState({});
  const [whoisData, setWhoIsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();

  const getPublisherData = async (sourceUrl) => {
    try {
      const data = await fetchPublisherData(sourceUrl);
      return data.data;
    } catch (error) {
      console.error("Failed to fetch Publisher Data", error);
      return null;
    }
  };

  const getWhoIsData = async (sourceUrl) => {
    try {
      const data = await fetchWhoIsData(sourceUrl);
      return data.data;
    } catch (error) {
      console.error("Failed to fetch WHOIS Data", error);
      return null;
    }
  };

  const getTrafficData = async (sourceUrl) => {
    try {
      const data = await fetchTrafficEngagementData(sourceUrl);
      return data.data;
    } catch (error) {
      console.error("Failed to fetch Traffic Data", error);
      return null;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      const [publisherData, trafficData, whoisData] = await Promise.all([
        getPublisherData(domainName),
        getTrafficData(domainName),
        getWhoIsData(domainName),
      ]);

      setData(publisherData || {});
      setTrafficData(trafficData || {});
      setWhoIsData(whoisData || {});
      setIsLoading(false);
    };

    fetchData();
  }, [domainName]);

  return {
    data,
    trafficData,
    whoisData,
    isLoading,
    isMobile,
  };
};

export default useDomainChecker;
