import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const getBooks = async (
  searchTerm,
  startIndex,
  maxResultsPerPage
) => {
  const { data } = await axiosInstance.post(searchAPI.getBooks, {
    query: searchTerm,
    maxResultsPerPage: maxResultsPerPage,
    startIndex: startIndex
  });

  return data;
};
