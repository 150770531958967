import { ceil, each } from "lodash";

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const lower = "bcdefghijklmnopqrstuvwxyz";
const number = "0123456789";
const special = "!#$&*";

export function generateString(minLength = 8, types = ["all"]) {
  let result = "";
  const segmentLength = ceil(minLength / types.length);
  each(types, (value) => {
    let chars;
    switch (value.toLowerCase()) {
      case "upper":
        chars = upper;
        break;
      case "lower":
        chars = lower;
        break;
      case "number":
        chars = number;
        break;
      case "special":
        chars = special;
        break;
      default:
        chars = characters;
        break;
    }
    const charactersLength = chars.length;
    for (let i = 0; i < segmentLength; i++) {
      result += chars.charAt(Math.floor(Math.random() * charactersLength));
    }
  });

  return result;
}

export function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export function replaceToken(str, token, replacement) {
  return str.replaceAll(new RegExp(escapeRegExp(token), "g"), replacement)
}
