import { useState, useEffect } from "react";
import { fetchQuickSearchResults } from "../services/quick-search.service";
import { useSidebarData } from "./use-sidebar-data.hook";

export const useQuickSearch = () => {
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { fetchData } = useSidebarData();

  const getQuickSearch = async () => {
    setIsLoading(true);
    try {
      const data = await fetchQuickSearchResults();
      setSearchList(data);
    } catch (error) {
      console.error("Failed to fetch quick search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuickSearch();
    fetchData();
  }, []);

  return { searchList, isLoading, getQuickSearch };
};
