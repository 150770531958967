import React, { useState } from "react";
import {
  Button,
  IconButton,
  Box,
  TextField,
  Typography,
  Modal,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const NewCollectionPopup = ({ newPopup, saveCollection, setNewPopup }) => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const handleSave = async () => {
    if (title.length === 0) {
      toast.error("Empty Collection name not allowed.");
      return;
    }
    const id = await saveCollection(title, false);
    navigate(`/collections/${id}`);
    setNewPopup(false);
  };

  const onClose = () => {
    setNewPopup(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "34vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal open={newPopup} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ px: 5, py: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Create a new Collection
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            value={title}
            placeholder="Enter new Collection name"
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            display: "flex",
            justifyContent: "space-between",
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              width: "45%",
            }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewCollectionPopup;
