// In Note.jsx
import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deepOrange } from "@mui/material/colors";
import { getFormattedDate } from "utils/time.util";
import ReactMarkdown from "react-markdown";
import ProfileAvatar from "components/base/ProfileAvatar";
import { useSelector } from "react-redux";
import Header from "./Header";

const Note = ({
  start,
  note,
  handleEdit,
  handleDelete,
  currentNoteIndex,
  isEditing,
  displayFonts,
  setDisplayFonts,
  editorState,
  setEditorState,
  saveNote,
  isSaving,
  setCurrentNoteIndex,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const MarkDown = ({ note }) => {
    const shortenedText = note.length > 100 ? `${note.slice(0, 100)}...` : note;

    return (
      <Box
        sx={{
          maxHeight: "10rem",
          overflowY: "auto",
          wordWrap: "break-word",
          pt: 2,
          boxSizing: "border-box",
        }}
      >
        <ReactMarkdown
          children={shortenedText}
          components={{
            strong: ({ node, ...props }) => (
              <strong style={{ fontWeight: "bold" }} {...props} />
            ),
          }}
        />
      </Box>
    );
  };

  const handleIsBlur = () => {
    setCurrentNoteIndex(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
      <ProfileAvatar userId={user.userId} />

      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
        key={note.noteId_ids}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={1} alignItems="center" flexGrow={1}>
            <Typography variant="h6" color="grey">
              {note.userName_chr}
            </Typography>
            <Typography variant="h5">.</Typography>
            <Typography variant="body1" color="grey">
              {`${getFormattedDate(note.lastUpdatedDateTime_dtm)}`}
            </Typography>
          </Stack>

          <Box>
            <IconButton
              onClick={handleClick}
              sx={{ color: "grey", margin: 0, padding: 0 }}
            >
              <MoreHorizIcon sx={{ color: "grey", margin: 0, padding: 0 }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => handleClose(note.noteId_ids)}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleEdit(note.noteId_ids, note);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleDelete(note.noteId_ids)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Stack>

        {!(isEditing && currentNoteIndex === note.noteId_ids) ? (
          <MarkDown note={note.noteText_chr} />
        ) : (
          <Box sx={{ mt: 2, position: "relative" }}>
            <Header
              isEditing={isEditing}
              displayFonts={displayFonts}
              setDisplayFonts={setDisplayFonts}
              editorState={editorState}
              setEditorState={setEditorState}
              saveNote={saveNote}
              isSaving={isSaving && currentNoteIndex !== null}
              isBlur={true}
              handleIsBlur={handleIsBlur}
            />
          </Box>
        )}

        <Divider
          sx={{
            color: "grey",
            my: 2,
          }}
        />
      </Box>
    </Box>
  );
};

export default Note;
