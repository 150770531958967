import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { useUserData } from "features/User/hooks";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { useEffect, useState } from "react";
import { FeatureHelpVideo } from "features/Support";

export default function SubscriptionTrialOptions() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { typography } = useTheme();
  const { timeTillExpired, joinGroup, getUserInfo, showSubscriptions } =
    useUserData();

  const [groupCode, setGroupCode] = useState("");
  const [groupJoinError, setGroupJoinError] = useState(null);

  const buttonStyles = {
    fontFamily: typography.fontFamily,
    textTransform: "uppercase",
  };

  const cardStyles = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const cardActionStyles = {
    display: "flex",
    justifyContent: "end",
  };

  async function handleJoinGroup() {
    if (groupCode === "") {
      setGroupJoinError("You must provide a group join code.");
    }
    const response = await joinGroup(groupCode);
    if (response.status === "error") {
      setGroupJoinError(response.error.message);
    } else {
      await getUserInfo();
    }
  }

  useEffect(() => {
    if (!showSubscriptions) {
      navigate("/profile");
    }
  }, [showSubscriptions, navigate]);

  return (
    <Stack direction={isMobile ? "column" : "row"}>
      <Card variant="outlined" sx={cardStyles}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>Individual Plan</Box>
            </Stack>
          }
        />
        <CardContent
          sx={{
            flex: 1,
          }}
        >
          Select this option if you are making an individual purchase for
          yourself and will not be part of a Group Plan.
          {/* <List>
            <ListItem>Individual Students or Educators</ListItem>
            <ListItem>
              Access to all our premium tools, features, and content
            </ListItem>
            <ListItem>Monthly or annual subscriptions available</ListItem>
          </List> */}
        </CardContent>
        <CardActions sx={cardActionStyles}>
          <Button
            variant="text"
            color="primary"
            sx={buttonStyles}
            LinkComponent={Link}
            to="/profile/subscription/individual-plan"
            disabled={timeTillExpired > 30}
          >
            Select
          </Button>
        </CardActions>
      </Card>
      <Card variant="outlined" sx={cardStyles}>
        <CardHeader title="Group Plan" />
        <CardContent
          sx={{
            flex: 1,
          }}
        >
          Select this option if you are making a purchase for more than just
          yourself (small classes, schools, districts, etc.)
          {/* <List>
            <ListItem>
              Groups of any size - larger groups of users will get bigger
              discounts
            </ListItem>
            <ListItem>
              Access to all our premium tools, features, and content plus admin
              controls
            </ListItem>
            <ListItem>
              Pro-rated pricing available for mid-school year purchases
            </ListItem>
          </List> */}
        </CardContent>
        <CardActions sx={cardActionStyles}>
          <Button
            variant="text"
            color="primary"
            sx={buttonStyles}
            LinkComponent={Link}
            to="/profile/subscription/group-plan"
          >
            Select
          </Button>
        </CardActions>
      </Card>
      <Card variant="outlined" sx={cardStyles}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>Join Existing Group</Box>
              <FeatureHelpVideo videoId="1008186293" title="Tip: How do I use this?" />
            </Stack>
          }
        />
        <CardContent
          sx={{
            flex: 1,
            pt: 4,
          }}
        >
          <TextField
            fullWidth
            label="Group Code"
            id="group-code"
            error={groupJoinError != null}
            helperText={
              groupJoinError != null
                ? groupJoinError
                : "Enter the group code your administrator sent you"
            }
            onChange={({ target }) => {
              setGroupJoinError(null);
              setGroupCode(target.value);
            }}
            value={groupCode}
          />
        </CardContent>
        <CardActions sx={cardActionStyles}>
          <Button
            variant="text"
            color="primary"
            sx={buttonStyles}
            onClick={handleJoinGroup}
          >
            Join
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
}
