import { Box } from "@mui/material";
import Explore from "./components/Explore";
import { useEffect, useState } from "react";
import PublicCollectionSource from "../PublicCollection/components/PublicCollectionSource";
import useCollection from "./hooks/use-public-collection.hook";
import { useParams } from "react-router-dom";

const ExplorePage = () => {
  const [loading, setLoading] = useState(true);
  const [currentPublicCollection, setCurrentPublicCollection] = useState({});
  const [publicCollectionOverview, setPublicCollectionOverview] = useState({});
  const [filter, setFilter] = useState("");

  const {
    publicCollections,
    fetchPublicCollectionsSource,
    publicCollectionsSource,
    handlePublicCollectionOverview,
    filteredPublicCollections,
  } = useCollection(filter);

  const params = useParams();
  const id = params.id;

  const getPublicCollectionOverview = async () => {
    try {
      const data = await handlePublicCollectionOverview(id);
      setPublicCollectionOverview(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const handleIdChange = () => {
      fetchPublicCollectionsSource(id);
      const currentCollection = publicCollections.filter((publicCollection) => {
        return publicCollection.publicCollectionID_chr === id;
      })[0];
      setCurrentPublicCollection(currentCollection);
    };
    publicCollections.length > 0 && handleIdChange();
  }, [id, publicCollections]);

  useEffect(() => {
    if (id) {
      getPublicCollectionOverview();
    }
  }, [id]);

  return (
    <Explore
      setCurrentPublicCollection={setCurrentPublicCollection}
      publicCollections={filter ? filteredPublicCollections : publicCollections}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default ExplorePage;
