import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getComments,
  deleteNote,
  addOrUpdateNote,
} from "../services/notes.service";
import { useDispatch } from "react-redux";
import { setBookmarkedData, setCollectionId } from "context/features/dataSlice";
import { compact } from "lodash";

export const useNote = (setLoading) => {
  const [notes, setNotes] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { collectionSourceId, collections, collectionId, summaryInfoData } =
    useSelector((state) => state.data);

  const fetchData = async () => {
    setLoading(true);
    if (collectionSourceId) {
      let data = await getComments(collectionSourceId, user.userId);
      const cleanData = compact(data);
      setNotes(cleanData);
    }

    setLoading(false);
  };

  const handleDeleteNote = async (noteId) => {
    await deleteNote(noteId, user.userId);
  };

  const handleAddOrUpdateNote = async (html, index, createBookmark) => {
    let collectionSourceId_id = collectionSourceId;
    if (!collectionSourceId) {
      collectionSourceId_id = await createBookmark("");
    }
    if (!collectionId) {
      dispatch(
        setBookmarkedData({
          id: summaryInfoData.url,
          collectionSourceId: collectionSourceId_id,
        })
      );
      dispatch(setCollectionId(collections[0].collectionId));
    }
    const data = await addOrUpdateNote(
      html,
      index,
      user.userId,
      collectionSourceId_id
    );
    const note = await getComments(collectionSourceId_id, user.userId, data);
    return note[0];
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    notes,
    setNotes,
    handleDeleteNote,
    handleAddOrUpdateNote,
  };
};
