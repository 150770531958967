import {
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useCategoryData from "features/News/hooks/use-category-data.hook";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import CategoryIcon from "features/News/components/CategoryIcon";

export default function CategorySection() {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(true);
  const {
    categories,
    updateCategoryList,
    categoriesCopy,
    fetchNewsCategoryData,
  } = useCategoryData(setLoading);

  const handleOnDragEnd = (res) => {
    if (!res.destination) return;
    const sourceIndex = res.source.index;
    const destinationIndex = res.destination.index;

    const sidebarDataCopy = JSON.parse(JSON.stringify(categories));

    let [selectedRow] = sidebarDataCopy.splice(sourceIndex, 1);
    sidebarDataCopy.splice(destinationIndex, 0, selectedRow);

    updateCategoryList(sidebarDataCopy);
  };

  const hideIcons = async (i, isHidden_ysn) => {
    const sidebarDataCopy = JSON.parse(JSON.stringify(categories));
    sidebarDataCopy[i].isHidden_ysn = !isHidden_ysn;
    updateCategoryList(sidebarDataCopy);
  };

  useEffect(() => {
    fetchNewsCategoryData();
  }, []);

  if (loading) {
    return (
      <Box width="60%">
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width="60%" />
      </Box>
    );
  }

  return (
    <Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {categoriesCopy.map(
                ({ category_id, categoryName_chr, isHidden_ysn }, index) => {
                  return (
                    <Draggable
                      key={category_id}
                      draggableId={category_id}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            gap: 1,
                            justifyContent: "space-between",
                            width: { sx: "100%", sm: "70%", md: "50%" },
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Tooltip title="Drag to reorder">
                              <DragIndicatorRoundedIcon />
                            </Tooltip>
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              gap={0}
                            >
                              <CategoryIcon
                                iconId={categoryName_chr}
                                sx={{
                                  fill: palette.text.primary,
                                }}
                              />
                              {/* <img
                                src={getCategoryIconLink(categoryName_chr)}
                                alt={`${categoryName_chr} Thumb`}
                                style={{ width: 25 }}
                              /> */}
                              <Typography variant="body1">
                                {categoryName_chr}
                              </Typography>
                            </Stack>
                          </Box>
                          <Box onClick={() => hideIcons(index, isHidden_ysn)}>
                            {isHidden_ysn ? (
                              <Tooltip title="Unhide category">
                                <VisibilityOffRoundedIcon />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Hide category">
                                <VisibilityRoundedIcon />
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}
