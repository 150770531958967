import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useDocumentTypes from "hooks/use-document-types.hook";
import ExternalBookmark from "./component/ExternalBookmark";
import RightDrawer from "components/base/RightDrawer";
import SourceSidebar from "components/base/SourceSidebar";

export default function ExternalBookmarkDrawer({
  isBookmarkSidebar,
  externalBookmarkCloseHandler,
  showExternalBookmarkSidebar,
}) {
  const { user } = useSelector((state) => state.auth);

  useDocumentTypes(user.userId);
  const toggleSidebar = () => {
    if (isBookmarkSidebar) {
      externalBookmarkCloseHandler();
    } else {
      showExternalBookmarkSidebar();
    }
  };

  useEffect(() => {
    const body = document.body;

    if (isBookmarkSidebar) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "auto";
    }

    return () => {
      body.style.overflowY = "auto";
    };
  }, [isBookmarkSidebar]);

  return (
    isBookmarkSidebar && (
      <RightDrawer
        width={"50%"}
        open={isBookmarkSidebar}
        onClose={externalBookmarkCloseHandler}
      >
        <ExternalBookmark toggleSidebar={toggleSidebar} />
      </RightDrawer>
    )
  );
}
