import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { authAPI, searchAPI } from "services/apis.service";
import { useDispatch } from "react-redux";
import { setsearch, setsidebarData } from "context/features/dataSlice";
import { loginUser, logoutUser } from "context/features/authSlice";
import { toast } from "react-toastify";
import CustomLoader from "components/base/loader";

export default function OauthLogin() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const setSidebar = async (data) => {
    try {
      const sidebar = await axios.post(
        searchAPI.sidebar,
        {
          callingUserId: data.userId,
          userId: data.userId,
          includeDeletedRecords: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );

      dispatch(setsidebarData(sidebar.data));
    } catch (e) {
      console.log(e);
    }
  };

  const handleCleverLogin = async (provider) => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      dispatch(logoutUser());
      localStorage.removeItem("user");
      dispatch(setsearch(""));
      try {
        const res = await axios.post(`${authAPI.cleverLogin}`, {
          code,
          provider,
        });
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("login", true);
        setSidebar(res.data);

        dispatch(loginUser({ user: res.data }));
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("clever", code);
        navigate("/");
      } catch (error) {
        console.error("Error sending the code to backend:", error);
        toast.error("There was an issue logging in with Clever.");
        navigate("/");
      }
    } else {
      toast.error("Cannot login in at clever.");
      navigate("/");
    }
  };

  useEffect(() => {
    handleCleverLogin("clever");
  }, [location.search]);

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          console.log(response);
        } else {
          // No user is logged in
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  }, []);

  return (
    <div className="circular-loader-center">
      <CustomLoader />
    </div>
  );
};