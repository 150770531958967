import React, { useEffect, useState } from "react";
import BookmarksTable from "features/Collections/components/Table";
import { Box } from "@mui/material";
import Header from "features/Collections/components/Header";

const PublicCollectionSource = ({
  publicCollectionsSource,
  currentPublicCollection,
  publicCollectionOverview,
  setPublicCollectionsSource,
}) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [filter, setFilter] = useState("");
  const [filteredBookmarks, setFilteredBookmarks] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleFilterBookmark = () => {
    const filteredBookmark = publicCollectionsSource.filter(
      (bookmark) =>
        bookmark.title_chr.toLowerCase().includes(filter.toLowerCase()) ||
        bookmark.sourceName_chr.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredBookmarks(filteredBookmark);
  };

  const sortData = (criteria, direction, data) => {
    const sortedData = [...data].sort((a, b) => {
      let valA, valB;
      switch (criteria) {
        case "dateAdded":
          valA = new Date(a.createdDateTime_dtm);
          valB = new Date(b.createdDateTime_dtm);
          break;
        case "publishedDate":
          valA = new Date(a.sourcePublishedDateTime_dtm);
          valB = new Date(b.sourcePublishedDateTime_dtm);
          break;
        case "newsGuardRating":
          valA = a.score ? parseFloat(a.score) : -Infinity;
          valB = b.score ? parseFloat(b.score) : -Infinity;
          break;
        case "title":
          valA = a.title_chr.toLowerCase();
          valB = b.title_chr.toLowerCase();
          break;
        case "source":
          valA = a.sourceName_chr.toLowerCase();
          valB = b.sourceName_chr.toLowerCase();
          break;
        default:
          return 0;
      }

      if (valA < valB) return direction === "asc" ? -1 : 1;
      if (valA > valB) return direction === "asc" ? 1 : -1;
      return 0;
    });
    console.log("SORTED DATA - ", sortDirection, sortCriteria, sortedData);
    return sortedData;
  };

  const handleSort = (criteria) => {
    const newDirection =
      sortCriteria === criteria && sortDirection === "asc" ? "desc" : "asc";
    setSortCriteria(criteria);
    setSortDirection(newDirection);
    const dataToSort = filter ? filteredBookmarks : publicCollectionsSource;
    console.log(dataToSort);
    const sortedBookmarks = sortData(criteria, newDirection, dataToSort);
    if (filter) {
      setFilteredBookmarks(sortedBookmarks);
    } else {
      setPublicCollectionsSource(sortedBookmarks);
    }
  };

  useEffect(() => {
    handleFilterBookmark();
  }, [filter]);

  return (
    <Box sx={{ p: 2 }}>
      <Header
        isPublic={true}
        collection={currentPublicCollection}
        length={
          filter ? filteredBookmarks.length : publicCollectionsSource.length
        }
        setFilter={setFilter}
        filter={filter}
        collectionOverview={publicCollectionOverview}
        onSort={handleSort}
        sortCriteria={sortCriteria}
        sortDirection={sortDirection}
      />
      <BookmarksTable
        setHoverIndex={setHoverIndex}
        rows={filter ? filteredBookmarks : publicCollectionsSource}
        setRows={() => {}}
      />
    </Box>
  );
};

export default PublicCollectionSource;
