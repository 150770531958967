import { get, startsWith, trimStart } from "lodash";
import { authAPI, subscriptionsAPI } from "services/apis.service";
import { User } from "../models";

export class AdminService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getUsers(page, pageSize, filterModel, sortModel) {
    const filterField = get(filterModel, "field", null);
    let filterValue = get(filterModel, "value", null);
    if (startsWith(filterValue, "@") && filterModel.field === "userName") {
      filterValue = trimStart(filterValue, "@");
    }
    if (filterField === "isDeleted") {
      filterValue =
        filterValue === "true" ? true : filterValue === "false" ? false : null;
    }

    try {
      const { data: responseData } = await this.#axios.post(
        authAPI.getUsers,
        {
          paginate: true,
          pageIndex: page + 1,
          pageSize,
          filterField: get(filterModel, "field", null),
          filterOperator: get(filterModel, "operator", null),
          filterValue,
          sortField: get(sortModel, "field", null),
          sortOrder: get(sortModel, "sort", null),
        },
        { cache: false }
      );
      if (
        responseData == null ||
        !responseData.success ||
        responseData.data == null ||
        !Array.isArray(responseData.data.users)
      ) {
        return {
          users: [],
          page,
          pageSize,
          total: 0,
        };
      }
      const { data } = responseData;
      const users = data.users.map((user) => new User(user));
      return {
        users,
        total: data.pagination.totalCount,
        page: data.pagination.pageIndex - 1,
        pageSize: data.pagination.pageSize,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUser(email) {
    try {
      const { data } = await this.#axios.post(
        `${authAPI.createUser}`,
        {
          email,
        },
        { cache: false }
      );
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: error.response?.data,
      };
    }
  }

  async changeUserStatus(id, isInactive) {
    try {
      await this.#axios.delete(
        `${authAPI.deactivateUser}?id=${id}&isInactive=${isInactive}`
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async impersonateUser(id) {
    try {
      const { data } = await this.#axios.post(
        `${authAPI.switchUser}?Id=${id}`,
        null,
        { cache: false }
      );

      const sessionStorageData = {
        key: "user",
        value: JSON.stringify(data),
      };

      return sessionStorageData;
    } catch (error) {}
  }

  async setupGroup(
    userId,
    organizationName,
    fullName,
    email,
    referenceNo,
    phoneNumber,
    city,
    state,
    country,
    planTypeData,
    expirationDate
  ) {
    try {
      const { data } = await this.#axios.post(
        subscriptionsAPI.createGroup,
        {
          userId,
          organizationName,
          fullName,
          email,
          referenceNo,
          phoneNumber,
          city,
          state,
          country,
          planTypeData,
          expirationDate,
        },
        { cache: false }
      );
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }
}
