import React, { useState } from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import { useTheme } from "@mui/material/styles";
import Submit from "./Submit";
import NoteBox from "./Notes/Notes";
import Citation from "./Citation";
import Summarize from "./Summarize";
import useCollection from "../hooks/useCollection.hook";
import EditBookmark from "./EditBookmark";
import { useSelector } from "react-redux";
import DeletePopup from "./DeletePopup";
import RightSidebar from "components/base/RightDrawer";
import { setNote } from "context/features/dataSlice";
import { useDispatch } from "react-redux";
import ReadingLevel from "./ReadingLevel";

export default function BookmarkDetails({ toggleSidebar }) {
  const [selectedCollection, setSelectedCollection] = useState("");
  const [edit, setEdit] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [create, setCreate] = useState(false);
  const [citation, setCitation] = useState("");
  const [markdownSummary, setmarkdownSummary] = useState("");
  const [markdownLevel, setmarkdownLevel] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("");
  const [authors, setAuthors] = useState([
    { firstName: "", lastName: "", creatorType: "author" },
  ]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [citationLoaded, setCitationLoaded] = useState(false);
  const [summaryLoaded, setSummaryLoaded] = useState(false);
  const [levelLoaded, setLevelLoaded] = useState(false);
  const [originalArticleData, setOriginalArticleData] = useState({});
  const [originalAuthors, setOriginalAuthors] = useState([
    { firstName: "", lastName: "", creatorType: "author" },
  ]);
  const [originalSummary, setOriginalSummary] = useState({});
  const [originalLevelSummary, setOriginalLevelSummary] = useState({});

  const [disableCitation, setDisableCitation] = useState(false);
  const [disableSummary, setDisableSummary] = useState(false);
  const [disableLevel, setDisableLevel] = useState(true);
  
  const [levelGenerated, setLeveGenerated] = useState(false);

  const {level, selectedBookmarkOption, collectionId } = useSelector(
    (state) => state.data
  );

  const dispatch = useDispatch();

  const {
    saveNewCollection,
    editBookmark,
    createOrUpdateBookmark,
    switchBookmark,
    deleteBookmarkSource,
  } = useCollection(
    setSelectedCollection,
    selectedStyle,
    authors,
    setmarkdownSummary,
    setmarkdownLevel,
    level,
    setOriginalSummary,
    setOriginalLevelSummary
  );

  const closeSidebar = () => {
    setCitationLoaded(false);
  };

  const getDisabled = () => {
    if (create) return true;
    if (selectedBookmarkOption === "summary" && disableSummary) {
      return true;
    }
    if (selectedBookmarkOption === "citation" && disableCitation) {
      return true;
    }
    if (selectedBookmarkOption === "readinglevel" && disableLevel) {
      return true;
    }
    return false;
  };

  const closedSidebarHandler = () => {
    dispatch(setNote({ noteId_ids: null }));
    toggleSidebar();
  };

  return (
    <React.Fragment>
      <Header
        toggleSidebar={closedSidebarHandler}
        selectedBookmarkOption={selectedBookmarkOption}
        edit={edit}
        setEdit={setEdit}
        saveNewCollection={saveNewCollection}
        setSelectedCollection={setSelectedCollection}
        selectedCollection={selectedCollection}
        switchBookmark={switchBookmark}
        closeSidebar={closeSidebar}
        setDeletePopup={setDeletePopup}
        create={create}
        setCreate={setCreate}
      />
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {edit && <EditBookmark />}
        {deletePopup && (
          <DeletePopup
            deletePopup={deletePopup}
            setDeletePopup={setDeletePopup}
            deleteBookmarkSource={deleteBookmarkSource}
            toggleSidebar={toggleSidebar}
          />
        )}
        <Box sx={{ display: edit ? "none" : "block" }}>
          <Citation
            citation={citation}
            setCitation={setCitation}
            articleData={articleData}
            setArticleData={setArticleData}
            selectedStyle={selectedStyle}
            setSelectedStyle={setSelectedStyle}
            authors={authors}
            setAuthors={setAuthors}
            selectedBookmarkOption={selectedBookmarkOption}
            citationLoaded={citationLoaded}
            setCitationLoaded={setCitationLoaded}
            setOriginalArticleData={setOriginalArticleData}
            originalArticleData={originalArticleData}
            originalAuthors={originalAuthors}
            setOriginalAuthors={setOriginalAuthors}
            setDisable={setDisableCitation}
          />
          {selectedBookmarkOption === "notes" && (
            <NoteBox
              collectionId={collectionId}
              createBookmark={createOrUpdateBookmark}
            />
          )}
          <Summarize
            markdownSummary={markdownSummary}
            setmarkdownSummary={setmarkdownSummary}
            summaryLoaded={summaryLoaded}
            setSummaryLoaded={setSummaryLoaded}
            selectedBookmarkOption={selectedBookmarkOption}
            setDisable={setDisableSummary}
            originalSummary={originalSummary}
            setOriginalSummary={setOriginalSummary}
          />
          <ReadingLevel
            levelLoaded={levelLoaded}
            setLevelLoaded={setLevelLoaded}
            markdownSummary={markdownLevel}
            setmarkdownSummary={setmarkdownLevel}
            level={level}
            setDisable={setDisableLevel}
            selectedBookmarkOption={selectedBookmarkOption}
            originalSummary={originalLevelSummary}
            setOriginalSummary={setOriginalLevelSummary}
          />
        </Box>
      </Box>
      {(selectedBookmarkOption !== "notes" || edit) && (
        <Submit
          articleData={articleData}
          citation={citation}
          markdownSummary={markdownSummary}
          addOrUpdateBookmark={editBookmark}
          setEdit={setEdit}
          edit={edit}
          citationLoaded={citationLoaded}
          disable={getDisabled()}
          selectedBookmarkOption={selectedBookmarkOption}
          levelLoaded={levelLoaded}
          markdownLevel={markdownLevel}
          summaryLoaded={summaryLoaded}
        />
      )}
    </React.Fragment>
  );
}
