import { useEffect, useState } from "react";
import { fetchNewsData } from "../services/news.service";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { getFilterDateValue, getFilterDateValueKeys } from "utils/date-filter.util";
import { useSourceSearch } from "features/SourceSearch";

const useNewsData = (setloading, category, query) => {
  const [newsCards, setNewsCards] = useState({});
  const { user } = useSelector((state) => state.auth);

  const {
    currentTimeframe,
    startDate,
    endDate,
  } = useSourceSearch();
  
  const [searchParams] = useSearchParams();
  const { search } = useParams();

  const filterTerm = searchParams.get("pubDate");

  const filteredDateValue = getFilterDateValueKeys(
    currentTimeframe,
    startDate,
    endDate
  );

  const fetchCategoryDataNewsCards = async () => {
    setloading(true);

    const newsDataObj = await fetchNewsData(
      query,
      user,
      filteredDateValue,
      category
    );
    setNewsCards(newsDataObj);
    setloading(false);
  };

  useEffect(() => {
    fetchCategoryDataNewsCards();
  }, [search, filteredDateValue]);

  return { newsCards, setNewsCards };
};

export default useNewsData;
