import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Add, CloseOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function GroupInvoiceConfirmation({
  handleClose,
  handleSubmit,
  formData,
}) {
  const { palette } = useTheme();

  const schema = yup.array(yup.string().email().required());
  const [emails, setEmails] = useState([]);

  function addEmail() {
    setEmails([...emails, ""]); //`email.${emails.length}@email.com`
  }

  function removeEmail(index) {
    const tempArr = [...emails];
    tempArr.splice(index, 1);
    setEmails([...tempArr]);
  }

  function updateEmail(value, index) {
    const tempArr = [...emails];
    tempArr.splice(index, 1, value);
    setEmails(tempArr);
  }

  function resetAndClose() {
    setEmails([]);
    handleClose();
  }

  const isValid = useMemo(() => {
    return schema.isValidSync(emails);
  }, [emails, schema]);

  return (
    formData != null && (
      <Dialog open={!!formData} onClose={resetAndClose} fullWidth maxWidth="md">
        <DialogTitle>Submit Invoice Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have chosen to submit your group subscription payment with an
            invoice request.
          </DialogContentText>
          <DialogContentText>
            An invoice will be emailed to{" "}
            <Typography component="span" fontWeight="bold">
              {formData.email}
            </Typography>
            .
          </DialogContentText>
          <DialogContentText>
            If you would like additional people to receive the invoice please
            add their email(s) below.
          </DialogContentText>
          <DialogContentText>
            After clicking Submit, it may take a moment to generate your invoice
          </DialogContentText>
          <Stack direction="column" mt={2}>
            <Box>
              <Button
                size="small"
                startIcon={<Add />}
                disabled={emails.length > 4}
                onClick={addEmail}
              >
                Email
              </Button>
            </Box>
            {emails.map((email, index) => {
              return (
                <EmailField
                  email={email}
                  index={index}
                  key={`email-${index}`}
                  onRemove={removeEmail}
                  onChange={updateEmail}
                />
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: palette.secondary.main, px: "15vw" }}
        >
          <Button variant="outlined" onClick={() => resetAndClose()}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            variant="contained"
            onClick={() => handleSubmit(emails, formData)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  );
}

function EmailField({ email, index, onChange, onRemove, ...props }) {
  const emailSchema = yup.string().email().required().label("Additional Email");

  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);

  function handleChange(value, index) {
    setTouched(true);
    onChange(value, index);
  }

  async function onBlur(value) {
    try {
      emailSchema.validateSync(value);
      setError(null);
    } catch (error) {
      setError(error.errors[0]);
    }
  }

  useEffect(() => {
    if (touched) {
      onBlur(email);
    }
  }, [index, email, touched]);

  return (
    <Stack direction="row" alignItems="start" {...props}>
      <TextField
        value={email}
        onChange={({ target: { value } }) => handleChange(value, index)}
        // onBlur={({ target: { value } }) => onBlur(value)}
        label="Additional Email"
        size="small"
        sx={{
          minWidth: "25vw",
        }}
        error={!!error}
        helperText={!!error ? error : null}
      ></TextField>
      <IconButton onClick={() => onRemove(index)}>
        <CloseOutlined />
      </IconButton>
    </Stack>
  );
}
