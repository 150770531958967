import { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function FormikSelectField({
  id,
  label,
  handleChange,
  handleBlur,
  value,
  error,
  options,
  fullWidth,
  helperText,
  sx,
  touched,
  inputSx,
  size,
  ...otherProps
}) {
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (touched === undefined) {
      setIsTouched(true);
    } else {
      setIsTouched(!!touched);
    }
  }, [touched]);

  return (
    <FormControl
      error={isTouched && !!error}
      fullWidth={fullWidth}
      sx={{ ...sx }}
      size={size != null ? `${size}` : null}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        id={id}
        sx={{ ...inputSx }}
        {...otherProps}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              key={id + "-" + option.key}
              value={option.key}
              selected={!!option.default}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {(helperText || (isTouched && error)) && (
        <FormHelperText >
          {error ? error : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
