import { get, map } from "lodash";
import * as yup from "yup";
import { useRef, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

import FormikTextField from "components/base/FormikTextField";

import { useAdmin } from "../hooks";
import { LoadingButton } from "@mui/lab";

export default function CreateUser({ open, handleClose = () => {} }) {
  const { createUser } = useAdmin();
  const formRef = useRef();

  const schema = yup.object().shape({
    email: yup.string().email().required().label("Email Address"),
  });

  const [initialState] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  async function onFormSubmit({ email }) {
    setLoading(true);
    const response = await createUser(email);
    if (response) {
      if (formRef.current) {
        formRef.current.handleReset();
      }
      handleClose();
    }
    setLoading(false);
  }

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize={true}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onFormSubmit}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
      }) => {
        const { email } = values;
        function resetAndClose(event) {
          handleReset(event);
          handleClose();
        }
        return (
          <Dialog
            open={open}
            onClose={resetAndClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              component: "form",
              onSubmit: handleSubmit,
            }}
          >
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent
              sx={{
                overflowY: "revert",
              }}
            >
              <Stack direction="column">
                <FormikTextField
                  fullWidth
                  error={get(errors, "email")}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={email}
                  label="Email Address"
                  name="email"
                />
              </Stack>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: "secondary.main",
              }}
            >
              <Button variant="outlined" onClick={resetAndClose}>
                Cancel
              </Button>
              <LoadingButton
                startIcon={<AddOutlined />}
                loading={loading}
                variant="contained"
                type="submit"
              >
                Add
              </LoadingButton>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}
