import { Folder } from "@mui/icons-material";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export default function Card({ collection }) {
  const { palette } = useTheme();
  return (
    <Link
      to={`/collections/${collection.collectionID_ids}`}
      style={{ textDecoration: "none" }}
    >
      <Paper
        sx={{
          display: "flex",
          p: 3,
          height: "100%",
          boxShadow: 0,
          backgroundColor: palette.secondary.main,
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <Folder sx={{ color: "#a9bdca" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                lineHeight: "1.5em",
                maxHeight: "3em",
                flexShrink: 0,
              }}
              variant="body5"
            >
              {collection.collectionName_chr}
            </Typography>
            <Typography
              sx={{ fontSize: "1.1rem", color: palette.text.secondary }}
            >
              {collection.collectionSource_Count_int} bookmarks
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize: "1.1rem", color: palette.text.secondary }}>
          {collection.lastUpdatedDisplay_chr}
        </Typography>
      </Paper>
    </Link>
  );
}
