import { createSlice } from "@reduxjs/toolkit";

export const geoDataSlice = createSlice({
  name: "geo",
  initialState: {
    countries: [],
    activeRegions: [],
  },
  reducers: {
    setCountries(state, { payload }) {
      state.countries = payload;
    },
    setActiveRegions(state, { payload }) {
      state.activeRegions = payload;
    },
  },
});

export const { setCountries, setActiveRegions } = geoDataSlice.actions;

export const geoDataReducer = geoDataSlice.reducer;
