import React from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

const TrafficSummaryInfoBox = ({ totalVisits, duration }) => {
  const {palette} = useTheme();
  return (
    totalVisits && (
      <Paper
        sx={{
          display: "flex",
          p: 3,
          height: "100%",
          boxShadow: 0,
          backgroundColor: palette.secondary.main,
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5">Total Visits</Typography>

          <Stack direction="row" alignItems={"center"}>
            <Typography fontSize="1.8rem" fontWeight="bold">
              {totalVisits}
            </Typography>
            <Typography variant="body2" alignContent="flex-end">
              / {duration} days
            </Typography>
          </Stack>
        </Box>
      </Paper>
    )
  );
};

export default TrafficSummaryInfoBox;
