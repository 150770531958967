import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ImInfo } from "react-icons/im";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SummaryBox from "features/Bookmarks/components/Summarize/Box";
import ReactMarkdown from "react-markdown";

import {
  setArticleSummary,
  setIsBookmarkSidebar,
  setIsSummaryAvailable,
  setShowAddToCollectionsField,
} from "context/features/dataSlice";
import { InfoOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import React, { useState } from "react";

function TooltipContent() {
  return (
    <React.Fragment>
      <Typography variant="caption" component="p">
        We used our own pre-trained transformer model to generate this summary.
        The only data we use is the text from the article. Relying on our own
        summarization pipeline significantly reduces risk of error while keeping
        your personal data safe. Generative AI is a new technology and can be
        prone to errors. Please confirm with the original work and edit as
        needed by clicking the "Edit" button below.
      </Typography>
    </React.Fragment>
  );
}

const GeneratedSummary = ({
  markdownSummary,
  setmarkdownSummary,
  changeTooltip,
  handleCopy,
  handleMouseOut,
  htmlSummary,
  sethtmlSummary,
}) => {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch();

  const tooltipTitle = changeTooltip
    ? "Copied to clipboard"
    : "Copy to clipboard";

  const saveSummary = (markdown) => {
    setmarkdownSummary(markdown);
    setIsFocused(false);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Tooltip placement="right-start" title={<TooltipContent />}>
            <InfoOutlined
              sx={{
                fontSize: theme.typography.subtitle1.fontSize,
              }}
            />
          </Tooltip>
          <Typography variant="caption">How do we generate it?</Typography>
        </Stack>
        <Button
          onClick={() => setmarkdownSummary("")}
          startIcon={<RestartAltIcon />}
          sx={{ backgroundColor: theme.palette.secondary.main, color: "black" }}
        >
          Restart
        </Button>
      </Stack>
      {editing ? (
        <Box sx={{ my: 2 }}>
          <SummaryBox
            editing={editing}
            setEditing={setEditing}
            markdownSummary={markdownSummary}
            htmlSummary={htmlSummary}
            sethtmlSummary={sethtmlSummary}
            saveSummary={saveSummary}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
          />
        </Box>
      ) : (
        <ReactMarkdown>{markdownSummary}</ReactMarkdown>
      )}

      {!editing && (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            {!editing ? (
              <Button
                startIcon={
                  <EditOutlinedIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                }
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  color: "black",
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditing((edit) => !edit);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                startIcon={<CloseIcon />}
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  color: "black",
                  backgroundColor: theme.palette.primary.main,
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditing((edit) => !edit);
                }}
              >
                Close
              </Button>
            )}
            <Tooltip title={tooltipTitle}>
              <Button
                startIcon={
                  <ContentCopyIcon sx={{ color: theme.palette.primary.main }} />
                }
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  color: "black",
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                }}
                onClick={handleCopy}
                onMouseOut={handleMouseOut}
              >
                Copy
              </Button>
            </Tooltip>
          </Stack>
          <Button
            onClick={() => {
              dispatch(setArticleSummary(markdownSummary));
              dispatch(setIsSummaryAvailable(true));
              dispatch(setIsBookmarkSidebar(true));
              dispatch(setShowAddToCollectionsField(true));
            }}
            sx={{ py: 0 }}
          >
            Copy to Collection
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default GeneratedSummary;
