import dayjs from "lib/dayjs";
import {
  debounce,
  filter,
  find,
  get,
  includes,
  map,
  orderBy,
  reduce,
  round,
  some,
  valuesIn,
} from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { toastError } from "utils/toast.util";
import { useIsTab } from "hooks/is-tab.hook";
import { useIsMobile } from "hooks/is-mobile.hook";
import { useGroupSubscription } from "features/User/hooks";

import FormikTextField from "components/base/FormikTextField";
import FormikSelectField from "components/base/FormikSelectField";
import FormikCheckBox from "components/base/FormikCheckBox";

import { prorationMonths } from "features/User/data/subscriptions.data";
import { LicenseLevelRow } from "..";
import { useGeoData } from "features/GeoData";

function handleEnterKey(event, callback) {
  if (event.key === "Enter") {
    event.preventDefault();
    callback();
  }
}

const PRIORITY_COUNTRIES = ["USA"];

export default function GroupForm({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isValid,
  dirty,
  touched,
  setFieldValue,
  setFieldTouched,
  loading,
  products,
  checkPromoCode,
  group,
  initialSubLevel,
  coupons,
}) {
  const {
    email,
    fullName,
    organizationName,
    referenceNo,
    phoneNumber,
    city,
    state,
    country,
    planTypeData,
    isProrated,
    prorateMonth,
    paymentType,
    promoCode,
    couponId,
    productId,
  } = values;

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const { priceData, updatePriceData } = useGroupSubscription();
  const { countries, activeRegions, updateActiveRegions } = useGeoData();

  const [subLevel, setSubLevel] = useState(initialSubLevel);
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeCoupon, setActiveCoupon] = useState(null);
  const [promoError, setPromoError] = useState(null);
  // const [regions, setRegions] = useState([]);

  const regions = useMemo(() => {
    return orderBy(
      map(activeRegions, (region) => ({
        key: region.state_code,
        label: region.name,
      })),
      ["label"]
    );
  }, [activeRegions]);

  const activePromoCode = useMemo(() => {
    if (activeCoupon == null) {
      return null;
    }
    return find(
      activeCoupon.promoCodes,
      (promo) => promo.code === promoCode.toUpperCase()
    );
  }, [activeCoupon, promoCode]);

  const credits = useMemo(() => {
    const k5Level = find(planTypeData, ["key", "group-k5"]);
    if (
      k5Level != null &&
      k5Level.noOfLicenses != null &&
      k5Level.noOfLicenses !== ""
    ) {
      const totalOtherSeats = reduce(
        filter(planTypeData, (level) => level.key !== "group-k5"),
        (sum, level) => {
          const seats =
            level.noOfLicenses != null && level.noOfLicenses !== ""
              ? level.noOfLicenses
              : 0;
          if (seats < 0) {
            return sum + 0;
          }
          return sum + seats;
        },
        0
      );
      return totalOtherSeats > k5Level.noOfLicenses
        ? k5Level.noOfLicenses < 0
          ? 0
          : k5Level.noOfLicenses
        : totalOtherSeats;
    }
    return 0;
  }, [planTypeData]);

  const totalSeats = useMemo(() => {
    return reduce(
      planTypeData,
      (sum, level) => {
        return (
          sum +
          (level.noOfLicenses === "" || level.noOfLicenses == null
            ? 0
            : level.noOfLicenses)
        );
      },
      0
    );
  }, [planTypeData]);

  const currentMonth = useMemo(() => dayjs().month(), []);

  const prorationPercentage = useMemo(() => {
    if (!isProrated) {
      return 1;
    }
    const currentDay = dayjs().date();
    let numMonthsInProration;
    if (prorateMonth > currentMonth) {
      // end month is this year
      numMonthsInProration = prorateMonth - currentMonth;
    } else {
      // end month is next year
      numMonthsInProration = 11 - currentMonth + (prorateMonth + 1);
    }
    if (currentDay <= 15) {
      numMonthsInProration++;
    }
    const percentage = numMonthsInProration / 12;
    return percentage;
  }, [isProrated, prorateMonth, currentMonth]);

  const subtotal = useMemo(() => {
    const subtotal = reduce(
      planTypeData,
      (sum, planType) => {
        const noOfLicenses =
          planType.noOfLicenses > -1 &&
          planType.noOfLicenses != null &&
          planType.noOfLicenses !== ""
            ? planType.noOfLicenses
            : 0;
        const pricing = find(priceData, ["key", planType.classGroupId]);
        if (pricing == null) {
          return sum + 0;
        }
        let lineCost = (noOfLicenses * pricing.value) / 100;
        if (planType.key === "group-k5") {
          lineCost = ((noOfLicenses - credits) * pricing.value) / 100;
        }
        return sum + lineCost;
      },
      0
    );
    const proratedSubtotal = subtotal * prorationPercentage;
    return round(proratedSubtotal, 2);
  }, [priceData, planTypeData, credits, prorationPercentage]);

  const discount = useMemo(() => {
    if (activeCoupon == null) {
      return 0;
    }
    return round(subtotal * activeCoupon.percentage, 2);
  }, [activeCoupon, subtotal]);

  const total = useMemo(() => {
    return subtotal - discount;
  }, [discount, subtotal]);

  function handleClearCoupon() {
    setActiveCoupon(null);
    setPromoError(null);
    setFieldValue("couponId", null);
  }

  const handleApplyCoupon = useCallback(
    async (defaultCoupon = false) => {
      if (activeProduct == null) {
        toastError("Oops. Something went wrong!");
        return;
      }
      if (promoCode.trim() === "" && !defaultCoupon) {
        setPromoError("Must enter a promo code.");
        return;
      }
      const coupon = await checkPromoCode(
        activeProduct.id,
        promoCode.trim().toUpperCase()
      );

      if (coupon != null) {
        setActiveCoupon(coupon);
        setPromoError(null);
        setFieldValue("couponId", coupon.id);
        setFieldTouched("couponId");
      } else {
        setPromoError("Not a valid promo code.");
      }
    },
    [activeProduct, promoCode, checkPromoCode, setFieldTouched, setFieldValue]
  );

  useEffect(() => {
    async function fetchRegions() {
      const countryObj = find(countries, { iso3: country });
      if (countryObj != null) {
        await updateActiveRegions(countryObj.id);
      }
    }

    fetchRegions();
  }, [countries, country]);

  useEffect(() => {
    setTimeout(() => {
      if (
        (group != null &&
          !(
            group.contact.country === country && group.contact.state === state
          ) &&
          !some(activeRegions, { state_code: state })) ||
        (group == null && !some(activeRegions, { state_code: state }))
      ) {
        setFieldValue("state", "");
      }
    }, 0);
  }, [
    regions,
    activeRegions,
    country,
    group,
    state,
    setFieldTouched,
    setFieldValue,
  ]);

  useEffect(() => {
    if (
      activeProduct != null &&
      promoCode !== "" &&
      couponId != null &&
      activeCoupon == null
    ) {
      handleApplyCoupon();
    }
  }, [promoCode, couponId, activeProduct, activeCoupon, handleApplyCoupon]);

  useEffect(() => {
    async function findDefaultCoupon() {
      if (activeProduct == null) {
        return;
      }
      const defaultCoupon = find(
        orderBy(coupons, ["percentageOff"], ["desc"]),
        (coupon) =>
          coupon.isForProduct(activeProduct.id) && totalSeats > coupon.minLimit
      );
      if (defaultCoupon != null) {
        if (
          activeCoupon != null &&
          (!activeCoupon.isDefault || defaultCoupon.id === activeCoupon.id)
        ) {
          return;
        }
        const defaultCode = find(
          defaultCoupon.promoCodes,
          (code) => code.isDefault
        );
        if (defaultCode) {
          const checkedCoupon = await checkPromoCode(
            activeProduct.id,
            defaultCode.code
          );
          if (checkedCoupon != null) {
            setActiveCoupon(checkedCoupon);
            setPromoError(null);
            setFieldValue("promoCode", defaultCode.code);
            setFieldValue("couponId", checkedCoupon.id);
          } else {
            handleClearCoupon();
          }
        }
      } else {
        handleClearCoupon();
      }
    }
    findDefaultCoupon();
  }, [coupons, activeProduct, totalSeats]);

  useEffect(() => {
    if (
      products == null ||
      (planTypeData.length > 1 && planTypeData[0].groupPlan === subLevel)
    ) {
      return;
    }
    const planTypeOptions = map(
      filter(
        products,
        (product) =>
          product.metadata.groupPlan != null &&
          product.metadata.groupPlan.toLowerCase() === subLevel
      ),
      (product) => {
        const dataPoint = find(planTypeData, {
          key: product.metadata.licenseLevelKey,
        });
        return {
          key: product.metadata.licenseLevelKey,
          classGroupId: Number(product.metadata.classGroupId),
          label: product.name,
          productId: product.id,
          groupPlan: product.metadata.groupPlan,
          noOfLicenses:
            dataPoint != null && dataPoint.noOfLicenses > 0
              ? dataPoint.noOfLicenses
              : 0,
        };
      }
    );
    const sorted = orderBy(planTypeOptions, ["classGroupId"], ["asc"]);
    setFieldValue("planTypeData", [...sorted]);
    setFieldTouched("planTypeData", false);
  }, [subLevel, products, setFieldTouched, setFieldValue]);

  useEffect(() => {
    const planType = `group-${subLevel}-yearly-nosub`;
    const changedProduct = find(
      products,
      (product) =>
        product.metadata.planType &&
        product.metadata.planType.toLowerCase() === planType.toLowerCase()
    );
    if (changedProduct != null) {
      setActiveProduct(changedProduct);
      setFieldValue("productId", changedProduct.id, true);
    }
  }, [subLevel, products, setFieldValue]);

  useEffect(() => {
    const groupK5 = find(planTypeData, { key: "group-k5" });
    if (groupK5 != null) {
      updatePriceData(
        map(planTypeData, (level) => {
          if (
            level.key === "group-k5" &&
            level.noOfLicenses != null &&
            level.noOfLicenses !== ""
          ) {
            const { noOfLicenses, ...props } = level;
            const creditDiff = noOfLicenses - credits;
            return {
              noOfLicenses: creditDiff < 0 ? 0 : creditDiff,
              ...props,
            };
          }
          return level;
        })
      );
    } else {
      updatePriceData(planTypeData);
    }
  }, [planTypeData, credits]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "grid",
        gridTemplateColumns: isTab ? "1fr" : "1fr 25vw",
      }}
    >
      <Stack direction="column" mb={2}>
        <Alert severity="info">
          <Typography>
            You will become the first Administrator for this Group Plan. You can
            assign additional Administrators if you choose once your purchase
            has been completed by following the steps below.
          </Typography>
          <Typography>
            After your purchase has been completed, you will be able to start
            inviting users to join your group
          </Typography>
        </Alert>

        <Typography variant="subtitle1">Group Details</Typography>
        <FormikTextField
          fullWidth
          error={get(errors, "organizationName")}
          touched={get(touched, "organizationName", null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={organizationName}
          label="Group Name"
          name="organizationName"
          helperText="What's the best way to describe your group?"
        />
        <FormikTextField
          fullWidth
          error={get(errors, "referenceNo")}
          touched={get(touched, "referenceNo", null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={referenceNo}
          label="Purchase Order Number (Optional)"
          name="referenceNo"
        />
        <Typography variant="subtitle1">Payment Contact Information</Typography>
        <Typography variant="caption">
          This is the primary billing point of contact. This individual will not
          be an Administrator unless you choose to make them one after your
          purchase has been completed
        </Typography>
        <Typography variant="caption">
          If you are paying via invoice, you will be given the option to include
          additional invoice recipients further below
        </Typography>
        <FormikTextField
          fullWidth
          error={get(errors, "fullName")}
          touched={get(touched, "fullName", null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={fullName}
          label="Contact Name"
          name="fullName"
        />
        <FormikTextField
          fullWidth
          error={get(errors, "email")}
          touched={get(touched, "email", null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={email}
          label="Email Address"
          name="email"
        />
        <FormikTextField
          fullWidth
          error={get(errors, "phoneNumber")}
          touched={get(touched, "phoneNumber", null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={phoneNumber}
          label="Phone Number"
          name="phoneNumber"
        />
        <FormikSelectField
          error={get(errors, "country")}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={get(touched, "country", null)}
          value={country}
          label="Country"
          name="country"
          id="country-select"
          size="small"
          options={map(countries, (country) => ({
            key: country.iso3,
            label: `${country.emoji} ${country.name} ${
              country.native != null &&
              country.name.toLowerCase() !== country.native.toLowerCase()
                ? `(${country.native})`
                : ""
            }`,
            default: country.iso3 === "USA",
          })).sort((countryA, countryB) => {
            const aPriority = includes(PRIORITY_COUNTRIES, countryA.key);
            const bPriority = includes(PRIORITY_COUNTRIES, countryB.key);
            if (aPriority && bPriority) {
              return countryA.name - countryB.name;
            }
            if (aPriority) {
              return -1;
            }
            if (bPriority) {
              return 1;
            }
            return countryA.name - countryB.name;
          })}
        />
        <Stack direction="row" gap={4}>
          <FormikTextField
            error={get(errors, "city")}
            touched={get(touched, "city", null)}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={city}
            label="City"
            name="city"
            sx={{
              flex: 1,
            }}
          />
          <FormikSelectField
            error={get(errors, "state")}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={get(touched, "state", null)}
            value={state}
            label="State/Region"
            name="state"
            id="state-select"
            options={regions}
            size="small"
            sx={{
              flex: 1,
            }}
            disabled={regions.length < 1}
            helperText={
              regions.length > 1 ? null : "Country has no states/regions"
            }
          />
        </Stack>
        <Typography variant="subtitle1">Group Licenses</Typography>
        <Typography variant="caption">
          Please select the appropriate group plan below (K-12, Higher Ed, or
          Gov / Public Library)
        </Typography>
        <Typography variant="caption">
          Populate the required field(s) with the number of licenses you are
          purchasing (1 user = 1 license)
        </Typography>
        <Typography variant="caption">
          There is no price difference between students and staff. Please
          include all users in your numbers below
        </Typography>
        <Typography variant="caption">
          Don’t stress about exact numbers; all Group Plans will be allowed 10%
          user overage with zero penalty
        </Typography>
        <Typography variant="caption">
          K-5 purchases qualify for user credits on a one-for-one basis for
          subscriptions that also include licenses for Grades 6-12
        </Typography>
        <ToggleButtonGroup
          fullWidth
          value={subLevel}
          onChange={(event, value) => {
            if (value != null) {
              setSubLevel(value);
            }
          }}
          exclusive
          disabled={group != null}
        >
          <ToggleButton value="k12">K-12</ToggleButton>
          <ToggleButton value="highed">Higher Ed</ToggleButton>
          <ToggleButton value="gov">Gov</ToggleButton>
        </ToggleButtonGroup>
        <Stack direction="row" alignItems="center">
          <Box sx={{ flex: isMobile ? 6 : 8 }} />
          <Typography
            variant="caption"
            sx={{ flex: isMobile ? 2 : 1 }}
            align="right"
          >
            Per User
          </Typography>
          <Typography
            variant="caption"
            sx={{ flex: isMobile ? 4 : 3 }}
            align="right"
          >
            Total
          </Typography>
        </Stack>
        {planTypeData.map((level, index) => (
          <LicenseLevelRow
            planType={level}
            index={index}
            priceData={priceData}
            credits={credits}
            prorationPercentage={prorationPercentage}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            key={level.key}
          />
        ))}
        {typeof get(errors, "planTypeData") === "string" &&
          !!get(touched, "planTypeData", false) && (
            <Alert variant="outlined" severity="error">
              {`${get(errors, "planTypeData")}`}
            </Alert>
          )}
        <Divider />
        <Stack direction="row" alignItems="center">
          <Box sx={{ flex: 8 }} />
          <Typography variant="caption" sx={{ flex: 1 }} align="right">
            Subtotal:
          </Typography>
          <Typography align="right" sx={{ flex: 3 }}>
            <NumericFormat
              value={subtotal}
              decimalScale={2}
              prefix="$"
              defaultValue="-"
              displayType="text"
              fixedDecimalScale={true}
              thousandSeparator=","
              thousandsGroupStyle="thousand"
            />
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <FormikCheckBox
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={isProrated}
            name="isProrated"
            error={get(errors, "isProrated")}
            touched={get(touched, "isProrated", null)}
            label="Yes, prorate my subscription"
            sx={{
              flex: 8,
            }}
          />
          <FormikSelectField
            error={get(errors, "prorateMonth")}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={get(touched, "prorateMonth", null)}
            value={prorateMonth}
            label="End Month"
            name="prorateMonth"
            id="prorateMonth-select"
            disabled={!isProrated}
            options={filter(
              prorationMonths,
              (month) => month.key !== currentMonth
            )}
            size="small"
            sx={{
              flex: 4,
            }}
          />
        </Stack>
        {activeCoupon != null && activePromoCode != null ? (
          <Fragment>
            <Alert
              action={
                <Button
                  color="inherit"
                  variant="text"
                  onClick={handleClearCoupon}
                >
                  CLEAR
                </Button>
              }
            >
              <AlertTitle>{activePromoCode.code}</AlertTitle>
              <Typography>{activePromoCode.description}</Typography>
            </Alert>
            <Stack direction="row" alignItems="center">
              <Box sx={{ flex: 8 }} />
              <Typography variant="caption" sx={{ flex: 1 }} align="right">
                Discount:
              </Typography>
              <Typography align="right" color="error.main" sx={{ flex: 3 }}>
                <NumericFormat
                  value={discount}
                  decimalScale={2}
                  prefix="$-"
                  defaultValue="-"
                  displayType="text"
                  fixedDecimalScale={true}
                  thousandSeparator=","
                  thousandsGroupStyle="thousand"
                />
              </Typography>
            </Stack>
          </Fragment>
        ) : (
          <Stack direction="row" alignItems="flex-start">
            <TextField
              value={promoCode}
              onChange={(event) =>
                setFieldValue("promoCode", event.target.value)
              }
              fullWidth
              label="Promo Code"
              helperText={promoError}
              error={promoError != null}
              onKeyDown={(event) => handleEnterKey(event, handleApplyCoupon)}
            />
            <Button onClick={handleApplyCoupon}>Apply</Button>
          </Stack>
        )}
        <Divider />
        <Stack direction="row" alignItems="center">
          <Box sx={{ flex: 8 }} />
          <Typography variant="caption" sx={{ flex: 1 }} align="right">
            Total:
          </Typography>
          <Typography align="right" sx={{ flex: 3 }}>
            <NumericFormat
              value={total}
              decimalScale={2}
              prefix="$"
              defaultValue="-"
              displayType="text"
              fixedDecimalScale={true}
              thousandSeparator=","
              thousandsGroupStyle="thousand"
            />
          </Typography>
        </Stack>
        <Typography variant="subtitle1">Make Payment</Typography>
        <Typography variant="caption">
          If you select “Credit Card / Electronic Check” you will be directed to
          Stripe’s authentication portal where you will complete your purchase
        </Typography>
        <Typography variant="caption">
          If you select “Pay By Invoice”, you will be asked whether you want to
          add additional recipients to receive the invoice. Following that step,
          you will click “Submit”. Once completed, an invoice will be generated
          and emailed to all the recipients you have identified (it may take a
          few seconds to create the invoice.) While the invoice remains
          outstanding, your Group Plan will be active, however, your group’s
          access may get restricted if payment is not received within 45 days
        </Typography>

        <ToggleButtonGroup
          fullWidth
          value={paymentType}
          onChange={(event, value) => {
            if (value != null) {
              setFieldValue("paymentType", value);
            }
          }}
          exclusive
          name="paymentType"
        >
          <ToggleButton value="direct">
            Credit Card / Electronic Check
          </ToggleButton>
          <ToggleButton value="invoice">Pay by Invoice</ToggleButton>
        </ToggleButtonGroup>
        {some(touched, (property) => {
          if (Array.isArray(property)) {
            return some(property, (item) => some(valuesIn(item), true));
          } else {
            return property;
          }
        }) &&
          !isValid && (
            <Alert severity="error">
              <AlertTitle>Invalid Form Fields Above.</AlertTitle>
            </Alert>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <LoadingButton
            loading={isSubmitting || loading}
            variant="contained"
            type="submit"
            // disabled={!isValid}
          >
            Continue
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  );
}
