import { createSvgIcon } from "@mui/material";

export const ReturnDownIcon = createSvgIcon(
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      points="400 352 464 288 400 224"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "32px",
      }}
    />
    <path
      d="M448,288H154C95.24,288,48,238.67,48,180V160"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "32px",
      }}
    />
  </svg>
);
