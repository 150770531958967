import { Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function MenuNavLink({
  children,
  to,
  onClick,
  title = "test",
  isLanding = false,
}) {
  return (
    <Tooltip title={title} arrow>
      <NavLink
        to={to}
        className={`main-nav-link ${isLanding ? "landing-page" : ""}`}
        onClick={onClick}
      >
        {children}
      </NavLink>
    </Tooltip>
  );
}
