import { searchAPI } from "services/apis.service";
import { VimeoVideoMetadata } from "../models";
import { replaceToken } from "utils/string.util";

export class VimeoService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getVimeoUsersVideosMetadata(userId) {
    try {
      const { data } = await this.#axios.get(
        replaceToken(searchAPI.getVimeoUserVideos, "{userId}", userId),
        { cache: false }
      );
      return data.map((video) => new VimeoVideoMetadata(video));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getVideoMetadata(videoId) {
    try {
      const { data } = await this.#axios.get(
        replaceToken(searchAPI.getVimeoVideo, "{videoId}", videoId)
      );
      return new VimeoVideoMetadata(data[0]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
