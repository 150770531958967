import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function ToolkitSearchBar({
  placeholder,
  handleChange,
  handleSearch,
  buttonText,
  value = null,
  width,
}) {
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const domainName = searchParams.get("domain"); 

  function handleSearchAction(value) {
    if (search === "") return;
    handleSearch(value);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const inputValue = e.target.value;
      handleSearchAction(inputValue);
    }
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
    if (handleChange) {
      handleSearch(e.target.value);
      setSearch(domainName);
    }
  };

  const LookUpButton = ({ buttonText }) => (
    <Button sx={{ my: 1 }} onClick={() => handleSearchAction(search)}>
      {buttonText}
    </Button>
  );

  useEffect(() => {
    if (value != null) {
      setSearch(value);
    } else {
      setSearch("");
    }
  }, [value]);

  return (
    <TextField
      sx={{ width: width ? width : "auto" }}
      value={search}
      placeholder={placeholder}
      InputProps={{ endAdornment: <LookUpButton buttonText={buttonText} /> }}
      onKeyDown={handleKeyDown}
      onChange={handleInputChange}
    />
  );
}
