import { createSvgIcon } from "@mui/material";

export const AcademiaIcon = createSvgIcon(
  <svg
    id="academia-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M49.89566,25.46216l-25.31561,13.72754v4.72949l8.00671,4.74261v16.44342l17.51758,9.43262,17.25983-9.35938v-16.53796l4.02649-2.36816v16.70007l4.0293-2.18506v-21.58105l-25.52429-13.74414Zm13.43921,33.85437l-16.45172,8.92114-10.26709-5.52869v-11.66052l13.2796,7.86584,13.43921-7.90405v8.30627Zm-13.39862-7.00024l-18.10754-9.01013,21.2973-11.54858,18.16846,9.83405-21.35822,10.72467Z" />
  </svg>
);
