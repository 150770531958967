import { useSelector } from "react-redux";
import useCitationFields from "./use-citation-fields.hook";

const useCitationItemSwitch = (
  setLoading,
  baseFields,
  setBaseFields,
  additionalData,
  setAdditionalData,
  articleData,
  setArticleData,
  fields,
  setFields,
  setCurrentItemType,
  setRequiredFields,
  resetArticleData,
  resetAdditionalData
) => {
  const { fetchFields } = useCitationFields();

  const findBaseFieldKeyByValue = (valueToFind) => {
    const baseFieldKey = Object.keys(baseFields).find(
      (key) => baseFields[key] === valueToFind
    );
    return baseFieldKey;
  };

  const getBaseFieldVal = (newBaseFields, field) => {
    const baseFieldKey = findBaseFieldKeyByValue(newBaseFields[field]);

    if (articleData[baseFieldKey]) {
      return articleData[baseFieldKey];
    }
    return additionalData[newBaseFields[field]];
  };

  const checkBaseField = (newBaseFields, field) => {
    return (
      newBaseFields[field] && findBaseFieldKeyByValue(newBaseFields[field])
    );
  };

  const handleEmptyDataFields = (data, fields) => {
    Object.keys(fields).forEach((field) => {
      if (!(field in data) || !data[field]) {
        data[field] = field.toLocaleLowerCase().includes("date") ? null : "";
      }
    });

    return data;
  };

  const handleItemChange = async (item, switchIndex) => {
    setCurrentItemType(item);
    setLoading(true);

    let newArticleData = { itemType: item };
    const fields = await fetchFields(
      switchIndex,
      item,
      baseFields,
      setLoading,
      setFields,
      setRequiredFields
    );

    newArticleData = resetArticleData(
      newArticleData,
      fields.fields,
      articleData,
      fields.baseFields
    );

    const additionalData = resetAdditionalData(
      newArticleData,
      fields.fields,
      articleData
    );

    newArticleData = handleEmptyDataFields(newArticleData, fields.fields);

    setArticleData(newArticleData);
    setAdditionalData(additionalData);

    setLoading(false);
  };

  return { handleItemChange, resetArticleData, resetAdditionalData };
};

export default useCitationItemSwitch;
