import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function TooltipContent() {
  return (
    <React.Fragment>
      <Typography component="p" variant="caption">
        First, we retrieve the full article text directly from the source.
      </Typography>
      <Typography component="p" variant="caption">
        Second, we input the text into our pre-trained transformer model to
        generate the summary as an output.
      </Typography>
      <Typography component="p" variant="caption">
        Our transformer model is specifically designed to summarizer text,
        therefore the only data we provide it is the text from the article.
      </Typography>
      <Typography component="p" variant="caption">
        Relying on our own summarization pipeline significantly reduces risk of
        error while keeping your personal data safe.
      </Typography>
    </React.Fragment>
  );
}

export default function Header() {
  return (
    <Stack sx={{ mt: 2 }} direction="row" gap={1} alignItems="center">
      <Tooltip placement="right-start" title={<TooltipContent />}>
        <InfoOutlinedIcon sx={{ height: 20, width: 20 }} />
      </Tooltip>
      <Typography variant="body1">How do we generate this?</Typography>
    </Stack>
  );
}
