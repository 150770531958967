import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PrivateConfirm = ({
  isOpen,
  onClose,
  makeCollectionPublic,
  collection,
  setView,
}) => {
  const handleConfirm = async () => {
    await makeCollectionPublic(false);
    setView("private");
    onClose();
  };

  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "35vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Change Access
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ mt: 2 }}>
            {`Are you sure you want to make the following collection ${collection.collectionName_chr} and its following sub collections private?`}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: navbarBackground,
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              width: "45%",
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivateConfirm;
