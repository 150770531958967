import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Drawer, useTheme } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { fillParent } from "utils/base-styles";

const Sidebar = ({ children, width = "80vw", setFn = null, toggle = true }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useIsMobile();
  const theme = useTheme();
  const radiusSize = theme.shape.borderRadius * 2;

  const toggleSidebar = useCallback(
    (toggle) => {
      if (setFn != null) {
        dispatch(setFn(toggle));
      }
    },
    [dispatch, setFn]
  );

  useEffect(() => {
    if (isMobile) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
  }, [isMobile, setFn, toggleSidebar, dispatch]);

  useEffect(() => {
    if (isMobile) {
      toggleSidebar(false);
    }
  }, [location, isMobile]);

  return (
    <Drawer
      open={toggle}
      onClose={() => toggleSidebar(false)}
      variant="persistent"
      sx={{
        minWidth: { xs: width, sm: "18vw", xl: "20vw" },
        mr: `${radiusSize}px`,
        "& .MuiDrawer-paper": {
          overflowY: "unset",
          bgcolor: theme.palette.secondary.main,
          border: 0,
          position: { sm: "relative" },
          minWidth: { xs: width, sm: "18vw", xl: "20vw" },
          zIndex: isMobile ? theme.zIndex.drawer : 1,
        },
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            width: radiusSize,
            height: radiusSize,
            position: "absolute",
            top: 0,
            left: "100%",
            bgcolor: theme.palette.secondary.main,
            "&::before": {
              display: "block",
              content: "''",
              bgcolor: theme.palette.background.default,
              borderTopLeftRadius: radiusSize,
              ...fillParent,
            },
          }}
        />
      )}
      {isMobile && (
        <Box sx={{ pl: 3, pt: 3 }}>
          <ArrowRightAltIcon
            onClick={() => toggleSidebar(false)}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      )}
      {children}
    </Drawer>
  );
};

export default Sidebar;
