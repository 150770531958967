import { findIndex } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { axiosInstance } from "lib/axios";
import { toastError } from "utils/toast.util";

import {
  setGroupControlsLoading,
  setGroupCodes,
  setGroupDomains,
} from "../context";
import { GroupService } from "../services";
import { GroupDomain } from "../models";

const apiSync = { getCodes: false, getDomains: false };

export function useGroupControls() {
  const dispatch = useDispatch();

  const groupService = new GroupService(axiosInstance);

  const { groupControlsLoading, groupCodes, group, groupDomains } = useSelector(
    (state) => state.group
  );

  const isGroupControlsLoading = useMemo(
    () => groupControlsLoading > 0,
    [groupControlsLoading]
  );

  function handleLoading(isLoading) {
    if (isLoading) {
      dispatch(setGroupControlsLoading(groupControlsLoading + 1));
    } else {
      dispatch(
        setGroupControlsLoading(
          groupControlsLoading > 0 ? groupControlsLoading - 1 : 0
        )
      );
    }
  }

  async function getGroupCodes() {
    apiSync.getCodes = true;
    handleLoading(true);
    try {
      const codes = await groupService.getGroupCodes();
      dispatch(setGroupCodes(codes));
    } catch (error) {
      toastError("Could not retrieve Group Codes.");
    } finally {
      handleLoading(false);
    }
  }

  async function getGroupDomains() {
    apiSync.getDomains = true;
    handleLoading(true);
    try {
      const domains = await groupService.getGroupDomains();
      dispatch(setGroupDomains(domains));
    } catch (error) {
      toastError("Gould not retrieve Group Domains.");
    } finally {
      handleLoading(false);
    }
  }

  async function createGroupCode() {
    handleLoading(true);
    try {
      const newCode = await groupService.createGroupCode();
      dispatch(setGroupCodes([...groupCodes, newCode]));
      return newCode;
    } catch (error) {
      toastError("Unable to create new Group Code.");
    } finally {
      handleLoading(false);
    }
  }

  async function deleteGroupCode(groupCode) {
    handleLoading(true);
    try {
      await groupService.deleteGroupCode(groupCode.id);
    } catch (error) {
      toastError("Could not delete Group Code: " + groupCode.code);
    } finally {
      handleLoading(false);
    }
  }

  async function createUpdateDomain(groupDomain) {
    handleLoading(true);
    try {
      const responseDomain = await groupService.addUpdateDomain(
        groupDomain.id,
        groupDomain.domain
      );
      const domainIndex = findIndex(
        groupDomains,
        groupDomain.id == null
          ? { domain: responseDomain.domain }
          : { id: responseDomain.id }
      );

      if (domainIndex > -1) {
        dispatch(
          setGroupDomains(
            groupDomains.toSpliced(domainIndex, 1, responseDomain)
          )
        );
      } else {
        dispatch(setGroupDomains([...groupDomains, responseDomain]));
      }
      return true;
    } catch (error) {
      toastError(`Unable to Create/Update domain: ${groupDomain.domain}`);
      return false;
    } finally {
      handleLoading(false);
    }
  }

  async function deleteDomain(groupDomain) {
    handleLoading(true);
    try {
      await groupService.deleteDomain(groupDomain.id);
    } catch (error) {
      toastError(`Could not delete group domain: ${groupDomain.domain}`);
    } finally {
      handleLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (!apiSync.getCodes) {
        await getGroupCodes();
      }
      if (!apiSync.getDomains) {
        await getGroupDomains();
      }
    }
    fetchData();
  }, [group]);

  return {
    createGroupCode,
    createUpdateDomain,
    deleteDomain,
    deleteGroupCode,
    getGroupCodes,
    getGroupDomains,
    groupCodes,
    groupDomains,
    isGroupControlsLoading,
  };
}
