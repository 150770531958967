import React from "react";
import { Typography, Stack, Button } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useSelector } from "react-redux";
import { getFormattedDate } from "utils/time.util";
import ProfileAvatar from "components/base/ProfileAvatar";

export default function PublicCollectionDetailCard({ collection, length }) {
  const { user } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body4" noWrap sx={{ fontSize: "2rem" }}>
          {collection.collectionName_chr}
        </Typography>
        <LanguageIcon sx={{ color: "#a9baca" }} />
      </Stack>
      <Typography variant="body2">{`${length} bookmarks`}</Typography>
      <Stack sx={{ mt: 2 }} direction="row" justifyContent="space-between">
        <Stack direction={"row"}>
          <ProfileAvatar userId={user.userId} size={10} />
          <Typography variant="body1" noWrap>@{user.userName}</Typography>
        </Stack>
        <Typography color="grey" variant="body2">
          {getFormattedDate(new Date())}
        </Typography>
      </Stack>
    </React.Fragment>
  );
}
