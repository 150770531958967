import React, { useState } from "react";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { writeStorage } from "@rehooks/local-storage";

const ColumnsSection = ({ visibilityModel, setVisibilityModel, columns, label }) => {
  const [anchorEl, setAnchorEl] = useState(null); // Track the button as anchor
  const open = Boolean(anchorEl);

  const handleOpenManageColumns = (event) => {
    setAnchorEl(event.currentTarget); // Use currentTarget to bind to the IconButton
  };

  const handleCloseManageColumns = () => {
    setAnchorEl(null);
  };

  const handleToggleColumnVisibility = (event, field) => {
    const newVisibilityModel = {
      ...visibilityModel,
      [field]: event.target.checked,
    };
    setVisibilityModel(newVisibilityModel);
    writeStorage(label, newVisibilityModel);
  };

  return (
    <Box sx={{ display: "flex", mb: 2 }}>
      <Stack
        onClick={handleOpenManageColumns}
        direction="row"
        gap={0.5}
        alignItems="center"
      >
        <IconButton>
          <ViewWeekIcon />
        </IconButton>
        <Typography sx={{ cursor: "pointer" }}>COLUMNS</Typography>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseManageColumns}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {Object.keys(visibilityModel).map((field) => (
          <MenuItem
            key={field}
            sx={{ paddingY: 0, minHeight: "32px", width: "15vw" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibilityModel[field] ?? true}
                  onChange={(event) =>
                    handleToggleColumnVisibility(event, field)
                  }
                />
              }
              label={columns.find((col) => col.field === field)?.headerName}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ColumnsSection;
