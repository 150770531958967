export class OpenedMetric {
  constructor(data, index) {
    this.id = index;
    this.category = data.category_chr;
    this.sourceName = data.entity_chr;
    this.biasRating = data.mediaBiasRating_chr;
    this.newsguardScore =
      data.score_chr == null ? null : Number(data.score_chr);
    this.count = data.metricCount_lng;
  }
}

export class BasicMetric {
  constructor(data, index) {
    this.id = index;
    this.metricName = data.metricProperty_chr;
    this.value = data.metricCount_lng;
  }
}
