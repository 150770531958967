import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getrecentCollectionSource,
  getrecentCollections,
} from "../services/recent-collection.service";
import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

const useRecentCollection = (filter) => {
  const [recentCollections, setRecentCollections] = useState([]);
  const [filteredRecentCollection, setFilteredRecentCollection] = useState([]);
  const [recentCollectionSource, setRecentCollectionSource] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const abortControllerRef = useRef(null);

  const getRecentCollections = async () => {
    try {
      const data = await getrecentCollections(user);
      setRecentCollections(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getRecentCollectionSource = async () => {
    try {
      const data = await getrecentCollectionSource(user, "");
      setRecentCollectionSource(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const getFilteredCollectionSource = async () => {
      try {
        const data = await getrecentCollectionSource(user, filter, signal);
        setFilteredRecentCollection(data);
      } catch (e) {
        if (e.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.log(e);
        }
      }
    };

    getFilteredCollectionSource();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [filter]);

  useEffect(() => {
    getRecentCollections();
    getRecentCollectionSource();
  }, []);

  return {
    recentCollections,
    recentCollectionSource,
    filteredRecentCollection,
  };
};

export default useRecentCollection;
