import { axiosInstance } from "lib/axios";
import { getConfig, searchAPI } from "services/apis.service";

export const getCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getCollections,
    {
      callingUserId: user.userId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getCollectionsSource = async (collectionId, collectionSourceId) => {
  const {data}  = await axiosInstance.post(searchAPI.getCollectionsSource, {
    collectionId: collectionId,
    collectionSourceId: collectionSourceId,
  }, {cache: false});

  return data;
}

export const createNewCollection = async (user, name, collectionId) => {
  const requestsData = {
    callingUserId: user.userId,
    userId: user.userId,
    collectionName: name,
    parentCollectionId: collectionId,
  };
  const { data } = await axiosInstance.post(
    searchAPI.updateCollections,
    requestsData,
    {
      cache: false,
    }
  );
  return data;
};

export const addOrUpdateBookmark = async (
  user,
  summaryInfoData,
  markdownSummary,
  collectionID_ids,
  collectionSourceId,
  markdownLevel,
  levelLoaded = false,
  summaryLoaded = false,
  level
) => {
  let json_res = {
    callingUserId: user.userId,
    userId: user.userId,
    collectionId: collectionID_ids,
    title: summaryInfoData.title,
    sourceName: summaryInfoData.publisher,
    sourceURL: summaryInfoData.url,
    sourcePublishedDateTime: new Date(summaryInfoData.published_date),
  };
  if (collectionSourceId) {
    json_res = { ...json_res, collectionSourceId: collectionSourceId };
  }
    if (summaryLoaded) {
      json_res.summary = markdownSummary;
    }

    if (level) {
      json_res.comprehensionLevel = level;
    }

    if (markdownLevel) {
      json_res.articleComprehension = markdownLevel;
    }

  const { data } = await axiosInstance.post(
    searchAPI.AddUpdateCollectionsSource,
    json_res,
    {
      cache: false,
    }
  );

  return data;
};

export const saveCitation = async (
  articleData,
  id,
  user,
  citation,
  selectedStyle,
  authors
) => {
  let json_res = {
    ...articleData,
    styles: selectedStyle,
    collectionSourceId: id,
    userId: user.userId,
    citation: citation,
    creators: authors,
  };
  await axiosInstance.post(searchAPI.saveCitation, json_res, {
    cache: false,
  });
};

export const deleteBookmark = async (user, id) => {
  await axiosInstance.delete(searchAPI.deleteCollectionsSource, {
    data: {
      callingUserId: user.userId,
      collectionSourceId: id,
      isHardDelete: false,
    },
  });
};

export const getParentCollections = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getParentCollections,
    {
      callingUserId: user.userId,
      userId: user.userId,
      parentCollectionId: id,
    },
    {
      cache: false,
    }
  );

  return data;
};
