import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loginUser,
  logoutUser,
  setMicrosoftUser,
} from "context/features/authSlice";
import { useUserData } from "features/User";

export function useAuth({ handleLoading, toast }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { setCategories } = useUserData();

  function handleLoginUser(data) {
    dispatch(loginUser(data));
  }

  function handleMicrosoftUser(data) {
    dispatch(setMicrosoftUser(data));
  }

  function handleLogoutUser() {
    dispatch(logoutUser());
  }

  async function handleExternalLogin(token, provider, serviceUri) {
    try {
      handleLoading(true);
      const res = await axios.post(
        `${serviceUri}`,
        {
          token: token,
          provider: provider,
        },
        {
          cache: false,
        }
      );
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("login", true);

      //setData(res.data);
      setCategories(res.data.userId);

      //dispatch(loginUser({ user: res.data }));
      handleLoginUser({ user: res.data });
      localStorage.setItem("email", res.data.email);
      //dispatch(setMicrosoftUser(true));
      handleMicrosoftUser(true);

      handleLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  function redirectToCleverOAuth() {
    const clientId = process.env.REACT_APP_API_PROD_CLEVER_CLIENTID;
    const redirectUri = encodeURIComponent(
      "https://app.sooth.fyi/signin/clever"
    );
    return `https://clever.com/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
  }

  function redirectToClasslinkOAuth() {
    const clientId = process.env.REACT_APP_API_PROD_CLASSLINK_CLIENTID;
    const redirectUri = encodeURIComponent(
      "https://app.sooth.fyi/signin/classlink"
    );
    return `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirectUri}&client_id=${clientId}&response_type=code`;
  }
  async function handleRedirectLogin(provider, apiUrl) {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code && location.includes(provider)) {
      try {
        handleLoading(true);
        const res = await axios.post(
          `${apiUrl}?code=${code}&provider=${provider}`
        );
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("login", true);

        setCategories(res.data.userId);

        handleLoginUser({ user: res.data });
        localStorage.setItem("email", res.data.email);

        handleLoading(false);
      } catch (error) {
        console.error("Error sending the code to backend:", error);
        if (toast != null) {
          toast.error(`There was an issue logging in with ${provider}.`);
        }
      }
    }
  }

  function handleLogout(microsoftUser, instance) {
    if (microsoftUser != null) {
      if (instance) {
        sessionStorage.clear();
      } else {
        console.error("MSAL instance is not available.");
      }
    }
    if (sessionStorage.getItem("user")) {
      sessionStorage.removeItem("user");
    } else {
      localStorage.removeItem("user");
    }
    window.location.href = "/";

    handleLogoutUser();
  }

  return {
    handleExternalLogin,
    handleLoginUser,
    handleLogout,
    handleMicrosoftUser,
    handleRedirectLogin,
    redirectToClasslinkOAuth,
    redirectToCleverOAuth,
  };
}
