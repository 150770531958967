import { Box, TextField, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditorBox from "../Editor";

const Header = ({
  isEditing,
  displayFonts,
  setDisplayFonts,
  editorState,
  setEditorState,
  saveNote,
  isSaving,
  handleIsBlur=() => {}
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    handleIsBlur();
    setIsFocused(false);
  }

  useEffect(() => {
    isEditing && setIsFocused(true);
  }, [isEditing]);

  if (isSaving) {
    return (
      <Skeleton
        variant="rectangular"
        height={100}
        width="100%"
        sx={{ borderRadius: 1 }}
      />
    );
  }

  return !isFocused ? (
    <Box p={2}>
      <TextField
        value="Add a note..."
        onFocus={() => setIsFocused(true)}
        sx={{
          width: "100%",
          my: 3,
          color: "red",
          "& .MuiInputBase-input": {
            color: "grey",
          },
        }}
        label="Note"
      />
    </Box>
  ) : (
    <EditorBox
      isFocused={displayFonts}
      setIsFocused={setDisplayFonts}
      editorState={editorState}
      setEditorState={setEditorState}
      handleSave={saveNote}
      isBlur={true}
      handleBlur={handleBlur}
    />
  );
};

export default Header;
