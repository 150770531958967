import React from "react";
import { Box, Paper, Typography, useTheme, alpha } from "@mui/material";

const SummaryInfoBox = ({ header, content }) => {
  const { palette } = useTheme();
  return header ? (
    <Paper
      sx={{
        display: "flex",
        p: 3,
        height: "100%",
        boxShadow: 0,
        backgroundColor: palette.secondary.main,
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        {header && <Typography variant="h5">{header}</Typography>}
        {content && (
          <Typography fontSize="1.8rem" fontWeight="bold">
            {content}
          </Typography>
        )}
      </Box>
    </Paper>
  ) : (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        justifyContent: "center",
        height: "100%",
        boxShadow: 0,
        backgroundColor: alpha(palette.warning.light, 0.25),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          justifyContent: "center",
        }}
      >
        {content && (
          <Typography
            variant="caption"
            sx={{
              color: palette.getContrastText(palette.warning.light),
            }}
          >
            {content}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default SummaryInfoBox;
