import { axiosInstance } from "lib/axios";
import { authAPI, searchAPI } from "services/apis.service";

export const getPublicCollections = async (user, filter, signal) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollections,
    {
      callingUserId: user.userId,
      filter: filter,
    },
    {
      cache: false,
      signal: signal,
    }
  );
  return data;
};

export const getPublicCollectionsSource = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollectionsSource,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    },
    {
      cache: true,
    }
  );

  return data;
};

export const getPublicCollectionOverview = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.publicCollectionOverView,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    }
  );

  return data;
};
