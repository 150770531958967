import { NavLink } from "react-router-dom";

export default function SideNavLink({
  style,
  children,
  to,
  onClick,
  end = true,
}) {
  return (
    <NavLink
      style={style}
      onClick={onClick}
      to={to}
      end={end}
      className="side-nav-link"
    >
      {children}
    </NavLink>
  );
}
