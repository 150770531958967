import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { colorArray } from "data/barchart";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const labels = ["L", "", "C", "", "R"];

export default function MediaBiasChart({ allSidesData }) {
  const theme = useTheme();
  const array = allSidesData ? Object.values(allSidesData) : [];
  const formattedArray = array
    ? array.map((value) => (value ? parseInt(value) : 0))
    : [];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    chartArea: {
      top: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        color: theme.palette.grey[700],
        font: {
          size: 14,
        },
        formatter: (value, context) => {
          return value ? `${value}%` : "";
        },
      },
    },
    scales: {
      x: {
        position: "top",
        border: {
          display: false,
        },
        grid: {
          // display: false,
          // drawBorder: false,
          // drawTicks: false,
          lineWidth: 0,
        },
        ticks: {
          padding: 25,
          showLabelBackdrop: true,
          backdropColor: "#a3becf",
          color: theme.palette.common.white,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    layout: {
      autoPadding: false,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Bias Distribution",
        data: formattedArray.slice(0, -1),
        backgroundColor: colorArray,
        borderColor: colorArray,
        borderWidth: 1,
        categoryPercentage: 1.0,
        barPercentage: 0.9,
      },
    ],
  };

  return (
    <Stack
      direction="row"
      height="100%"
      sx={{
        gridArea: "ratings",
      }}
    >
      <Stack
        direction="column"
        height="100%"
        justifyContent="space-between"
        width={{ xs: "100%", md: "30%" }}
        alignItems="flex-start"
        gap="5%"
      >
        <Typography color="grey" variant="body2">
          AllSides Media Bias Rating
          <Typography
            component="sup"
            variant="h6"
            color="primary.dark"
            fontSize="0.6em"
          >
            TM
          </Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Typography color="grey" variant="body2">
            No rating
          </Typography>
          <Typography variant="body2">{formattedArray[5]}%</Typography>
        </Box>
      </Stack>
      <Box
        width={{ md: "65%" }}
        height="100%"
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        }}
      >
        <Bar data={data} options={options} width="100%" height="100%" top={0} />
      </Box>
    </Stack>
  );
}
