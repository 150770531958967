import { useState, useRef, useEffect } from "react";
import { setCitationData } from "context/features/dataSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { generateCitation } from "features/Bookmarks/services/citation.service";
import { useSelector } from "react-redux";

const useCitationGenerate = (
  requiredFields,
  articleData,
  setLoading,
  citation,
  setCitation,
  currentItemType,
  selectedStyle,
  authors,
  setDisable
) => {
  const dispatch = useDispatch();
  const [tooltipText, setTooltipText] = useState("Copy");
  const abortControllerRef = useRef(null);

  const { isCitationAvailable } = useSelector((state) => state.data);

  const handleMouseLeave = () => {
    setTooltipText("Copy");
  };

  const handleCopyClick = () => {
    const textToCopy = citation;
    const html = new Blob([textToCopy], { type: "text/html" });
    const plain = new Blob([textToCopy.replace(/<[^>]*>?/gm, "")], {
      type: "text/plain",
    });

    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/html": html,
          "text/plain": plain,
        }),
      ])
      .then(() => {
        setTooltipText("Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const fetchCitation = async (fields, isDataModified) => {
    if (isCitationAvailable && !isDataModified) return;
    if (Object.keys(fields).length === 0) return;
    const anyFieldMissing = requiredFields.some((field) => {
      return !articleData[field];
    });
    if (anyFieldMissing) {
      console.log("Please fill in all required fields.");
      setLoading(false);
      setDisable(true);
      return;
    }

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    // if (!citation) setLoading(true);

    try {
      const data = await generateCitation(
        currentItemType,
        selectedStyle,
        articleData,
        authors
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const entryElement = doc.querySelector(".csl-entry");

      if (entryElement) {
        const citationHTML = entryElement.innerHTML;
        setCitation(citationHTML);
        dispatch(setCitationData(citationHTML));
      }
    } catch (err) {
      if (err.name !== "AbortError") {
        toast.error(
          "Some error occurred while generating citation. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  return { fetchCitation, tooltipText, handleCopyClick, handleMouseLeave };
};

export default useCitationGenerate;
