import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    biasRatingLoading: false,
    biasRatingSourceData: [],
    categories: null,
    categorySourceData: [],
    categorySourceLoading: false,
    infoDietEndDate: null,
    infoDietSourceTableData: [],
    infoDietStartDate: null,
    infoDietTimeframe: 0,
    openedLoading: false,
    profileSidebar: true,
  },
  reducers: {
    setBiasRatingLoading: (state, action) => {
      const { payload } = action;
      state.biasRatingLoading = payload;
    },
    setBiasRatingSourceData: (state, action) => {
      const { payload } = action;
      state.biasRatingSourceData = payload;
    },
    setCategoriesData: (state, action) => {
      const { payload } = action;

      state.categories = payload;
    },
    setCategorySourceData: (state, action) => {
      const { payload } = action;

      state.categorySourceData = payload;
    },
    setCategorySourceLoading: (state, action) => {
      const { payload } = action;

      state.categorySourceLoading = payload;
    },
    setInfoDietEndDate: (state, action) => {
      const { payload } = action;
      state.infoDietEndDate = payload;
    },
    setInfoDietSourceTableData: (state, action) => {
      const { payload } = action;
      state.infoDietSourceTableData = payload;
    },
    setInfoDietStartDate: (state, action) => {
      const { payload } = action;
      state.infoDietStartDate = payload;
    },
    setInfoDietTimeframe: (state, action) => {
      const { payload } = action;
      state.infoDietTimeframe = payload;
    },
    setOpenedLoading: (state, action) => {
      const { payload } = action;
      state.openedLoading = payload;
    },
    setProfileSidebar: (state, action) => {
      const { payload } = action;

      state.profileSidebar = payload;
    },
  },
});

export const {
  setBiasRatingLoading,
  setBiasRatingSourceData,
  setCategoriesData,
  setCategorySourceData,
  setCategorySourceLoading,
  setInfoDietEndDate,
  setInfoDietSourceTableData,
  setInfoDietStartDate,
  setInfoDietTimeframe,
  setOpenedLoading,
  setProfileSidebar,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
