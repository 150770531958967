export class UserInfo {
  #id;
  #email;
  #createdDate;
  #groupLicenseLevel;
  #groupId;
  #groupRole;

  constructor({
    userName,
    userId,
    email,
    acceptedMISTerms,
    registrationdate,
    collectionCount,
    isCategoriesSelected,
    groupLicenseKey,
    groupId,
    groupRole,
  }) {
    this.#id = userId;
    this.#email = email;
    this.#createdDate = registrationdate;
    this.#groupLicenseLevel = groupLicenseKey;
    this.#groupId = groupId;
    this.#groupRole = groupRole;
    this.username = userName;
    this.acceptedMisTerms = acceptedMISTerms;
    this.collectionCount = collectionCount;
    this.isCategoriesSelected = isCategoriesSelected;
  }

  get id() {
    return this.#id;
  }
  get email() {
    return this.#email;
  }
  get createdDate() {
    return this.#createdDate;
  }
  get gradeLicenseLevel() {
    return this.#groupLicenseLevel;
  }
  get groupId() {
    return this.#groupId;
  }
  get groupRole() {
    return this.#groupRole;
  }
}
