import dayjs from "lib/dayjs";
import { Fragment, useMemo, useState } from "react";
import { CloudUploadOutlined } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import { scrollingBox } from "utils/base-styles";
import { useGroup } from "../hooks";
import { Box } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function GroupUserBulkUpload({ open, handleClose }) {
  const { uploadBulkUsers, resetUsers, group } = useGroup();

  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(null);

  const isHighEd = useMemo(
    () => group != null && group.planType.includes("highEd"),
    [group]
  );
  const isK12 = useMemo(
    () => group != null && group.planType.includes("k12"),
    [group]
  );

  async function submitAndClose() {
    setProcessing(true);
    setErrors(null);
    const response = await uploadBulkUsers(file);
    if (response.errors != null) {
      setErrors(response.errors);
    } else {
      await resetUsers();
      resetAndClose(true);
    }
    setProcessing(false);
  }

  function resetAndClose(override = false) {
    if (!processing || override) {
      setFile(null);
      setErrors(null);
      handleClose();
    }
  }

  function handleFile(event) {
    setFile(event.target.files[0]);
  }
  return (
    <Dialog open={open} onClose={resetAndClose}>
      <DialogTitle>Bulk User Upload</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can bulk upload your users using a csv file. The file needs to
          have three columns: email, license, role.
        </DialogContentText>
        <Typography my={1} variant="subtitle2">
          Column Details
        </Typography>
        <DialogContentText mb={1}>
          <Typography component="span" fontWeight="bold">
            email:
          </Typography>{" "}
          email string for each user.
        </DialogContentText>
        <DialogContentText mb={1}>
          <Typography component="span" fontWeight="bold">
            license:
          </Typography>{" "}
          {isHighEd && (
            <Typography component="span">
              Higher Education will always use the 'group-highed' license key.
            </Typography>
          )}
          {isK12 && (
            <Typography component="span">
              For your group plan you can assign users to the following license
              levels: '
              {group.licenseLevels.map((level) => level.key).join("', '")}'
            </Typography>
          )}
        </DialogContentText>
        <DialogContentText mb={1}>
          <Typography component="span" fontWeight="bold">
            role:
          </Typography>{" "}
          Users can be assigned one of two roles: "Student" or "Teacher". If
          this column is left blank, then all new users will be assigned the
          role of "Student".
        </DialogContentText>
        <DialogContentText>
          <Alert severity="info">
            You can only assign the "Administrator" role within the User
            Management portal to users.
          </Alert>
        </DialogContentText>
      </DialogContent>
      {errors != null && (
        <DialogContent
          sx={{
            maxHeight: "15vh",
            ...scrollingBox,
          }}
        >
          <Typography variant="subtitle1" color="error">
            File Errors ({errors.length})
          </Typography>
          <List>
            {errors != null &&
              errors.map((error, index) => (
                <ListItem dense key={`error-${index}`}>
                  <Alert severity="error">{error}</Alert>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      )}
      <DialogContent>
        {processing ? (
          <Fragment>
            {file != null && (
              <Typography variant="caption" component="div">
                {file.name} (Modified: {dayjs(file.lastModified).format("l LT")}
                )
              </Typography>
            )}
            <LinearProgress />
          </Fragment>
        ) : (
          <Fragment>
            <Stack direction="row" alignItems="center">
              <Button
                size="small"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadOutlined />}
              >
                <Box
                  component="span"
                  sx={{
                    height: "18px",
                  }}
                >
                  Choose File
                </Box>
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFile}
                  accept=".csv, .xslx, .xls"
                />
              </Button>
              {file != null && (
                <Typography variant="caption">
                  {file.name} (Modified:{" "}
                  {dayjs(file.lastModified).format("l LT")})
                </Typography>
              )}
            </Stack>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "secondary.main" }}>
        <Button
          onClick={resetAndClose}
          disabled={processing}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={submitAndClose} disabled={file == null || processing}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
