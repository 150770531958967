import { axiosInstance } from 'lib/axios'
import React from 'react'
import { openaiAPI } from 'services/apis.service'

const fetchRelatedSearchService = async (search) => {
  const { data } = await axiosInstance.post(openaiAPI.relatedSearch, {
    search_query: search,
  });
  return data;
};

export default fetchRelatedSearchService;