import { useParams } from "react-router-dom";
import NewsCategoryPage from "./NewsCategoryPage";
import Newspage from "./Newspage";

export default function SearchSourceSwitch() {
  const { source } = useParams();

  if (source === "categories" || source === "rating") {
    return <Newspage />;
  }
  return <NewsCategoryPage />;
}
