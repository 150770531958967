import React, { useEffect, useState, useCallback } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Box, Stack, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { setSummaryInfoData } from "context/features/dataSlice";

const EditBookmark = () => {
  const summaryInfoData = useSelector(
    (state) => state.data.summaryInfoData,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [localPublisher, setLocalPublisher] = useState(
    summaryInfoData.publisher
  );
  const [localPublishedDate, setLocalPublishedDate] = useState(
    dayjs(summaryInfoData.published_date)
  );
  const [localTitle, setLocalTitle] = useState(summaryInfoData.title);
  const [localURL, setLocalURL] = useState(summaryInfoData.url);

  const handleInputChange = useCallback((e, change) => {
    change(e.target.value);
  }, []);

  const handleDateChange = (val) => {
    setLocalPublishedDate(val);
  };

  const shouldDisableFutureDates = (date) => {
    return date.isAfter(dayjs());
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        setSummaryInfoData({
          publisher: localPublisher,
          title: localTitle,
          published_date: new Date(localPublishedDate.$d),
          url: localURL,
        })
      );
    }, 300);

    return () => clearTimeout(timer);
  }, [localPublisher, localPublishedDate, localTitle, localURL]);

  return (
    <Box p={2}>
      <Stack justifyContent="center" px={3} gap={2}>
        <TextField
          onChange={(e) => handleInputChange(e, setLocalURL)}
          label="URL"
          value={localURL}
          sx={{ mt: 2 }}
        />
        <TextField
          onChange={(e) => handleInputChange(e, setLocalTitle)}
          label="Title"
          value={localTitle}
        />
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <TextField
            onChange={(e) => handleInputChange(e, setLocalPublisher)}
            label="Source"
            value={localPublisher}
            sx={{ width: "100%" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Published Date"
              value={localPublishedDate}
              onChange={(val) => handleDateChange(val)}
              shouldDisableDate={shouldDisableFutureDates}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </Box>
  );
};

export default React.memo(EditBookmark);
