import "react-toastify/dist/ReactToastify.css";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  CheckOutlined,
  ErrorOutline,
  InfoOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import CssBaseLine from "@mui/material/CssBaseline";

import { homeUrl } from "services/apis.service";
import Router from "Router";
import {
  useTheme as useThemeContext,
  useIsSystemDarkMode,
} from "./ThemeContext";
import UserLicenseLevelModal from "features/Group/components/global/UserLicenseLevelModal";

function App() {
  const { themePref } = useThemeContext();
  const { isSystemDarkMode } = useIsSystemDarkMode();

  const { palette } = useTheme();

  const messageHandlers = (event) => {
    if (event.data.action === "setSessionStorage") {
      const { key, value } = event.data.payload;
      sessionStorage.setItem(key, value);
      window.location.reload();
    }
    if (String(window.location.href) === `${homeUrl()}admin`) {
      sessionStorage.removeItem("user");
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageHandlers);
    return () => {
      window.removeEventListener("message", messageHandlers);
    };
  }, []);

  return (
    <Fragment>
      <CssBaseLine enableColorScheme />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable={50}
        pauseOnHover
        icon={({ type }) => {
          switch (type) {
            case "success":
              return <CheckOutlined sx={{ color: palette.success.main }} />;
            case "info":
              return <InfoOutlined sx={{ color: palette.info.main }} />;
            case "warning":
              return <WarningOutlined sx={{ color: palette.warning.main }} />;
            case "error":
              return <ErrorOutline sx={{ color: palette.error.main }} />;
            default:
              return false;
          }
        }}
        theme={
          themePref === "system"
            ? isSystemDarkMode
              ? "dark"
              : "light"
            : themePref
        }
      />
      <BrowserRouter>
        <Router />
        <UserLicenseLevelModal />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
