import React from "react";
import RightDrawer from "components/base/RightDrawer";
import SourceInfo from "./components/SourceInfo";

export default function SourceSidebar({
  isSourceSidebarOpen,
  sourceSidebarClosedHandler,
}) {
  return (
    <RightDrawer
      open={isSourceSidebarOpen}
      onClose={sourceSidebarClosedHandler}
    >
      <SourceInfo handleClose={sourceSidebarClosedHandler} />
    </RightDrawer>
  );
}
