import { filter, reduce, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { lightBlue } from "@mui/material/colors";
import { useInfoDiet } from "../hooks";

export default function CategorySource() {
  const [totalCount, setTotalCount] = useState(0);
  const [dataset, setDataset] = useState([]);
  const [colors, setColors] = useState([]);
  const theme = useTheme();
  const {
    categorySourceLoading,
    categorySourceData,
    getCategoryMetrics,
    infoDietTimeframe,
    infoDietEndDate,
    infoDietStartDate,
  } = useInfoDiet();

  useEffect(() => {
    const keys = Object.keys(lightBlue).filter(
      (key) => !key.includes("A") && key !== "50"
    );
    const colors = keys.map((key) => lightBlue[key]);
    setColors(colors.reverse());
  }, [lightBlue]);

  useEffect(() => {
    const total = reduce(
      categorySourceData,
      (sum, item) => sum + item.value,
      0
    );
    setTotalCount(total);
    const dataset = sortBy(
      filter(categorySourceData, (metric) => metric.value > 0).map(({ id, metricName, value }) => ({
        id,
        value,
        label: metricName,
      })),
      (item) => item.value
    );
    setDataset(dataset.reverse());
  }, [categorySourceData]);

  useEffect(() => {
    getCategoryMetrics();
  }, [infoDietTimeframe, infoDietEndDate, infoDietStartDate]);

  const series = [
    {
      data: dataset,
      innerRadius: 50,
      paddingAngle: 3,
      cornerRadius: 5,
    },
  ];

  return (
    <Box
      sx={{
        p: 4,
        bgcolor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius / 10,
        display: "grid",
        gridTemplateAreas: '"title divider naRating" "chart chart chart"',
        gridTemplateColumns: "9fr auto 3fr",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Typography
        component="div"
        variant="h6"
        color="primary.dark"
        sx={{
          gridArea: "title",
        }}
      >
        Source Categories
      </Typography>
      <Box
        sx={{
          gridArea: "chart",
          minHeight: totalCount > 0 ? "40vh" : "15vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "80vh",
        }}
      >
        {categorySourceLoading ? (
          <Skeleton variant="circular" width="15vw" height="15vw" />
        ) : totalCount > 0 ? (
          <PieChart
            margin={{
              left: 0,
              top: 10,
              right: 0,
              bottom: dataset.length * 28.6,
            }}
            colors={colors}
            series={series}
            slotProps={{
              legend: {
                direction: "column",
                position: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                labelStyle: {
                  fontSize: theme.typography.caption.fontSize,
                },
                itemMarkWidth: 20,
                itemMarkHeight: 2,
                markGap: 5,
                itemGap: 10,
              },
            }}
          />
        ) : (
          <Typography component="div">
            No source category data available.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
