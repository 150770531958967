import { useState } from "react";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

import { useVideos } from "../hooks";
import { VideoDialog } from ".";

export default function FeatureHelpVideo({
  videoId,
  title,
  showText = false,
  sx,
  ...props
}) {
  const { getVimeoVideoData } = useVideos();

  const [helpVideo, setHelpVideo] = useState(null);

  return (
    <Box
      {...props}
      sx={{
        display: "inline-block",
        ...sx,
      }}
    >
      {!showText ? (
        <Tooltip title={title}>
          <IconButton
            size="small"
            onClick={async () => {
              const video = await getVimeoVideoData(videoId);
              setHelpVideo(video);
            }}
          >
            <HelpOutlineOutlined fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="text"
          size="small"
          startIcon={<HelpOutlineOutlined size="small" />}
          onClick={async () => {
            const video = await getVimeoVideoData(videoId);
            setHelpVideo(video);
          }}
          sx={({ typography }) => ({
            ...typography.body2,
          })}
        >
          {title}
        </Button>
      )}
      {helpVideo != null && (
        <VideoDialog
          open={helpVideo != null}
          handleClose={() => setHelpVideo(null)}
          title={helpVideo.title}
          id={helpVideo.id}
        />
      )}
    </Box>
  );
}
