import React from "react";
import { Box, Typography } from "@mui/material";

export default function MisinformationFingerprintFooter() {
  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        p: "2rem",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
      }}
    >
      <Typography variant="body5">
        Not what you were looking for? Try different or more specific keywords.
      </Typography>
    </Box>
  );
}
