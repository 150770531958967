import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const NEWSGUARDBLUE = "#465DAA";

export default function NewsguardScore({ score = 100, size = "small" }) {
  const theme = useTheme();
  const [scoreColor, setScoreColor] = useState(NEWSGUARDBLUE);
  const [height, setHeight] = useState("1rem");
  const [fontSize, setFontSize] = useState("0.75rem");
  if (size !== "small" && size !== "large") {
    console.error(Error("Size prop only allows 'small' and 'large' values"));
  }
  // if (score >= 100 || score <= 1) {
  //   console.error(Error("Score must be between 1 and 100"));
  // }
  useEffect(() => {
    if (size === "large") {
      setHeight("2.5rem");
      setFontSize("1.6rem");
    } else {
      setHeight("1.4rem");
      setFontSize("1rem");
    }
  }, [size, setHeight, setFontSize]);

  useEffect(() => {
    if (score < 60) {
      setScoreColor(theme.palette.error.main);
    } else {
      setScoreColor(NEWSGUARDBLUE);
    }
  }, [score, setScoreColor, theme]);
  
  if (score == null || Number.isNaN(Number(score))) {
    return;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 251 302"
        style={{
          fill: scoreColor,
          height,
        }}
      >
        <path d="M186.3,40v130.2c0,17.4-8.67,36.55-24.4,53.94-13.03,14.4-30.12,26.72-48.27,35.02-21.34-9.98-39.27-23.35-52.39-39.15-13.9-16.75-21.24-34.88-21.24-52.44V40h146.3M226.3,0H0v167.57c0,54.49,43.5,108.54,113.15,134.76,58.22-19.63,113.15-73.24,113.15-132.12V0h0ZM251.93,176.36s-3.22,43.5-25.63,69.87c-34.71,45.26-67.67,56.1-67.67,56.1h93.3v-125.97Z" />
      </svg>
      <Box
        component="div"
        sx={{
          bgcolor: scoreColor,
          color: theme.palette.common.white,
          height,
          fontSize,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // position: "relative",
          // left: -1,
          pl: 1,
          pr: 1,
          fontWeight: "bold",
          borderRadius: "0 6px 6px 0",
        }}
      >
        {score}
      </Box>
    </Box>
  );
}
