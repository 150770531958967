import { marked } from "marked";
import { convertFromHTML, ContentState, EditorState } from "draft-js";

function markdownToHtml(markdown) {
  if(!markdown){
    return "";
  }
  return marked(markdown);
}

export function markdownToContentState(markdown) {
  const html = markdownToHtml(markdown);
  const contentState = ContentState.createFromBlockArray(
    convertFromHTML(html).contentBlocks
  );
  return EditorState.createWithContent(contentState);
}
