import React, { useEffect, useState } from "react";
import { Box, Link, Typography, SvgIcon, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { getFormattedDate, getRegularDate } from "utils/time.util";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";

import { ReactComponent as summarizerLogo } from "assets/icons/summarizer-icon.svg";
import { ReactComponent as citationLogo } from "assets/icons/citation-generator-icon.svg";
import {ReactComponent as readingLogo} from "assets/icons/reading-level-icon.svg";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import ColumnsSection from "./ColumnSection";
import { useSelector } from "react-redux";

export default function RecentDataGrid({ recentCollectionSource }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const defaultVisibilityModel = {
    createdDateTime_dtm: false,
    title_chr: true,
    collectionName_chr: true,
    sourceName_chr: true,
    sourcePublishedDateTime_dtm: true,
    score: false,
    mediaBiasRating: false,
    charityNavigatorSource: false,
    isSummaryAvailable_ysn: false,
    isCitationAvailable_ysn: true,
    isReadingLevelAvailable_ysn: false,
    notesCount_cnt: true,
  };

  const { user } = useSelector((state) => state.auth);

 const [visibilityModel, setVisibilityModel] = useLocalStorage(
   `recent-columnvisibility-${user.userId}`,
   defaultVisibilityModel
 );

 useEffect(() => {
   if (!visibilityModel || Object.keys(visibilityModel).length === 0) {
     setVisibilityModel(defaultVisibilityModel);
     writeStorage(
       `recent-columnvisibility-${user.userId}`,
       defaultVisibilityModel
     );
     apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
   } else {
     apiRef.current.setColumnVisibilityModel(visibilityModel);
   }
 }, []);

  const [columnWidths, setColumnWidths] = useLocalStorage(
    `recent-columnWidths-${user.userId}`,
    {}
  );

  useEffect(() => {
    const savedWidths = columnWidths;

    if (savedWidths) {
      Object.entries(savedWidths).forEach(([field, width]) => {
        apiRef.current.setColumnWidth(field, width);
      });
    }
  }, [apiRef, columnWidths]);


  const handleColumnResize = (params) => {
    const updatedWidths = {
      ...columnWidths,
      [params.colDef.field]: params.width,
    };
    setColumnWidths(updatedWidths); 
    writeStorage(`recent-columnWidths-${user.userId}`, updatedWidths); 
  };


  const handleSidebarOpen = (item, option) => {
    dispatch(setLevel(""));
    dispatch(setSelectedBookmarkOption(option));
    dispatch(setReadingLevelAvailable(item.isReadingLevelAvailable_ysn));
    dispatch(
      setSummaryInfoData({
        publisher: item.sourceName_chr,
        title: item.title_chr,
        published_date: item.sourcePublishedDateTime_dtm,
        url: item.sourceUrl_chr,
      })
    );
    dispatch(setCollectionSourceId(item.collectionSourceID_ids));
    dispatch(setCollectionId(item.collectionID_lng));
    dispatch(setIsCitationAvailable(item.isCitationAvailable_ysn));
    dispatch(setIsSummaryAvailable(item.isSummaryAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  const handleVisibilityChange = (newVisibilityModel) => {
    const updatedVisibility = {
      ...visibilityModel,
      ...newVisibilityModel, 
    };

    setVisibilityModel(updatedVisibility);
    writeStorage(`recent-columnvisibility-${user.userId}`, updatedVisibility);
  };

const columns = [
  {
    field: "createdDateTime_dtm",
    headerName: "Added",
    type: "date",
    flex: columnWidths["createdDateTime_dtm"] ? 0 : 1, // Flex if no saved width
    width: columnWidths["createdDateTime_dtm"], // Use saved width if available
    valueGetter: (value, row, column, apiRef) => {
      return value ? new Date(value) : null;
    },
    renderCell: (params) => {
      const date = params.value ? getRegularDate(params.value) : "-";

      return (
        <Typography
          noWrap
          variant="body2"
          color={theme.palette.text.secondary}
          onClick={() => handleSidebarOpen(params.row, "notes")}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {date ? date : "-"}
        </Typography>
      );
    },
  },
  {
    field: "title_chr",
    headerName: "Title",
    flex: columnWidths["title_chr"] ? 0 : 2,
    width: columnWidths["title_chr"],
    renderCell: (params) => {
      const { sourceUrl_chr } = params.row;
      const content = (
        <Typography variant="inherit" noWrap={true} sx={{ fontSize: "1.3rem" }}>
          {params.value ? params.value : "-"}
        </Typography>
      );

      return sourceUrl_chr ? (
        <Link
          href={sourceUrl_chr}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {content}
        </Link>
      ) : (
        content
      );
    },
  },
  {
    field: "collectionName_chr",
    headerName: "Collection",
    flex: columnWidths["sourceName_chr"] ? 0 : 1,
    width: columnWidths["sourceName_chr"],
    cellClassName: "ellipsisCell",
    renderCell: (params) => (
      <Typography
        fontSize={"1.3rem"}
        variant="inherit"
        noWrap
        fontWeight={"bold"}
        color={"#6c6c6c"}
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value ? params.value : "-"}
      </Typography>
    ),
  },
  {
    field: "sourceName_chr",
    headerName: "Source",
    flex: columnWidths["sourceName_chr"] ? 0 : 1,
    width: columnWidths["sourceName_chr"],
    cellClassName: "ellipsisCell",
    renderCell: (params) => (
      <Typography
        fontSize={"1.3rem"}
        variant="inherit"
        noWrap
        fontWeight={"bold"}
        color={"#6c6c6c"}
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value ? params.value : "-"}
      </Typography>
    ),
  },
  {
    field: "sourcePublishedDateTime_dtm",
    headerName: "Pub Date",
    flex: columnWidths["sourcePublishedDateTime_dtm"] ? 0 : 1,
    width: columnWidths["sourcePublishedDateTime_dtm"],
    cellClassName: "ellipsisCell",
    type: "date",
    valueGetter: (value, row, column, apiRef) => {
      return value ? new Date(value) : null;
    },
    renderCell: (params) => {
      const date = params.value ? getRegularDate(params.value) : "-";

      return (
        <Typography
          onClick={() => handleSidebarOpen(params.row, "notes")}
          variant="inherit"
          color={"#6c6c6c"}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {date ? date : "-"}
        </Typography>
      );
    },
  },
  {
    field: "score",
    headerName: "NewsGuard",
    flex: columnWidths["score"] ? 0 : 1,
    width: columnWidths["score"],
    renderCell: (params) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {params.value ? (
          <Box sx={{ mt: 2 }}>
            <NewsguardScore score={Number(params.value)} size="small" />
          </Box>
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    field: "mediaBiasRating",
    headerName: "AllSides",
    flex: columnWidths["mediaBiasRating"] ? 0 : 1,
    width: columnWidths["mediaBiasRating"],
    renderCell: (params) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {params.value ? (
          <Box sx={{ mt: 2 }}>
            <AllSidesRating activeCategory={params.value} single={true} />
          </Box>
        ) : (
          "-"
        )}
      </Box>
    ),
  },
  {
    field: "charityNavigatorSource",
    headerName: "Charity Navigator",
    flex: columnWidths["charityNavigatorSource"] ? 0 : 1,
    width: columnWidths["charityNavigatorSource"],
    renderCell: (params) => (
      <Typography
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value ? `${params.value}%` : "-"}
      </Typography>
    ),
  },
  {
    field: "isSummaryAvailable_ysn",
    headerName: "Summary",
    flex: columnWidths["isSummaryAvailable_ysn"] ? 0 : 1,
    width: columnWidths["isSummaryAvailable_ysn"],
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "summary");
        }}
      >
        <SvgIcon
          component={summarizerLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
  {
    field: "notesCount_cnt",
    headerName: "Notes",
    flex: columnWidths["notesCount_cnt"] ? 0 : 1,
    width: columnWidths["notesCount_cnt"],
    renderCell: (params) => (
      <Typography
        onClick={() => handleSidebarOpen(params.row, "notes")}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "isCitationAvailable_ysn",
    headerName: "Citation",
    flex: columnWidths["isCitationAvailable_ysn"] ? 0 : 1,
    width: columnWidths["isCitationAvailable_ysn"],
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "citation");
        }}
      >
        <SvgIcon
          component={citationLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
  {
    field: "isReadingLevelAvailable_ysn",
    headerName: "Leveling",
    flex: columnWidths["isReadingLevelAvailable_ysn"] ? 0 : 1,
    width: columnWidths["isReadingLevelAvailable_ysn"],
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "&:hover > svg": {
            fill: params.value
              ? theme.palette.primary.dark
              : theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[700],
          },
        }}
        onClick={() => {
          handleSidebarOpen(params.row, "readinglevel");
        }}
      >
        <SvgIcon
          component={readingLogo}
          inheritViewBox
          sx={{
            fill: params.value
              ? theme.palette.primary.main
              : theme.palette.mode === "dark"
              ? theme.palette.grey[800]
              : theme.palette.grey[200],
            height: "1.8rem",
          }}
        />
      </Box>
    ),
  },
];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ColumnsSection
        visibilityModel={visibilityModel}
        setVisibilityModel={setVisibilityModel}
        columns={columns}
        label={`recent-columnvisibility-${user.userId}`}
      />
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={recentCollectionSource}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          // margin: "0 auto",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        onColumnResize={handleColumnResize}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        getRowId={(row) => row.collectionSourceID_ids}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
