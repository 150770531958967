import React from "react";
import { Box } from "@mui/material";
import { scrollingBox, fillParent, footerFlex } from "utils/base-styles";
import Footer from "./Footer";

export default function SearchLayout({ children, sx }) {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
        ...sx,
      }}
    >
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}
