import { useEffect, useRef, useState } from "react";
import { fetchWikipediaData } from "../services/wikipedia.service";
import { useSelector } from "react-redux";
import { useSourceSearch } from "features/SourceSearch";

const IDPROP = "url";

const useInfiniteScrollWiki = (
  offset,
  setLoading,
  setcategoryData,
  setHasMore,
) => {
  const { currentSearchTerm } = useSourceSearch();
  const { user } = useSelector((state) => state.auth);

  const pageRef = useRef(0);

  const [error, setError] = useState("");

  const getData = async () => {
    setLoading(true);

    try {
      const data = await fetchWikipediaData(currentSearchTerm, user, pageRef.current * 21);
      const newWikiSources = data.data.webPages ? data.data.webPages.value : [];
      const totalRecords = data.data.webPages ? data.data.webPages.totalEstimatedMatches : 0;
      setcategoryData(newWikiSources, IDPROP);
      setHasMore(pageRef.current * 21 < totalRecords);
    } catch (error) {
      setError(error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setcategoryData([], IDPROP, true);
    pageRef.current = 0;
    getData();
    pageRef.current = 1;
  }, [currentSearchTerm]);

  useEffect(() => {
    if (pageRef.current) {
      if (offset !== 0) {
        getData();
        pageRef.current += 1;
      }
    }
  }, [offset]);

  return { error };
};

export default useInfiniteScrollWiki;
