import axios from "axios";
import { VimeoService } from "../services";
import { axiosInstance } from "lib/axios";

export function useVideos() {
  const vimeoService = new VimeoService(axiosInstance);

  async function getVimeoVideoData(videoId) {
    return await vimeoService.getVideoMetadata(videoId);
  }

  async function getVimeoUsersVideosMetadata(userId) {
    return await vimeoService.getVimeoUsersVideosMetadata(userId);
  }

  return {
    getVimeoVideoData,
    getVimeoUsersVideosMetadata,
  };
}
