import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import useSummarize from "features/Bookmarks/hooks/useSummarizer.hook";

import { convertFromHTML, ContentState, EditorState } from "draft-js";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Header from "./Header";
import SummaryBox from "./Box";
import CloseIcon from "@mui/icons-material/Close";

import { marked } from "marked";
import Summarizer from "features/Summarizer";

const Summarize = ({
  markdownSummary,
  setmarkdownSummary,
  summaryLoaded,
  setSummaryLoaded,
  selectedBookmarkOption,
  edit,
  setDisable,
  originalSummary,
  setOriginalSummary,
}) => {
  const [htmlSummary, sethtmlSummary] = useState(
    EditorState.createEmpty(markdownToContentState(markdownSummary))
  );

  const [editing, setEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);

  const {  isSummaryAvailable } = useSelector(
    (state) => state.data
  );

  const handleInputChange = (e, change) => {
    change(e.target.value);
  };

  const {
    handleCopy,
    changeTooltip,
    handleMouseOut,
    error,
    errorMessage,
    setError,
  } = useSummarize(
    htmlSummary,
    sethtmlSummary,
    markdownSummary,
    setmarkdownSummary,
    setLoading,
    summaryLoaded,
    setSummaryLoaded,
    selectedBookmarkOption,
    edit,
    setDisable,
    setOriginalSummary
  );

  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const tooltipTitle = changeTooltip
    ? "Copied to clipboard"
    : "Copy to clipboard";

  const saveSummary = (markdown) => {
    setmarkdownSummary(markdown);
    setIsFocused(false);
  };

  function markdownToHtml(markdown) {
    return marked(markdown);
  }

  function markdownToContentState(markdown) {
    const html = markdownToHtml(markdown);
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(html).contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }

  useEffect(() => {
    if (isSummaryAvailable) {
      if (markdownSummary === originalSummary) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
    sethtmlSummary(markdownToContentState(markdownSummary));
  }, [markdownSummary, edit]);

  useEffect(() => {
      if(markdownSummary.length > 20){
          setError(false)
      }
  }, [markdownSummary])

  if (selectedBookmarkOption !== "summary") {
    return <></>;
  }

  if (loading) {
    return (
      <Stack direction="column" gap={2} p={2} pt={4}>
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={50} />
      </Stack>
    );
  }
  return (
    <Box>
      {error ? (
        <Box
          sx={{
            p: 2,
            pt: 0,
          }}
        >
          <Header />
          <Summarizer
            errorMessage={errorMessage}
            markdownSummary={markdownSummary}
            setmarkdownSummary={setmarkdownSummary}
            setDisable={setDisable}
          />
        </Box>
      ) : (
        <Stack p={2} gap={2}>
          <Header />
          <Typography sx={{ mt: 2 }} variant="body2" color="grey">
            Generated Summary
          </Typography>

          <SummaryBox
            editing={editing}
            setEditing={setEditing}
            markdownSummary={markdownSummary}
            htmlSummary={htmlSummary}
            sethtmlSummary={sethtmlSummary}
            saveSummary={saveSummary}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
          />

          {!editing && (
            <Stack direction="row" gap={2}>
              <Button
                startIcon={
                  <EditOutlinedIcon
                  // sx={{ color: theme.palette.primary.main }}
                  />
                }
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  color: "black",
                  backgroundColor: navbarBackground,
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditing((edit) => !edit);
                }}
              >
                Edit
              </Button>

              <Tooltip title={tooltipTitle}>
                <Button
                  startIcon={
                    <ContentCopyIcon
                    // sx={{ color: theme.palette.primary.main }}
                    />
                  }
                  sx={{
                    mt: "2rem",
                    px: 2,
                    py: 1,
                    color: "black",
                    backgroundColor: navbarBackground,
                    textTransform: "none",
                  }}
                  onClick={handleCopy}
                  onMouseOut={handleMouseOut}
                >
                  Copy
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Summarize;
