import { Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import SideNavLink from "components/base/SideNavLink";
import { useUserData } from "features/User";

export default function GroupManagementSideMenu() {
  const { groupId, groupRole } = useUserData();
  return (
    groupId != null &&
    groupRole === "OrganizationAdmin" && (
      <Fragment>
        <Divider />
        <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
          Group Management
        </Typography>
        <SideNavLink to="/profile/group/user-management">
          User Management
        </SideNavLink>
        <SideNavLink to="/profile/group/group-controls">
          Group Controls
        </SideNavLink>
      </Fragment>
    )
  );
}
