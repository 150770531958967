import React from "react";
import {
  Typography,
  Button,
  IconButton,
  Box,
  Modal,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";

const DeletePopup = ({
  deletePopup,
  handleDeleteCollection,
  setDeletePopup,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const handleDelete = async () => {
    await handleDeleteCollection(params.id);
    navigate("/collections");
    setDeletePopup(false);
  };

  const onClose = () => {
    setDeletePopup(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "35vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal
      open={deletePopup}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
      }}
    >
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Confirm Deletion
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" mt={1}>
            Are you sure you want to delete this collection?
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              width: "45%",
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeletePopup;
