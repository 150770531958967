import React from "react";
import useMisinformationFingerprints from "../hooks/use-misinformation-fingerprints.hook";
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { htmlDecode } from "utils/html.util";
import MetaDataChips from "./MetaDataChips";

const MisinformationDetailsPage = () => {
  const { fingerprintDetails, loading } = useMisinformationFingerprints();

  if (loading) {
    return (
      <Box mt={2} px={2}>
        <Typography variant="h4" gutterBottom color={"primary.dark"}>
          Myth Details
        </Typography>
        <Paper variant="outlined" sx={{ padding: 3, marginBottom: 3 }}>
          <Typography variant="h6">Basic Information</Typography>
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
        </Paper>
        <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} />
        <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} />
        <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} />
      </Box>
    );
  }

  if (!fingerprintDetails) {
    return null;
  }

  const { writeUp, metaData, mythExampleLinks } = fingerprintDetails;


const maxArticlesToShow = 2;
const exampleLinks = mythExampleLinks.slice(0, maxArticlesToShow);

  const renderSection = (section) => {
    if (!section) return null;
    return (
      <Box key={section.title} mb={4}>
        <Typography variant="subtitle2">{section.title}</Typography>
        <Typography variant="body1">{section.body}</Typography>
      </Box>
    );
  };

  return (
    <Stack direction="column" mt={2} px={2}>
      {writeUp && writeUp.name && writeUp.description ? (
        <React.Fragment>
          <Typography color="primary.dark" alignItems="center" variant="h3">
            Myth: {writeUp.name.body}
          </Typography>
          <Typography>{htmlDecode(writeUp.description.body)}</Typography>
        </React.Fragment>
      ) : (
        <Typography color="primary.dark" alignItems="center" variant="h3">
          Myth Details
        </Typography>
      )}

      {writeUp && (
        <Paper
          variant="outlined"
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h4">Fingerprint Details</Typography>
          {Object.values(writeUp)
            .filter(
              (section) =>
                section != null &&
                section.title !== "Name" &&
                section.title !== "Description"
            )
            .map(renderSection)}
        </Paper>
      )}
      {metaData && exampleLinks && (
        <Paper variant="outlined" sx={{ p: 3 }}>
          <Typography variant="h4">Examples</Typography>
          {exampleLinks.map(({link},index) => (
            <ListItem
              dense
              disableGutters
              disablePadding
              sx={{ wordWrap: "break-word" }}
              key={index}
            >
              <ListItemText
                primary={
                  <Link href={link} target="_blank" rel="noopener noreferrer">
                    {link.length > 100 ? link.substring(0, 100) + "..." : link}
                  </Link>
                }
              />
            </ListItem>
          ))}
        </Paper>
      )}

      {metaData && metaData.reliableSources && (
        <Paper variant="outlined" sx={{ p: 3 }}>
          <Typography variant="h4">Reliable Sources</Typography>
          <List dense disablePadding>
            {metaData.reliableSources.body.map((item, index) => (
              <ListItem
                dense
                disableGutters
                disablePadding
                sx={{ wordWrap: "break-word" }}
                key={index}
              >
                <ListItemText
                  primary={
                    <Link href={item} target="_blank" rel="noopener noreferrer">
                      {item.length > 100
                        ? item.substring(0, 100) + "..."
                        : item}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Stack>
  );
};

export default MisinformationDetailsPage;
