import { Box } from "@mui/material";
import Footer from "components/layout/Footer";
import MisinformationDetailsPage from "features/Tools/components/MisinformationDetailsPage";
import React from "react";
import { fillParent, scrollingBox } from "utils/base-styles";

const MisinformationFingerprintsDetailsPage = () => {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
      }}
    >
      <MisinformationDetailsPage />
      <Footer />
    </Box>
  );
};

export default MisinformationFingerprintsDetailsPage;
