import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import newsGuardIcon from "assets/images/neutral-shield.png";
import shieldPlatformIcon from "assets/images/shield-platform.svg";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";

import { getBiasImage } from "utils/bias-images.util";
import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { logEvent } from "services/amplitude.service";

export const WikiCard = ({ data, cardRef, category, query }) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const ref = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const imageUrl = data?.openGraphImage?.contentUrl
    ? data?.openGraphImage?.contentUrl
    : defaultBackground;

  const openSidebar = () => {
    logEvent("open_bookmark_popup", { click: true, url: data.url });
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    setIsHovered(false);
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: data.url.includes("britannica")
          ? "Britannica"
          : "Merriam Webster",
        title: data.name,
        published_date: data.datePublished,
        url: data.url,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          cursor: "pointer",
          mt: "auto",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
          pl: 1,
          pr: 1,
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mt={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            sx={{
              width: 18,
              height: 18,
              cursor: "pointer",
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="more"
          onClick={openSidebar}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  const NotesSection = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            // height: { xs: "10vh", sm: "6vh" },
          }}
        >
          <Stack>
            <Typography
              variant="body1"
              sx={{
                color: { xs: "black", sm: "grey" },
                fontSize: { xs: "1.4rem" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: { xs: 3, sm: 4 },
                WebkitBoxOrient: "vertical",
                maxHeight: { xs: "6vh", sm: "8vh" },
                lineHeight: { xs: "2vh", sm: "2vh" },
                height: "8vh",
                width: "98%",
              }}
            >
              {data.datePublishedDisplayText &&
                `${data.datePublishedDisplayText} - `}
              {data.snippet}
            </Typography>
          </Stack>
        </Box>
      </>
    );
  };

  const handleTitleClick = () => {
    let newUrl = data.url;

    window.open(newUrl, "_blank");

    logEvent("click_resultset_title", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: data.url.toLowerCase().includes("merriam")
        ? "Merriam Webster"
        : "Britannica",
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
  };

  return (
    <React.Fragment>
      <Box
        ref={cardRef}
        sx={{
          display: "grid",
          gridTemplateAreas: `
        'titleSection imageSection'
        'notesSection imageSection'
        'divider divider'
      `,
          gridTemplateColumns: "4fr 1fr",
          gridTemplateRows: "auto 1fr auto",
          gap: 1,
          width: "100%",
          maxHeight: { xs: "unset", sm: "19vh" },
          boxShadow: "none",
        }}
      >
        {/* Title Section */}
        <Box
          sx={{
            gridArea: "titleSection",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="body4"
            onClick={handleTitleClick}
            sx={{
              fontWeight: "bold",
              color: "grey",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              maxHeight: "3vh",
              lineHeight: "3vh",
              height: "3vh",
              cursor: "pointer",
              "&:hover": {
                color: "rgb(18,89,151)",
              },
            }}
          >
            {data.name}
          </Typography>
        </Box>

        {/* Notes Section */}
        <Box sx={{ gridArea: "notesSection" }}>
          <NotesSection />
        </Box>

        {/* Image Section */}
        <Box
          sx={{
            gridArea: "imageSection",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            pr: 1,
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: "12vh", borderRadius: 1 }}
            image={imageUrl}
            alt="No Image Found"
          />
          <Box sx={{ mt: 1 }}>
            <BookmarkOptions />
          </Box>
        </Box>

        {/* Divider Section */}
        <Box sx={{ gridArea: "divider", mb: 2 }}>
          <Divider />
        </Box>
      </Box>
    </React.Fragment>
  );
};
