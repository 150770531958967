import { Box, useTheme } from "@mui/material";
import React from "react";

const SplashScreen = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.secondary.main,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Box
        component="img"
        width="200px"
        maxHeight="100%"
        src={theme.palette.mode === "dark" ? "/images/sooth_horizontal_on_dark.png" : "/images/sooth_horizontal_on_white.png"}
        alt="Sooth.fyi Logo"
      />
    </Box>
  );
};

export default SplashScreen;
