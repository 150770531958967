import { createSvgIcon } from "@mui/material";

export const UsGovernmentIcon = createSvgIcon(
  <svg
    id="us-government-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <polygon points="68.55258 66.00491 68.55258 50.29196 64.52328 50.29196 64.52328 66.00491 57.52701 66.00491 57.52701 50.29196 53.49771 50.29196 53.49771 66.00491 46.50143 66.00491 46.50143 50.29196 42.47214 50.29196 42.47214 66.00491 35.47586 66.00491 35.47586 50.29196 31.44656 50.29196 31.44656 66.00491 27.54166 66.00491 25.54166 73.0145 74.45834 73.0145 72.45834 66.00491 68.55258 66.00491" />
    <path d="M67.41769,42.68887c-.90771-8.80945-8.37219-15.70337-17.41766-15.70337s-16.51074,6.89392-17.41858,15.70337h-7.03979v4.0293h48.91669v-4.0293h-7.04065Zm-24.72406-5.49835c5.25317-5.2533,12.80511-6.25018,16.83417-2.22113,1.96112,1.96118,2.70728,4.75995,2.37305,7.71948h-22.88239c.78448-1.9397,2.01141-3.83466,3.67517-5.49835Z" />
  </svg>
);
