import React, { useCallback, useRef, useState } from "react";

const useLastElementRef = (scrollLoading, hasMore, setoffset) => {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (scrollLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setoffset((prevOffset) => prevOffset + 21);
        }
      });
      if (node) observer.current.observe(node);
    },
    [scrollLoading, hasMore]
  );

  return { lastElementRef };
};

export default useLastElementRef;
