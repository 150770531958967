import { toast } from "react-toastify";

export function toastError(message, { data, ...options } = {}) {
  const errorData =
    data != null
      ? {
          excludeFromNotifications: true,
          ...data,
        }
      : {
          excludeFromNotifications: true,
        };
  toast.error(message, {
    data: errorData,
    ...options,
  });
}
