import React from "react";
import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";


export default function Logo({isLandingPage, sx}) {
  const theme = useTheme()
  return (
    <Link to="/" style={{
      display: "block",
      ...sx
    }}>
      <Box
        component="img"
        src={isLandingPage || theme.palette.mode === "dark" ? "/images/sooth_horizontal_on_dark.png" : "/images/sooth_horizontal_on_white.png"}
        style={{
          width: isLandingPage ? 180 : 115,
          height: isLandingPage ? 45 : 30,
        }}
      />
    </Link>
  );
};