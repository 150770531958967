import { find } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
  academicSources,
  frequencies,
  sources,
} from "../data/search-fields.data";

const defaultSource = find(sources, { default: true });
const defaultTimeframe = find(frequencies, { default: true });

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    currentCategory: null,
    currentSearchTerm: "",
    currentSource: defaultSource.key,
    currentSubCategory: null,
    currentTimeframe: defaultTimeframe.key,
    endDate: null,
    isActive: false,
    sources: sources,
    sourceCategories: [],
    subCategories: academicSources,
    startDate: null,
    timeframes: frequencies,
  },
  reducers: {
    setCurrentCategory(state, action) {
      const { payload } = action;
      state.currentCategory = payload;
    },
    setCurrentSearchTerm(state, action) {
      const { payload } = action;
      state.currentSearchTerm = payload;
    },
    setCurrentSource(state, action) {
      const { payload } = action;
      state.currentSource = payload;
    },
    setCurrentSubCategory(state, action) {
      const { payload } = action;
      state.currentSubCategory = payload;
    },
    setCurrentTimeframe(state, action) {
      const { payload } = action;
      state.currentTimeframe = payload;
    },
    setIsActive(state, action) {
      const { payload } = action;
      state.isActive = payload;
    },
    setSources(state, action) {
      const { payload } = action;
      state.sources = payload;
    },
    setSourceCategories(state, action) {
      const { payload } = action;
      state.sourceCategories = payload;
    },
    setSubCategories(state, action) {
      const { payload } = action;
      state.subCategories = payload;
    },
    setStartDate(state, action) {
      const { payload } = action;
      state.startDate = payload;
    },
    setEndDate(state, action) {
      const { payload } = action;
      state.endDate = payload;
    },
    setTimeframes(state, action) {
      const { payload } = action;
      state.timeframes = payload;
    },
  },
});

export const {
  setCurrentCategory,
  setCurrentSearchTerm,
  setCurrentSource,
  setCurrentSubCategory,
  setCurrentTimeframe,
  setEndDate,
  setIsActive,
  setSources,
  setSourceCategories,
  setSubCategories,
  setStartDate,
  setTimeframes,
} = searchSlice.actions;

export default searchSlice.reducer;
