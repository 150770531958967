import React from "react";
import { Typography } from "@mui/material";
import SourceInfoBox from "./SourceInfoBox";
import { mediaBiasContent } from "data/mediaBiasContent";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import AllSidesLogo from "components/base/AllSidesLogo";

const MediaBiasRatingInfo = ({ data, sx={} }) => {
  return (
    <SourceInfoBox
      title="AllSides Media Bias Rating"
      header={<Typography fontSize="1.8rem">{data.mediaBiasRating}</Typography>}
      buttonText="Full Info"
      buttonLink={data.allsidesUrl}
      dynamicHeight={true}
      logo={<AllSidesLogo fontSize="2rem" />}
      sx={sx}
    >
      <AllSidesRating activeCategory={data.mediaBiasRating} size="large" />
      {data.mediaBiasRating && (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {mediaBiasContent(data.mediaBiasRating)}
        </Typography>
      )}
    </SourceInfoBox>
  );
};

export default MediaBiasRatingInfo;
