import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GeoDataService } from "../services";
import { axiosInstance } from "lib/axios";
import { setActiveRegions, setCountries } from "../context";
import { toastError } from "utils/toast.util";

export function useGeoData() {
  const dispatch = useDispatch();

  const geoService = new GeoDataService(axiosInstance);

  const { countries, activeRegions } = useSelector((state) => state.geo);

  async function getRegions(countryId) {
    return await geoService.getRegions(countryId);
  }

  async function updateActiveRegions(countryId) {
    try {
      const regions = await geoService.getRegions(countryId);
      dispatch(setActiveRegions(regions));
    } catch (error) {
      toastError("Could not update active regions.");
    }
  }

  useEffect(() => {
    async function fetchData() {
      const countries = await geoService.getCountries();
      dispatch(setCountries(countries));
    }
    if (countries.length < 1) {
      fetchData();
    }
  }, []);
  return {
    activeRegions,
    countries,
    getRegions,
    updateActiveRegions,
  };
}
