import React from "react";
import { useGetFeedCategories } from "features/Pulse/hooks/get-feed.hook";
import WhatsYourPulse from "features/Pulse/components/WhatsYourPulse";

const PulseCategoryPrefrence = () => {
  const { feedCategories } = useGetFeedCategories();

  return <WhatsYourPulse feedCategories={feedCategories} pageTitle={false} />;
};

export default PulseCategoryPrefrence;
