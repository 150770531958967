import amplitude from "amplitude-js";
import { amplitudeKey } from "config";

amplitude.getInstance().init(amplitudeKey);

function initAmplitude(userId) {
  amplitude.getInstance().setUserId(userId);
}

function logEvent(eventName, eventProperties) {
  amplitude.getInstance().logEvent(eventName, eventProperties);
}

export { initAmplitude, logEvent };
