import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const fetchWikipediaData = async (searchTerm, user, offset) => {
  const { data } = await axiosInstance.post(searchAPI.searchEncylopedia, {
    searchTerm: searchTerm,
    count: "21",
    user: user.userId,
    offset: String(offset),
  });

  return data;
};
