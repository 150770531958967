import { configureStore } from "@reduxjs/toolkit";
import authSlice from "context/features/authSlice";
import dataSlice from "context/features/dataSlice";
import stylesSlice from "context/features/stylesSlice";
import {
  profileReducer,
  accountReducer,
  subscriptionsReducer,
} from "features/User/context";
import searchSlice from "features/SourceSearch/context/searchSlice";
import { groupReducer } from "features/Group";
import { adminReducer } from "features/Admin";
import { geoDataReducer } from "features/GeoData";

export default configureStore({
  reducer: {
    account: accountReducer,
    admin: adminReducer,
    auth: authSlice,
    data: dataSlice,
    profile: profileReducer,
    search: searchSlice,
    styles: stylesSlice,
    subs: subscriptionsReducer,
    group: groupReducer,
    geo: geoDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: Number(process.env.REACT_APP_DEV_TOOLS),
});
