import React from "react";
import { Typography, Stack, Divider } from "@mui/material";
import SideNavLink from "components/base/SideNavLink";

export default function SideMenu() {
  return (
    <React.Fragment>
      <Stack direction="column" gap={1} sx={{ ml: 3, pt: 2 }}>
        <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
          Research Tools
        </Typography>
        <SideNavLink to="/tools/summarizer">Summarizer</SideNavLink>
        <SideNavLink to="/tools/reading-level">Reading Level</SideNavLink>
        {/* <SideNavLink to="/tools/citation-generator">
          Citation Generator
        </SideNavLink> */}
        <Divider />
        <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
          Misinformation Toolkit
        </Typography>
        <SideNavLink to="/tools/domain-checker">Domain Checker</SideNavLink>
        <SideNavLink end={false} to="/tools/misinformation-fingerprints">
          Misinformation Fingerprints
          <Typography fontSize="0.5em" component="sup">
            TM
          </Typography>
        </SideNavLink>
        <SideNavLink to="/tools/misinformation-search">
          Misinformation Search
        </SideNavLink>
      </Stack>
    </React.Fragment>
  );
}
