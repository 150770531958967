// External Libraries
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Internal Libraries & Modules
import { axiosInstance } from "lib/axios";

// imports from within Module
import { individualBasePricing } from "../data/subscriptions.data";
import { setCoupons, setProducts } from "../context";
import { SubscriptionService } from "../services/";

export function useSubscriptions() {
  const [subsLoading, setSubsloading] = useState(false);
  const dispatch = useDispatch();
  const subsService = useMemo(() => new SubscriptionService(axiosInstance), []);

  const { products, coupons } = useSelector((state) => state.subs);

  function getIndividualPlanBasePrice(gradeLevel, planLength) {
    if (
      individualBasePricing[gradeLevel] &&
      individualBasePricing[gradeLevel][planLength]
    ) {
      return individualBasePricing[gradeLevel][planLength];
    }
    return null;
  }

  async function createCheckoutSession(
    productId,
    couponId,
    mode = "subscription"
  ) {
    return await subsService.createCheckoutSession(productId, couponId, mode);
  }

  async function expireCheckoutSession(sessionId) {
    return await subsService.expireCheckoutSession(sessionId);
  }

  async function getCheckoutSessionStatus(sessionId) {
    return await subsService.getCheckoutSessionStatus(sessionId);
  }

  async function cancelSubscription(subId) {
    return await subsService.cancelSubscription(subId);
  }

  async function checkPromoCode(productId, promoCode) {
    return await subsService.checkPromoCode(productId, promoCode);
  }

  async function createPortalSession() {
    return await subsService.getPortalSession();
  }

  useEffect(() => {
    setSubsloading(true);
    async function fetchData() {
      const products = await subsService.getProducts();
      dispatch(setProducts(products));
      const coupons = await subsService.getDefaultCoupons();
      dispatch(setCoupons(coupons));
      setSubsloading(false);
    }
    fetchData();
  }, [subsService, dispatch]);

  return {
    cancelSubscription,
    checkPromoCode,
    coupons,
    createCheckoutSession,
    createPortalSession,
    expireCheckoutSession,
    getIndividualPlanBasePrice,
    getCheckoutSessionStatus,
    products,
    subsLoading,
  };
}
