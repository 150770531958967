import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Box,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useIsTab } from "hooks/is-tab.hook";

dayjs.extend(utc);

export default function Timeframe({
  timeframeValue,
  setTimeframeValue,
  startDateValue,
  setStartDateValue,
  endDateValue,
  setEndDateValue,
  frequencies,
  disabled = false,
  sx,
}) {
  const isTab = useIsTab();

  const [timeframe, setTimeframe] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(dayjs());

  function handleTimeframeChange(event) {
    console.log(event.target.value)
    setTimeframeValue(event.target.value);
  }
  function handleStartDateChange(date) {
    setStartDateValue(date.utc().format());
  }
  function handleEndDateChange(date) {
    setEndDateValue(date.utc().format());
  }

  useEffect(() => {
    setTimeframe(timeframeValue);
    if (timeframeValue === 3) {
      handleEndDateChange(endDate);
      handleStartDateChange(startDate);
    }
  }, [timeframeValue]);

  useEffect(() => {
    if (startDateValue != null) {
      setStartDate(dayjs(startDateValue));
    }
  }, [startDateValue]);

  useEffect(() => {
    if (endDateValue != null) {
      setEndDate(dayjs(endDateValue));
    }
  }, [endDateValue]);

  return (
    <Stack
      direction={isTab ? "row" : "column"}
      alignItems="stretch"
      mt={1}
      sx={sx}
      onKeyUp={(event) => event.stopPropagation()}
    >
      <Box>
        <FormControl fullWidth={!isTab} disabled={disabled}>
          <InputLabel id="timeframe-label" size="small">
            Timeframe
          </InputLabel>
          <Select
            size="small"
            variant="outlined"
            value={timeframe}
            onChange={handleTimeframeChange}
            label="Timeframe"
            id="timeframe"
            name="timeframe"
            labelId="timeframe-label"
            MenuProps={{ disablePortal: true }}
          >
            {frequencies.map((frequency) => {
              return (
                <MenuItem
                  key={`timeframe-key-${frequency.key}`}
                  value={frequency.key}
                >
                  {frequency.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Collapse in={timeframe === "custom-date"}>
        <Stack direction={isTab ? "rwo" : "column"} alignItems="stretch">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              onChange={handleStartDateChange}
              value={startDate}
              maxDate={endDate}
              disableFuture
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              onChange={handleEndDateChange}
              value={endDate}
              minDate={startDate}
              disableFuture
            />
          </LocalizationProvider>
        </Stack>
      </Collapse>
    </Stack>
  );
}
