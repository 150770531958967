import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Box,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { frequencies } from "../data/info-diet.data";

dayjs.extend(utc);

export default function InfoDietTimeframe({
  timeframeValue,
  setTimeframeValue,
  startDateValue,
  setStartDateValue,
  endDateValue,
  setEndDateValue,
  sx,
}) {
  const [timeframe, setTimeframe] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(dayjs());



  function handleTimeframeChange(event) {
    setTimeframeValue(event.target.value);
  }
  function handleStartDateChange(date) {
    setStartDateValue(date.utc().format());
  }
  function handleEndDateChange(date) {
    setEndDateValue(date.utc().format());
  }

  useEffect(() => {
    setTimeframe(timeframeValue);
    if(timeframeValue === 3) {
      handleEndDateChange(endDate);
      handleStartDateChange(startDate);
    }
  }, [timeframeValue]);

  useEffect(() => {
    if (startDateValue != null) {
      setStartDate(dayjs(startDateValue));
    }
  }, [startDateValue]);

  useEffect(() => {
    if (endDateValue != null) {
      setEndDate(dayjs(endDateValue));
    }
  }, [endDateValue]);

  return (
    <Stack direction="column" alignItems="flex-end" mt={1} sx={sx}>
      <Box>
        <FormControl>
          <InputLabel id="timeframe-label" size="small">
            Timeframe
          </InputLabel>
          <Select
            size="small"
            variant="outlined"
            value={timeframe}
            onChange={handleTimeframeChange}
            label="Timeframe"
            id="timeframe"
            labelId="timeframe-label"
          >
            {frequencies.map((frequency) => {
              return (
                <MenuItem
                  key={`timeframe-key-${frequency.value}`}
                  value={frequency.value}
                >
                  {frequency.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Collapse in={timeframe === 3}>
        <Stack direction="row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              onChange={handleStartDateChange}
              value={startDate}
              disableFuture
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              onChange={handleEndDateChange}
              value={endDate}
              disableFuture
            />
          </LocalizationProvider>
        </Stack>
      </Collapse>
    </Stack>
  );
}
