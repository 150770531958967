import React from "react";
import { Outlet } from "react-router-dom";
import MuiNavbar from "components/layout/MuiNavbar";
import LabelBottomNavigation from "components/layout/MuiNavbar/BottomBar";
import { Box } from "@mui/material";

export default function Layout() {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "1fr",
          height: { xs: "100%", sm: "100vh"},
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Box sx={{}}>
          <MuiNavbar />
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <LabelBottomNavigation />
    </React.Fragment>
  );
}
