import { axiosInstance } from "lib/axios";
import {
  searchAPI,
  trafficEngagementAPI,
  whoIsAPI,
} from "services/apis.service";

export const fetchPublisherData = async (sourceUrl) => {
  const response = await axiosInstance.post(
    searchAPI.domainPublisherInfo,
    {
      domain: sourceUrl,
    },
    { cache: false }
  );
  return response.data;
};

export const fetchTrafficEngagementData = async (sourceUrl) => {
  const response = await axiosInstance.post(
    trafficEngagementAPI.getTrafficEngagementAPI,
    {
      domain: sourceUrl,
    }
  );
  return response.data;
};

export const fetchWhoIsData = async (sourceUrl) => {
  const response = await axiosInstance.post(whoIsAPI.getWhoIsAPI, {
    domain: sourceUrl,
  });
  return response.data;
};
