import { CloseOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useGroupControls } from "../hooks";

export default function GroupCodeListItem({ groupCode, ...props }) {
  const { deleteGroupCode, getGroupCodes } =
    useGroupControls();

  async function handleDelete() {
    await deleteGroupCode(groupCode);
    await getGroupCodes();
    toast.success(`Group code '${groupCode.code}' as been deleted`);
  }
  return (
    <Stack
      component="li"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Typography
        component="div"
        variant="caption"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {groupCode.code}{" "}
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(groupCode.code);
            toast.info(
              `Code '${groupCode.code}' has been copied to your clipboard.`
            );
          }}
          size="small"
        >
          <ContentCopyOutlined fontSize="inherit" />
        </IconButton>
      </Typography>
      <IconButton
        size="small"
        onClick={handleDelete}
      >
        <CloseOutlined fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}
