export function boolean(value) {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else return "";
}

export function base64toBlob(data) {

  const bytes = window.atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
}
