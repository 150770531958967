import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const InputCheckbox = (props) => {
  const { label, value, onChange } = props;
  return (
    <FormControlLabel
      control={<Checkbox value={value} onChange={onChange} />}
      label={label}
    />
  );
};

export default InputCheckbox;
