import { createSvgIcon } from "@mui/material";

export const OtherSourcesIcon = createSvgIcon(
  <svg
    id="other-sources-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M30.12619,45.53491l3.98285,.01953c.94653,.01074,1.83405,.31641,2.49017,.97217,1.54913,1.54932,1.25159,4.36768-.664,6.2832-1.09485,1.09473-2.48126,1.64062-3.76068,1.63525l-2.04834,.02002v15.40869h15.08734l.02002-2.04828c-.00537-1.27942,.54053-2.66583,1.63525-3.76068,1.91553-1.91559,4.73389-2.21313,6.2832-.664,.65576,.65613,.96143,1.54364,.97217,2.49017l.01953,3.98279h15.7301v-11.88849c2.92236-1.47351,4.93616-4.49506,4.93591-7.98486,0-3.49005-2.01367-6.51184-4.93591-7.98553v-11.88867h-11.31378c-.51294-4.44879-4.29791-7.91626-8.88177-7.91626-4.58362-.00031-8.36823,3.46735-8.88104,7.91626h-10.67102v15.40869Zm7.00952-11.37946h8.07782l.02002-2.04834c-.00537-1.27942,.54053-2.66583,1.63525-3.76068,1.91553-1.91559,4.73389-2.21313,6.2832-.664,.65576,.65613,.96143,1.54364,.97217,2.49017l.01953,3.98285h8.72052v11.37946l3.98285,.01953c.94653,.01074,1.83405,.31641,2.49017,.97217,1.54913,1.54932,1.25159,4.36768-.664,6.2832-1.09485,1.09473-2.48126,1.64062-3.76068,1.63525l-2.04834,.02002v11.37939h-3.98285c-.513-4.44879-4.29797-7.91626-8.88177-7.9162-4.58362-.00037-8.36829,3.46735-8.8811,7.9162h-3.98279v-7.85919c2.92242-1.47351,4.93622-4.49506,4.93597-7.98486,0-3.49005-2.01373-6.51184-4.93597-7.98553v-7.85944Z" />
  </svg>
);
