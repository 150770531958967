import { createSvgIcon } from "@mui/material";

export const InterGovernmentalIcon = createSvgIcon(
  <svg
    id="inter-governmental-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M50.00003,24.59225l-2.57281,1.53479-19.13037,11.41205v25.07111l14.61951,8.68036h0l6.93396,4.11719,8.19482-4.79919,13.65802-7.99835v-25.07092l-21.70312-12.94702Zm-.00006,23.14313l-12.72443-7.34644,15.6488-9.33514,12.77209,7.6192-15.69647,9.06238Zm-17.67383,12.53363v-16.92749l.98602-.58826,14.67322,8.47156v17.09723l-1.05536,.61804-14.60388-8.67108Zm19.68848,5.69348v-14.73761l15.65924-9.04089v14.60828l-15.65924,9.17023Z" />
  </svg>
);
