import React, { Fragment } from "react";
import { Typography, Grid, Box } from "@mui/material";
import SourceInfoBox from "features/SourceInfo/components/SourceInfoBox";
import SimilarWebLogo from "components/base/SimilarWebLogo";

export default function WebsiteAnalyticsInfo({ data, sx = {} }) {
  return (
    <SourceInfoBox
      title="Website Analytics"
      logo={<SimilarWebLogo fontSize="2rem" />}
      sx={sx}
    >
      <Typography variant="h5">Total Visits</Typography>
      <Typography gutterBottom fontSize="1.8rem">
        {data.totalVisits} / {data.totalVisitsPeriod} days
      </Typography>
      <Typography variant="h5">Ranking by Traffic</Typography>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={8} md={8} sx={{ mt: 1 }}>
          <Typography variant="body2">Global rank</Typography>
        </Grid>
        <Grid item xs={4} md={4} sx={{ mt: 1 }}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2">
              #{data.globalRank == null ? "unranked" : data.globalRank}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} md={8} sx={{ mt: 1 }}>
          <Typography variant="body2">Country rank (US)</Typography>
        </Grid>
        <Grid item xs={4} md={4} sx={{ mt: 1 }}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2">
              #{data.countryRank == null ? "unranked" : data.countryRank}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} md={8} sx={{ mt: 1 }}>
          <Typography variant="body2">
            Industry rank ({data.industry})
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} sx={{ mt: 1 }}>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2">
              #{data.industryRank == null ? "unranked" : data.industryRank}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </SourceInfoBox>
  );
}
