import React, { useEffect } from "react";
import { Box, Typography, Stack, Divider } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import PageTitle from "components/layout/PageTitle";
import ThemePrefrence from "features/Settings/components/ThemePrefrence";
import CategoryIndexing from "features/Settings/components/CategoryIndexing";
import PulseCategoryPrefrence from "features/Settings/components/PulseCategoryPrefrence";
import Footer from "components/layout/Footer";
import { useLocation, useParams } from "react-router-dom";

const PrefrencesPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const handleClickScroll = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (id) {
      handleClickScroll();
    }
  }, [id]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
        mb: 5,
      }}
    >
      <Stack>
        <PageTitle sx={{ wordBreak: "break-word" }}>Preferences</PageTitle>
        <Typography variant="body2">
          Welcome to the preferences page. Here, you will find all the options
          available to make life on Sooth easier. As your needs change, come
          back here and update things to better suit your research activities.
          As Sooth grows, we will expand the options available to make
          researching smoother for you.
        </Typography>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h4">Color Scheme</Typography>
        <Typography variant="body2">
          The color scheme allows you to set Sooth's interface to light or dark
          mode. You can also tell Sooth to match your system settings as well.
        </Typography>
        <ThemePrefrence />
        <Divider sx={{ my: 4 }} />

        <Typography variant="h4">Search Categories</Typography>
        <Typography variant="body2">
          Sooth's search categories are customizable to make researching easier.
          Change the order to keep more important categories near the top. Hide
          categories that are not of interest to you. You can always access all
          the categories in the search box at the top of the page, so you can
          always take advantage of essential research sources.
        </Typography>
        <CategoryIndexing />
        <Divider sx={{ my: 4 }} />

        <Typography id="pulse-category" variant="h4">
          Pulse Topics
        </Typography>
        <PulseCategoryPrefrence />
        <Divider
          sx={{
            my: 3,
          }}
        />
      </Stack>
      <Footer />
    </Box>
  );
};

export default PrefrencesPage;
