import React from "react";
import { Typography } from "@mui/material";
import SourceInfoBox from "./SourceInfoBox";
import three_star from "assets/images/three_star.svg";
import four_star from "assets/images/four_star.svg";
import one_star from "assets/images/one_star.svg";
import zero_star from "assets/images/zero_star.svg";
import two_star from "assets/images/two_star.svg";
import CharityNavigatorLogo from "components/base/CharityNavigatorLogo";

export default function CharityNavigatorInfo({ data, sx = {} }) {
  const charity_rating = () => {
    if (data.charityNavigatorScore > 90 || data.charityNavigatorRating === 4) {
      return four_star;
    } else if (
      data.charityNavigatorScore >= 75 ||
      data.charityNavigatorRating === 3
    ) {
      return three_star;
    } else if (
      data.charityNavigatorScore >= 60 ||
      data.charityNavigatorRating === 2
    ) {
      return two_star;
    } else if (
      data.charityNavigatorScore >= 50 ||
      data.charityNavigatorRating === 1
    ) {
      return one_star;
    }
    return zero_star;
  };

  const charity_text = () => {
    if (data.charityNavigatorScore >= 90) {
      return "four-star";
    } else if (data.charityNavigatorScore >= 75) {
      return "three-star";
    } else if (data.charityNavigatorScore >= 60) {
      return "two-star";
    } else if (data.charityNavigatorScore >= 50) {
      return "one-star";
    }
    return "zero-star";
  };

  return (
    <SourceInfoBox
      title="Charity Navigator"
      header={
        data.charityNavigatorScore ? (
          <Typography fontSize="1.8rem">
            {data.charityNavigatorScore}%
          </Typography>
        ) : null
      }
      dynamicHeight={true}
      buttonText="Full Review"
      buttonLink={data.charityNavigatorEIN}
      logo={<CharityNavigatorLogo fontSize="2rem" />}
      sx={sx}
    >
      {data.charityNavigatorRating ? (
        <img
          style={{
            width: "10rem",
            height: "2rem",
            marginLeft: -2,
          }}
          src={charity_rating()}
          alt="Charity rating"
        />
      ) : null}

      <Typography variant="body2" sx={{ mt: 1 }}>
        {data.charityNavigatorRating &&
          `The Charity Score is ${
            data.charityNavigatorScore
          }%, earning it a ${charity_text()} rating.`}
      </Typography>
    </SourceInfoBox>
  );
}
