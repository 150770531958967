import { Check } from "@mui/icons-material";
import { Chip, Stack, Typography } from "@mui/material";
import { useCallback } from "react";

export default function PillFilter({
  title,
  filters,
  currentFilter,
  handleChange,
  required = false,
  keyPrefix = "",
  disabled = false,
  sx,
  ...otherProps
}) {
  const isSelected = useCallback(
    (key) => {
      return key === currentFilter;
    },
    [currentFilter, keyPrefix]
  );

  function handleSelect(key) {
    if (key !== currentFilter) {
      handleChange(key);
    } else if (!required) {
      handleChange(null);
    }
  }
  return (
    <Stack
      direction="column"
      sx={{
        p: 2,
        ...sx,
      }}
      {...otherProps}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={1}
        sx={{
          "&:after": {
            content: '""',
            flex: "auto",
          },
        }}
      >
        {filters.map((item) => (
          <Chip
            key={`${keyPrefix}-${item.key}`}
            label={item.label}
            onClick={() => handleSelect(item.key)}
            icon={isSelected(item.key) ? <Check /> : null}
            variant={isSelected(item.key) ? "filter" : "outlined"}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
}
