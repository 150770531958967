import { searchAPI } from "services/apis.service";

export function InfoDietService(axisoInstance) {
  const axios = axisoInstance;

  async function getUserAnalytics(frequencyFilter, metricFilter) {
    try {
      const response = await axios.post(
        searchAPI.userAnalytics,
        {
          ...frequencyFilter,
          metricFilter,
        },
        {
          cache: false,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async function getInfoDietData(frequencyFilter, metricFilter) {
    try {
      const response = await axios.post(
        searchAPI.getInfoDietData,
        {
          ...frequencyFilter,
          metricFilter,
        },
        {
          cache: false,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return {
    getUserAnalytics,
    getInfoDietData,
  };
}
