const { axiosInstance } = require("lib/axios");
const { searchAPI } = require("services/apis.service");

export const getMisinformationDetailsPage = async (soothId, userId) => {
  const { data } = await axiosInstance.post(searchAPI.fingerprintDetails, {
    userId: userId,
    soothId: soothId,
  });

  if(data.errors != null && Array.isArray(data.errors) && data.errors.length > 0) {
    throw new Error(data.errors[0].message);
  }

  return data;
};
