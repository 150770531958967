import React, { useEffect } from "react";
import BookmarkDetails from "./components/BookmarkDetails";
import { useSelector } from "react-redux";
import useDocumentTypes from "hooks/use-document-types.hook";
import RightDrawer from "components/base/RightDrawer";

export default function BookmarkDrawer({
  isSidebarOpen,
  sidebarClosedHandler,
}) {
  const { user } = useSelector((state) => state.auth);
  useDocumentTypes(user.userId);

  return (
    <RightDrawer open={isSidebarOpen} onClose={sidebarClosedHandler}>
      <BookmarkDetails toggleSidebar={sidebarClosedHandler} />
    </RightDrawer>
  );
}
