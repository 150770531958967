import { reduce } from "lodash";
import { useMemo } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { useUserData } from "features/User";

import { useGroup } from "../../hooks";
import { getFormattedDate } from "utils/time.util";
import { NumericFormat } from "react-number-format";
import { parsePhoneNumber } from "libphonenumber-js";

export function GroupSubscriptionDetails({ sx }) {
  const { palette } = useTheme();
  const { isSubscriptionLoading } = useUserData();
  const { group, isGroupLoading } = useGroup();

  function rowColor(seats, usedSeats, availableSeats) {
    if (availableSeats < 0 && (usedSeats / seats) * 100 > 110) {
      return palette.error.main;
    } else if (availableSeats < 0) {
      return palette.warning.main;
    }
    return palette.text.primary;
  }

  const totalSeats = useMemo(() => {
    if (group != null && Array.isArray(group.licenseLevels)) {
      return reduce(group.licenseLevels, (sum, level) => sum + level.seats, 0);
    }
    return 0;
  }, [group]);

  const totalUsedSeats = useMemo(() => {
    if (group != null && Array.isArray(group.licenseLevels)) {
      return reduce(
        group.licenseLevels,
        (sum, level) => sum + level.usedSeats,
        0
      );
    }
    return 0;
  }, [group]);

  if (isSubscriptionLoading || isGroupLoading || group == null) {
    return null;
  }

  return (
    <Stack direction="row" sx={{ my: 3, ...sx }}>
      <Stack direction="column" sx={{ flex: 1 }}>
        <Typography component="div" variant="caption">
          <Typography component="span" variant="caption" fontWeight="bold">
            Group Name:
          </Typography>{" "}
          {group.name}
        </Typography>
        <Typography component="div" variant="caption">
          <Typography component="span" variant="caption" fontWeight="bold">
            Created:
          </Typography>{" "}
          {getFormattedDate(group.created)}
        </Typography>
        {group.contact != null && (
          <Stack direction="column" gap={0}>
            <Typography component="div" variant="caption" fontWeight="bold">
              Group Contact:
            </Typography>
            <Typography component="div" variant="caption">
              {group.contact.name}
            </Typography>
            <Typography component="div" variant="caption">
              {parsePhoneNumber(group.contact.phone, "US").formatNational()}
            </Typography>
            <Typography component="div" variant="caption">
              {group.contact.email}
            </Typography>
            <Stack direction="row" gap={0}>
              <Typography component="div" variant="caption">
                {group.contact.city}
              </Typography>
              {group.contact.state != null && group.contact.state !== "" && (
                <Typography component="div" variant="caption">
                  , {group.contact.state}
                </Typography>
              )}
              {group.contact.country != null &&
                group.contact.country !== "" && (
                  <Typography component="div" variant="caption">
                    , {group.contact.country}
                  </Typography>
                )}
            </Stack>
          </Stack>
        )}
      </Stack>
      {Array.isArray(group.licenseLevels) && (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            maxWidth: "50vw",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>License</TableCell>
                <TableCell align="right">Seats</TableCell>
                <TableCell align="right">Used</TableCell>
                <TableCell align="right">Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.licenseLevels.map((level) => (
                <TableRow key={level.key}>
                  <TableCell
                    sx={{
                      color:
                        level.availableSeats < 0
                          ? palette.warning.main
                          : palette.text.primary,
                    }}
                  >
                    {level.label}
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={level.seats}
                      defaultValue="-"
                      displayType="text"
                      thousandSeparator=","
                      thousandsGroupStyle="thousand"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      value={level.usedSeats}
                      defaultValue="-"
                      displayType="text"
                      thousandSeparator=","
                      thousandsGroupStyle="thousand"
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        level.availableSeats < 0
                          ? palette.warning.main
                          : palette.text.primary,
                    }}
                  >
                    <NumericFormat
                      value={level.availableSeats}
                      defaultValue="-"
                      displayType="text"
                      thousandSeparator=","
                      thousandsGroupStyle="thousand"
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{
                    color: rowColor(
                      totalSeats,
                      totalUsedSeats,
                      totalSeats - totalUsedSeats
                    ),
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: rowColor(
                      totalSeats,
                      totalUsedSeats,
                      totalSeats - totalUsedSeats
                    ),
                  }}
                >
                  {totalSeats}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: rowColor(
                      totalSeats,
                      totalUsedSeats,
                      totalSeats - totalUsedSeats
                    ),
                  }}
                >
                  {totalUsedSeats}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: rowColor(
                      totalSeats,
                      totalUsedSeats,
                      totalSeats - totalUsedSeats
                    ),
                  }}
                >
                  {totalSeats - totalUsedSeats}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
}
