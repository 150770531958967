import React from "react";
import { AvatarGroup, useTheme } from "@mui/material";
import ProfileAvatar from "components/base/ProfileAvatar";

const GroupAvatar = ({ userIds }) => {
  const theme = useTheme();
  if (!Array.isArray(userIds)) {
    return null;
  }

  return (
    <AvatarGroup
      max={3}
      sx={{
        "& .MuiAvatar-root": {
          width: "2.5rem",
          height: "2.5rem",
          color: theme.palette.text.primary,
          ...theme.typography.caption,
        },
      }}
    >
      {userIds.map((userId) => (
        <ProfileAvatar key={userId} userId={userId} />
      ))}
    </AvatarGroup>
  );
};

export default GroupAvatar;
