import { Fragment } from "react";
import { Box, Grid, Paper, Typography, alpha } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Stack, useTheme } from "@mui/system";
import { red } from "@mui/material/colors";

const NewsguardSummaryInfoBox = ({ score }) => {
  const { palette } = useTheme();
  const highCrediblity = (
    <Fragment>
      <Typography variant="h5">High Credibility</Typography>
      <Typography fontSize="1.8rem" fontWeight="bold">
        {score}%
      </Typography>
    </Fragment>
  );

  const lowCredibility = (
    <Grid container spacing={2} display={"flex"} flexGrow={1} width="100%">
      <Grid item xs={6} sm={6} md={4} alignContent="center">
        <WarningIcon
          sx={{ color: palette.error.main }}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={8}>
        <Stack direction="column">
          <Typography
            variant="h5"
            color={palette.error.main}
          >
            Low Credibility
          </Typography>
          <Typography
            fontSize="1.8rem"
            fontWeight="bold"
            color={palette.error.main}
          >
            {score}%
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );

  return score ? (
    <Paper
      sx={{
        display: "flex",
        p: 3,
        height: "100%",
        boxShadow: 0,
        backgroundColor:
          score < 60 ? alpha(palette.warning.light, 0.25) : palette.secondary.main,
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "100%",
        }}
      >
        {score < 60 ? lowCredibility : highCrediblity}
      </Box>
    </Paper>
  ) : (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        justifyContent: "center",
        height: "100%",
        boxShadow: 0,
        backgroundColor: alpha(palette.warning.light, 0.25),
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: palette.getContrastText(palette.warning.light),
          }}
        >
          Credibility score unavailable
        </Typography>
      </Box>
    </Paper>
  );
};

export default NewsguardSummaryInfoBox;
