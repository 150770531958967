import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  ArrowBackOutlined,
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
  ShieldOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useGroupControls } from "../hooks";

export default function AutoJoinDomain({
  groupDomain,
  setVerifyModal,
  isNewItem,
  closeNew,
  sx,
  ...otherProps
}) {
  const { palette } = useTheme();

  const {
    createUpdateDomain,
    getGroupDomains,
    deleteDomain,
    isGroupControlsLoading,
  } = useGroupControls();

  const [editMode, setEditMode] = useState(isNewItem);
  const [editDomain, setEditDomain] = useState(groupDomain.domain);

  const inputRef = useRef(null);

  const saveDisabled = useMemo(
    () =>
      editDomain === "" ||
      editDomain.trim().toLowerCase() ===
        groupDomain.domain.trim().toLowerCase(),
    [editDomain, groupDomain]
  );

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  async function handleSubmit() {
    const success = await createUpdateDomain({
      id: groupDomain.id,
      domain: editDomain,
    });
    if (success) {
      await getGroupDomains();
      if (isNewItem) {
        closeNew();
      } else {
        setEditMode(false);
      }
      toast.success(
        `Auto-join domain saved: ${groupDomain.domain} => ${editDomain}`
      );
    }
  }

  async function onDeleteClick() {
    await deleteDomain(groupDomain);
    await getGroupDomains();
    toast.success(`Auto-join domain '${groupDomain.domain}' as been deleted`);
  }

  function handleEditMode() {
    setEditMode(true);
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 200);
  }

  useEffect(() => {
    setEditDomain(groupDomain.domain);
  }, [groupDomain]);

  useEffect(() => {
    if (isNewItem) {
      handleEditMode();
    }
  }, [isNewItem]);

  return (
    <Stack
      component="li"
      direction="row"
      alignItems="center"
      {...otherProps}
      sx={{
        minHeight: "46px",
        ...sx,
      }}
    >
      {editMode ? (
        <Fragment>
          {isNewItem ? (
            <IconButton
              size="small"
              onClick={() => closeNew()}
              disabled={isGroupControlsLoading}
              sx={{ width: "25px" }}
            >
              <Tooltip title="Cancel Add New" placement="left">
                <CloseOutlined fontSize="inherit" />
              </Tooltip>
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => setEditMode(false)}
              disabled={isGroupControlsLoading}
              sx={{ width: "25px" }}
            >
              <Tooltip title="Cancel Edit" placement="left">
                <ArrowBackOutlined fontSize="inherit" />
              </Tooltip>
            </IconButton>
          )}
          <TextField
            fullWidth
            value={editDomain}
            onChange={({ target }) => setEditDomain(target.value)}
            onKeyUp={handleKeyPress}
            label="Domain"
            size="small"
            inputRef={inputRef}
            sx={{ flex: 11 }}
            placeholder="abcshool.org"
          />
          <IconButton
            size="small"
            onClick={handleSubmit}
            disabled={saveDisabled || isGroupControlsLoading}
            sx={{ width: "25px" }}
          >
            <Tooltip title="Save Domain" placement="right">
              <SaveOutlined fontSize="inherit" />
            </Tooltip>
          </IconButton>
        </Fragment>
      ) : (
        <Fragment>
          <IconButton
            size="small"
            onClick={handleEditMode}
            sx={{ width: "25px" }}
            disabled={isGroupControlsLoading}
          >
            <Tooltip title="Edit Domain" placement="left">
              <EditOutlined fontSize="inherit" />
            </Tooltip>
          </IconButton>
          <TextField
            variant="outlined"
            fullWidth
            value={groupDomain.domain}
            disabled
            sx={{
              flex: groupDomain.verified ? 9 : 7,
              "& .Mui-disabled": {
                color: palette.text.primary,
                WebkitTextFillColor: palette.text.primary,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent !important",
              },
            }}
          />
          {groupDomain.verified ? (
            <Chip
              variant="outlined"
              icon={<VerifiedUserOutlined />}
              label="Verified"
              color="success"
              size="small"
              sx={{
                flex: 2,
                "& > .MuiChip-icon": {
                  flex: 4,
                },
                "& > .MuiChip-label": {
                  flex: 8,
                },
              }}
            />
          ) : (
            <Fragment>
              <Box sx={{ flex: 2 }}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setVerifyModal(groupDomain)}
                >
                  How to verify?
                </Button>
              </Box>
              <Chip
                variant="outlined"
                icon={<ShieldOutlined />}
                label="Unverified"
                color="warning"
                size="small"
                sx={{
                  flex: 2,
                  "& > .MuiChip-icon": {
                    flex: 4,
                  },
                  "& > .MuiChip-label": {
                    flex: 8,
                  },
                }}
              />
            </Fragment>
          )}
          <IconButton
            size="small"
            onClick={onDeleteClick}
            sx={{ width: "25px" }}
            disabled={isGroupControlsLoading}
          >
            <Tooltip title="Delete Domain" placement="right">
              <CloseOutlined fontSize="inherit" />
            </Tooltip>
          </IconButton>
        </Fragment>
      )}
    </Stack>
  );
}
