import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getBreakoutTopic, getFeedCategories } from "../services/news-feed.services";

export const useGetFeedCategories = () => {
  const { user } = useSelector((state) => state.auth);
  const [feedCategories, setFeedCategories] = useState([]);
  const [breakCategories, setBreakOutCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const categories = await getFeedCategories(user.userId);
      setFeedCategories(categories);
    } catch (error) {
      console.error("Failed to fetch feed categories:", error);
    }
  };

  const fetchBreakoutTopics = async () => {
  try{
    const categories = await getBreakoutTopic(user.userId);
    setBreakOutCategories(categories);
  } catch(error){
    console.error("Failed to breakout topic list", error);
  }   
  }

  useEffect(() => {
    fetchCategories();
    fetchBreakoutTopics();
  }, []);

  return { feedCategories, breakCategories };
};
