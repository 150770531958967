import React, { useState } from "react";
import { Box } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import Summarizer from "features/Summarizer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsBookmarkSidebar } from "context/features/dataSlice";
import ExternalBookmarkDrawer from "features/ExternalBookmark";
import Footer from "components/layout/Footer";
import ReadingLevelComponent from "features/ReadingLevelComponent";
import { markdownToContentState } from "features/Bookmarks/utils/htmltomarkdown";
import { EditorState } from "draft-js";

export default function ReadingLevel() {
  const { isBookmarkSidebar } = useSelector((state) => state.data);
  const [markdownSummary, setmarkdownSummary] = useState("");
  const [rawText, setRawText] = useState("");
  const [htmlSummary, sethtmlSummary] = useState(
    EditorState.createEmpty(markdownToContentState(markdownSummary))
  );

  const dispatch = useDispatch();

  const externalBookmarkCloseHandler = () => {
    dispatch(setIsBookmarkSidebar(false));
  };

  const showExternalBookmarkSidebar = () => {
    dispatch(isBookmarkSidebar(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <Box sx={{ ...fillParent, ...scrollingBox, ...footerFlex }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <ReadingLevelComponent rawText={rawText} setRawText={setRawText} htmlSummary={htmlSummary} sethtmlSummary={sethtmlSummary} markdownSummary={markdownSummary} setmarkdownSummary={setmarkdownSummary} />
      </Box>
      <Footer />
      <ExternalBookmarkDrawer
        isBookmarkSidebar={isBookmarkSidebar}
        externalBookmarkCloseHandler={externalBookmarkCloseHandler}
        showExternalBookmarkSidebar={showExternalBookmarkSidebar}
      />
    </Box>
  );
}
