import React, { Fragment } from "react";
import { Box, Skeleton, Grid, Stack, Typography } from "@mui/material";
import NewsguardInfoBox from "features/SourceInfo/components/NewsGuardInfoBox";
import MediaBiasRatingInfo from "features/SourceInfo/components/MediaBiasRatingInfo";
import CharityNavigatorInfo from "features/SourceInfo/components/CharityNavigatorInfo";
import WebsiteAnalyticsInfo from "./WebsiteAnalytics";
import WhoIsInfo from "./WhoisInfo";
import SummaryInfo from "./SummaryInfo";
import useDomainChecker from "features/MisinformationToolkit/hooks/use-domian-checker.hook";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

export default function DomainCheckerInfo({ domainName }) {
  const isMobile = useIsMobile();
  const { data, trafficData, whoisData, isLoading } =
    useDomainChecker(domainName);

  return !isLoading ? (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridAutoRows: "minmax(5vh, auto)",
        gap: 2,
      }}
    >
      {trafficData && trafficData.description && (
        <Typography
          sx={{
            gridColumnEnd: "span 12",
          }}
        >
          {trafficData.description}
        </Typography>
      )}
      <SummaryInfo
        data={data}
        trafficData={trafficData}
        whoIsData={whoisData}
        sx={{
          gridColumnEnd: "span 12",
        }}
      />
      {data && data.score && (
        <NewsguardInfoBox
          data={data}
          sx={{
            gridColumnEnd: isMobile ? "span 12" : "span 6",
          }}
        />
      )}
      {data && data.mediaBiasRating && (
        <MediaBiasRatingInfo
          data={data}
          sx={{
            gridColumnEnd: isMobile ? "span 12" : "span 6",
          }}
        />
      )}
      {data && (data.charityNavigatorScore || data.charityNavigatorRating) && (
        <CharityNavigatorInfo
          data={data}
          sx={{
            gridColumnEnd: isMobile ? "span 12" : "span 4",
          }}
        />
      )}
      {trafficData && trafficData.domain && (
        <WebsiteAnalyticsInfo
          data={trafficData}
          sx={{
            gridColumnEnd: isMobile ? "span 12" : "span 4",
          }}
        />
      )}
      {whoisData && whoisData.domain && (
        <WhoIsInfo
          data={whoisData}
          sx={{
            gridColumnEnd: isMobile ? "span 12" : "span 4",
          }}
        />
      )}
    </Box>
  ) : (
    <Stack direction="column" gap={2}>
      <Skeleton variant="rectangular" height={150} />
      <Skeleton variant="rectangular" height={150} />
    </Stack>
  );
}
