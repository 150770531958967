import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { Avatar, Modal } from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import { deepOrange } from "@mui/material/colors";
import UserIcon from "../../../../assets/icons/icon_user.svg";
import { notificationData } from "data/notification-data";
import ProfileAvatar from "components/base/ProfileAvatar";
import { useSelector } from "react-redux";

const ProfilePopupMobile = ({ openProfileModal, handleCloseModal }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Modal
      open={openProfileModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "40%",
          position: "absolute",
          bottom: 50,
          bgcolor: "background.paper",
          border: "none",
          borderTopLeftRadius: "3rem",
          borderTopRightRadius: "3rem",
          boxShadow: 0,
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ProfileAvatar userId={user.userId} />
            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                @janedoe
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.2rem",
                }}
              >
                <FiArrowUpRight color="#5686b3" fontWeight={"bold"} />
                <Typography
                  variant="body2"
                  sx={{ color: "#5686b3", fontWeight: "bold" }}
                >
                  Your Profile
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#e7edf2",
              color: "#5686b3",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(231, 237, 242, 0.8)",
              },
            }}
          >
            Account
          </Button>
        </Box>
        <Divider></Divider>

        <Box sx={{ p: 2 }}>
          <Typography
            sx={{
              marginBottom: 2,
              fontStyle: "italic",
              fontSize: "1.5rem",
              color: "#707070",
            }}
          >
            Notifications
          </Typography>

          <Box>
            {notificationData.map((notification) => (
              <Box
                key={notification.data}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: 2,
                }}
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  style={{ width: 30, height: 30, marginRight: 10 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            marginRight: 1,
                            marginBottom: 0,
                            fontWeight: "bold",
                          }}
                        >
                          {notification.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "400" }}
                        >
                          {notification.day}
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                        {notification.content}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Button
            sx={{
              width: "100%",
              textTransform: "none",
              backgroundColor: "#e7edf2",
              color: "#5686b3",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(231, 237, 242, 0.8)",
              },
            }}
          >
            All Notifications
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfilePopupMobile;
