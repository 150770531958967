import React from "react";
import { Box, Typography, Modal, useTheme } from "@mui/material";
import SummarizerIcon from "assets/icons/SummarizerIcon";
import MisinformationFingerprintIcon from "assets/icons/MisinformationFingerprintsIcon";
import MisinformationSearchIcon from "assets/icons/MisinformationSearchIcon";
import DomainCheckerIcon from "assets/icons/DomainCheckerIcon";
import ToolMenuItem from "components/layout/MuiNavbar/ToolMenuItem";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";

const PopupToolModal = ({ openToolModal, handleCloseModal }) => {
  const theme = useTheme();

  return (
    <Modal
      open={openToolModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 50,
          bgcolor: "background.paper",
          border: "none",
          borderTopLeftRadius: "3rem",
          borderTopRightRadius: "3rem",
          boxShadow: 0,
          p: 2,
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: theme.palette.grey[700],
            }}
          >
            Research Tools
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))", // Distribute items equally across the width
              gap: 2,
            }}
          >
            <ToolMenuItem title="Summarizer" link="/tools/summarizer">
              <SummarizerIcon />
            </ToolMenuItem>
            <ToolMenuItem title="Reading Level" link="/tools/reading-level">
              <AutoStoriesOutlinedIcon />
            </ToolMenuItem>
          </Box>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: theme.palette.grey[700],
              mt: 2
            }}
          >
            Misinformation Toolkit
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
            }}
          >
            <ToolMenuItem title="Domain Checker" link="/tools/domain-checker">
              <DomainCheckerIcon />
            </ToolMenuItem>
            <ToolMenuItem
              title="Misinformation Fingerprint"
              link="/tools/misinformation-fingerprints"
            >
              <MisinformationFingerprintIcon />
            </ToolMenuItem>
            <ToolMenuItem
              title="Misinformation Search"
              link="/tools/misinformation-search"
            >
              <MisinformationSearchIcon />
            </ToolMenuItem>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupToolModal;
