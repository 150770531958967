import { createSlice } from "@reduxjs/toolkit";

export const stylesSlice = createSlice({
  name: "styles",
  initialState: {
    sidebarActive: false,
    darkTheme: false,
    popupCardFooter: null,
    feedbackPopup: false,
    collectionPopup: false,
  },
  reducers: {
    setsidebarActive: (state, action) => {
      const { payload } = action;
      state.sidebarActive = payload;
    },

    setdarkTheme: (state, action) => {
      const { payload } = action;
      state.darkTheme = payload;
    },
    setpopupCardFooter: (state, action) => {
      const { payload } = action;
      state.popupCardFooter = payload;
    },
    setfeedbackPopup: (state, action) => {
      const { payload } = action;
      state.feedbackPopup = payload;
    },
    setcollectionPopup: (state, action) => {
      const { payload } = action;
      state.collectionPopup = payload;
    },
  },
});

// this is for dispatch
export const {
  setsidebarActive,
  setdarkTheme,
  setpopupCardFooter,
  setfeedbackPopup,
  setcollectionPopup,
} = stylesSlice.actions;

// this is for configureStore
export default stylesSlice.reducer;
