import { IconButton, Avatar } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";

export default function RedirectionLinkButton() {
  return (
    <IconButton sx={{ backgroundColor: "navbar.main" }}>
      <CallMadeIcon color="primary" />
    </IconButton>
  );
}
