import React from "react";
import { Outlet } from "react-router-dom";
import MuiNavbar from "components/layout/MuiNavbar";
import LabelBottomNavigation from "components/layout/MuiNavbar/BottomBar";
import { Box, Button, useTheme } from "@mui/material";
import { setProfileSidebar } from "features/User/context/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import Sidebar from "components/base/Sidebar";
import SideMenu from "features/User/components/SideMenu";

export default function ProfileLayout() {
  const dispatch = useDispatch();
  const { profileSidebar } = useSelector((state) => state.profile);

  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "auto 1fr",
          marginBottom: isMobile ? "5vh" : "auto",
          height: { xs: "100%", sm: "100vh" },
          width: "100vw",
        }}
      >
        <Box
          sx={{
            gridColumnEnd: "span 2",
          }}
        >
          <MuiNavbar />
        </Box>
        <Sidebar setFn={setProfileSidebar} toggle={profileSidebar}>
          <SideMenu />
        </Sidebar>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {isMobile && (
            <Box sx={{ pt: 2, px: 3, mb: 2 }}>
              <Button onClick={() => dispatch(setProfileSidebar(true))}>
                All
              </Button>
            </Box>
          )}
          <Outlet />
        </Box>
      </Box>

      <LabelBottomNavigation />
    </React.Fragment>
  );
}
