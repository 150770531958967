import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "lib/axios";
import {
  setInfoDietTimeframe,
  setInfoDietSourceTableData,
  setOpenedLoading,
  setBiasRatingLoading,
  setBiasRatingSourceData,
  setCategorySourceData,
  setCategorySourceLoading,
  setInfoDietEndDate,
  setInfoDietStartDate,
} from "../context";
import { BasicMetric, OpenedMetric } from "../models";
import { InfoDietService } from "../services/info-diet.service";

export function useInfoDiet() {
  const dispatch = useDispatch();
  const {
    infoDietTimeframe,
    infoDietSourceTableData,
    openedLoading,
    biasRatingLoading,
    biasRatingSourceData,
    categorySourceData,
    categorySourceLoading,
    infoDietEndDate,
    infoDietStartDate,
  } = useSelector((state) => state.profile);
  const infoDietService = InfoDietService(axiosInstance);

  function buildFrequencyFilter(timeframe) {
    if (timeframe === 3) {
      return {
        frequencyFilter: timeframe,
        endDate: infoDietEndDate,
        startDate: infoDietStartDate,
      };
    }
    return {
      frequencyFilter: timeframe,
    };
  }

  async function getOpenedMetrics() {
    dispatch(setOpenedLoading(true));
    try {
      const data = await infoDietService.getInfoDietData(
        buildFrequencyFilter(infoDietTimeframe),
        0
      );
      if (!Array.isArray(data)) {
        dispatch(setInfoDietTimeframe([]));
      } else {
        const openedData = data.map(
          (openedMetric, index) => new OpenedMetric(openedMetric, index)
        );
        dispatch(setInfoDietSourceTableData(openedData));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setOpenedLoading(false));
    }
  }

  async function getBiasRatingMetrics() {
    dispatch(setBiasRatingLoading(true));
    try {
      const data = await infoDietService.getUserAnalytics(
        buildFrequencyFilter(infoDietTimeframe),
        3
      );
      if (!Array.isArray(data)) {
        dispatch(setBiasRatingSourceData([]));
      } else {
        const modelData = data.map(
          (metric, index) => new BasicMetric(metric, index)
        );
        dispatch(setBiasRatingSourceData(modelData));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setBiasRatingLoading(false));
    }
  }
  async function getCategoryMetrics() {
    dispatch(setCategorySourceLoading(true));
    try {
      const data = await infoDietService.getUserAnalytics(
        buildFrequencyFilter(infoDietTimeframe),
        2
      );
      if (!Array.isArray(data)) {
        dispatch(setCategorySourceData([]));
      } else {
        const modelData = data.map(
          (metric, index) => new BasicMetric(metric, index)
        );
        dispatch(setCategorySourceData(modelData));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCategorySourceLoading(false));
    }
  }

  function handleTimeframeChange(value) {
    dispatch(setInfoDietTimeframe(value));
  }
  function handleStartDateChange(value) {
    console.log("InfoDietHook.handleStartDateChange", value);
    dispatch(setInfoDietStartDate(value));
  }
  function handleEndDateChange(value) {
    console.log("InfoDietHook.handleEndDateChange", value);
    dispatch(setInfoDietEndDate(value));
  }
  return {
    biasRatingLoading,
    biasRatingSourceData,
    categorySourceData,
    categorySourceLoading,
    getBiasRatingMetrics,
    getCategoryMetrics,
    getOpenedMetrics,
    handleEndDateChange,
    handleStartDateChange,
    handleTimeframeChange,
    infoDietEndDate,
    infoDietSourceTableData,
    infoDietStartDate,
    infoDietTimeframe,
    openedLoading,
  };
}
