import { reduce, find } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BarChart, ChartsText } from "@mui/x-charts";
import { useInfoDiet } from "../hooks";
import { colorArray } from "../data/info-diet.data";
import { round } from "utils/math.util";

export default function BiasRatings() {
  const [totalCount, setTotalCount] = useState(0);
  const [naRating, setNaRating] = useState(0);
  const [dataset, setDataset] = useState([]);
  const theme = useTheme();
  const {
    biasRatingsLoading,
    infoDietTimeframe,
    getBiasRatingMetrics,
    biasRatingSourceData,
    infoDietEndDate,
    infoDietStartDate,
  } = useInfoDiet();

  useEffect(() => {
    getBiasRatingMetrics();
  }, [infoDietTimeframe, infoDietEndDate, infoDietStartDate]);

  useEffect(() => {
    const total = reduce(
      biasRatingSourceData,
      (sum, item) => {
        return sum + item.value;
      },
      0
    );
    setTotalCount(total);
    const naItem = find(
      biasRatingSourceData,
      (item) => item.metricName === "NA"
    );
    if (naItem != null && total !== 0) {
      setNaRating(round((naItem.value / total) * 100, 1));
    } else {
      setNaRating(0);
    }
    setDataset(
      biasRatingSourceData
        .filter((item) => item.metricName !== "NA")
        .map(({ metricName, value }) => {
          if (total === 0) {
            return {
              metricName,
              value,
            };
          }
          return {
            metricName,
            value: round((value / total) * 100, 1),
          };
        })
    );
  }, [biasRatingSourceData]);

  const xAxis = [
    {
      scaleType: "band",
      dataKey: "metricName",
      valueFormatter(value, context) {
        if (context.location === "tick") {
          switch (value) {
            case "Left":
              return "L";
            case "Center":
              return "C";
            case "Right":
              return "R";
            default:
              return "|";
          }
        } else {
          return value;
        }
      },
      colorMap: {
        type: "ordinal",
        colors: colorArray,
      },
    },
  ];

  const series = [
    {
      dataKey: "value",
    },
  ];

  return (
    <Box
      sx={{
        p: 4,
        bgcolor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius / 10,
        display: "grid",
        gridTemplateAreas: '"title divider naRating" "chart chart chart"',
        gridTemplateColumns: "9fr auto 3fr",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <Typography
        component="div"
        variant="h6"
        color="primary.dark"
        sx={{
          gridArea: "title",
        }}
      >
        AllSides Media Bias Rating
        <Typography
          component="sup"
          variant="h6"
          color="primary.dark"
          fontSize="0.6em"
        >
          TM
        </Typography>
      </Typography>
      {totalCount > 0 && (
        <React.Fragment>
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              gridArea: "divider",
            }}
          />
          <Stack
            direction="column"
            gap={0}
            sx={{
              px: 1,
              gridArea: "naRating",
            }}
          >
            <Typography component="div" variant="caption" color="grey.700">
              No rating
            </Typography>
            <Typography
              component="div"
              fontWeight={theme.typography.fontWeightMedium}
            >
              {naRating}%
            </Typography>
          </Stack>
        </React.Fragment>
      )}
      <Box
        sx={{
          gridArea: "chart",
          minHeight: "15vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {biasRatingsLoading ? (
          <Stack
            direction="row"
            alignItems="flex-end"
            minHeight="100%"
            minWidth="100%"
            gap="0.5rem"
          >
            <Skeleton
              variant="rectangular"
              height="40%"
              sx={{
                flex: 1,
              }}
            />
            <Skeleton
              variant="rectangular"
              height="90%"
              sx={{
                flex: 1,
              }}
            />
            <Skeleton
              variant="rectangular"
              height="60%"
              sx={{
                flex: 1,
              }}
            />
            <Skeleton
              variant="rectangular"
              height="25%"
              sx={{
                flex: 1,
              }}
            />
            <Skeleton
              variant="rectangular"
              height="25%"
              sx={{
                flex: 1,
              }}
            />
          </Stack>
        ) : totalCount > 0 ? (
          <BarChart
            dataset={dataset}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            slots={{
              axisTickLabel(props) {
                const { x, y, text, ...otherProps } = props;
                return text.includes("|") ? (
                  <ChartsText {...props} />
                ) : (
                  <React.Fragment>
                    <rect
                      x={x - 10}
                      y={y - 14}
                      width="2rem"
                      height="2rem"
                      className="tickLabelBG"
                    />
                    <ChartsText x={x} y={y} text={text} {...otherProps} />
                  </React.Fragment>
                );
              },
            }}
            margin={{
              left: 0,
              right: 0,
              top: 40,
              bottom: 0,
            }}
            xAxis={xAxis}
            series={series}
            leftAxis={null}
            bottomAxis={null}
            topAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            tooltip={{
              trigger: "none",
            }}
            barLabel={(item) => `${item.value}%`}
            sx={{
              "& .MuiBarElement-root": {
                opacity: 0.4,
              },
              "& .MuiBarLabel-root": {
                fill: theme.palette.text.primary,
                fontWeight: "bold",
                dominantBaseline: "text-after-edge",
              },
              "& .MuiChartsAxis-tickContainer": {
                "& > .tickLabelBG": {
                  fill: theme.palette.secondary.dark,
                },
                "& > .MuiChartsAxis-tickLabel": {
                  fill: theme.palette.secondary.dark,
                  fontWeight: "bold",
                },
                "&:nth-of-type(odd)": {
                  "& > .MuiChartsAxis-tickLabel": {
                    fill: theme.palette.common.white,
                  },
                },
              },
            }}
          />
        ) : (
          <Typography component="div">
            No bias rating data available.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
