import { Grid, Stack, Typography, Divider, Skeleton } from "@mui/material";
import React from "react";
import RedirectionLinkButton from "components/base/button/RedirectionLinkButton";
import { Link } from "react-router-dom";

export default function FingerprintSummary({
  fingerprintData,
  isLoading,
  gotData,
}) {
  if (isLoading) {
    return (
      <Stack direction="column" gap={2}>
        <Skeleton variant="rectangular" height={90} />
        <Skeleton variant="rectangular" height={90} />
        <Skeleton variant="rectangular" height={90} />
      </Stack>
    );
  }

  if (gotData && (!fingerprintData || !fingerprintData.length)) {
    return <Typography variant="body5">No record found.</Typography>;
  }

  if (gotData && fingerprintData.length) {
    return (
      <Grid container direction="row">
        {fingerprintData.map((item, index) => (
          <React.Fragment key={item.soothId}>
            <Grid item md={11} sx={{ mb: 3 }}>
              <Stack gap={1} mt={2}>
                <Typography fontSize="1.6rem">Myth: {item.myth}</Typography>
                <Typography
                  sx={{
                    flexGrow: 1,
                    flexBasis: "60%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: { xs: 4, md: 4, lg: 5 },
                    WebkitBoxOrient: "vertical",
                    maxHeight: {
                      xs: "6em",
                      md: "6em",
                      lg: "7.5em",
                    },
                    lineHeight: "1.5em",
                    flexShrink: 0,
                    color: "rgb(126,127,126)",
                  }}
                >
                  {item.description}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              md={1}
              container
              alignContent="center"
              justifyContent="flex-end"
            >
              <Link to={`/tools/misinformation-fingerprints/details/${item.soothId}`}>
                <RedirectionLinkButton />
              </Link>
            </Grid>
            {!(fingerprintData.length - 1 === index) && (
              <Divider sx={{ width: "100%" }} />
            )}
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  return null;
}
