export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export function downloadFile(fileURL, fileName) {
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
