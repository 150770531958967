export const roles = [
  {
    key: "User",
    label: "Standard User",
  },
  {
    key: "Admin",
    label: "Super Admin",
  },
];

export const filterRoles = [
  {
    key: "User",
    label: "Standard User",
  },
  {
    key: "Admin",
    label: "Super Admin",
  },
  {
    key: "OrganizationAdmin",
    label: "Group: OrganizationAdmin",
  },
  {
    key: "Teacher",
    label: "Group: Teacher",
  },
  {
    key: "Student",
    label: "Group: Student",
  },
];
