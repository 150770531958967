import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_PAGING = {
  page: 0,
  pageSize: 20,
};

export const DEFAULT_FILTER_MODEL = { items: [] };

export const DEFAULT_SORT_MODEL = [{ field: "email", sort: "asc" }];

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminLoading: 0,
    filterModel: { ...DEFAULT_FILTER_MODEL },
    paginationModel: { ...DEFAULT_PAGING },
    sortModel: [...DEFAULT_SORT_MODEL],
    totalSize: 0,
    users: [],
  },
  reducers: {
    setAdminLoading(state, action) {
      const { payload } = action;
      state.adminLoading = payload;
    },
    setFilterModel(state, action) {
      const { payload } = action;
      state.filterModel = payload;
    },
    setPaginationModel(state, action) {
      const { payload } = action;
      state.paginationModel = payload;
    },
    setSortModel(state, action) {
      const { payload } = action;
      state.sortModel = payload;
    },
    setTotalSize(state, action) {
      const { payload } = action;
      state.totalSize = payload;
    },
    setUsers(state, action) {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.users = [...payload];
      }
    },
  },
});

export const {
  setAdminLoading,
  setFilterModel,
  setPaginationModel,
  setSortModel,
  setTotalSize,
  setUsers,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
