import dayjs from "lib/dayjs";

export const getFormattedDate = (datetime, past = true) => {
  if (!datetime) return "NA";
  const date = dayjs(datetime);
  if (!date.isValid()) return "Invalid date";

  const now = dayjs();
  const daysBetween = Math.abs(now.diff(date, "day"));

  if (daysBetween <= 5) {
    return past ? date.fromNow(true) : now.to(date, true);
  }
  return date.local().format("ll");
};

export const getCurrentTime = () => {
  const today = dayjs();
  return today.local().format("hh:mm A");
};
export const getCurrentDate = () => {
  const today = dayjs();
  return today.locale().format("MM/DD/YYYY");
};

export function subtractYearsAndGetFormattedDate(date, yearsToSubtract) {
  const adjustedDate = new Date(date);
  adjustedDate.setFullYear(date.getFullYear() - yearsToSubtract);

  const formattedDate = adjustedDate.toISOString().split("T")[0];
  return formattedDate;
}

export const getRegularDate = (datetime) => {
  if (!datetime) return "";

  const date = new Date(datetime);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const citationDate = (datetime) => {
  if (!datetime) return "";

  const date = new Date(datetime);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
