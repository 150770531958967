import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function SourceInfoBox({
  children,
  title,
  header,
  logo,
  buttonText,
  buttonLink,
  onClick,
  dynamicHeight,
  loading,
  sx = {},
}) {
  const { palette } = useTheme();
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 3,
        height: dynamicHeight ? "100%" : undefined,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box>
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Typography variant="h6" color="primary.dark">
            {title}
            {title == "AllSides Media Bias Rating" && (
              <Typography
                component="sup"
                variant="h6"
                color="primary.dark"
                fontSize="0.6em"
              >
                TM
              </Typography>
            )}
          </Typography>
          {header}
        </Stack>
        <Divider sx={{ mt: 3, mb: 3 }} />
        {children}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          mt: 3,
          "& > *:only-child": {
            marginLeft: "auto",
          },
        }}
      >
        {(buttonLink || onClick) && buttonText && (
          <Button
            LinkComponent={Link}
            to={buttonLink}
            target="_blank"
            variant="chip"
            onClick={onClick}
            loading={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> 
            ) : (
              buttonText 
            )}
          </Button>
        )}
        {logo}
      </Box>
    </Paper>
  );
}
