export class Country {
  constructor({
    countryId,
    countryName,
    iso2,
    phoneCode,
    iso3,
    emoji,
    native,
  }) {
    this.id = countryId;
    this.iso2 = iso2;
    this.iso3 = iso3;
    this.name = countryName;
    this.native = native;
    this.phone_code = phoneCode;
    this.emoji = emoji;
  }
}

export class Region {
  constructor({ stateId, stateName, stateCode }) {
    this.id = stateId;
    this.name = stateName;
    this.state_code = stateCode;
  }
}
