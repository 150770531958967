const { createSvgIcon } = require("@mui/material");

const MisinformationSearchIcon = createSvgIcon(
  <svg
    id="misinformation-search-icon"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.04 24.84"
  >
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path d="M13.07,18.22c.88-1.15,1.41-2.57,1.47-4.12h-2.9c-.32.86-.86,1.72-1.61,2.47-2.11,2.11-5.06,2.6-6.58,1.08-1.52-1.52-1.03-4.47,1.08-6.58,1.19-1.19,2.63-1.84,3.94-1.93l1.25-2.17c-.77-.27-1.59-.43-2.45-.43C3.27,6.54,0,9.81,0,13.82s3.27,7.28,7.28,7.28c1.65,0,3.18-.56,4.4-1.49l3.63,4.64.6.6,2.4-2.4-.6-.6-4.64-3.63Z" />
        <path d="M16.06,0l-6.98,12.11h13.97L16.06,0ZM15.51,9.85l-.35-4.49h1.89l-.35,4.49h-1.2Z" />
      </g>
    </g>
  </svg>
);
export default MisinformationSearchIcon;
