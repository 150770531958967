import axios from "axios";
import { axiosInstance } from "lib/axios";
import { openaiAPI, searchAPI } from "services/apis.service";
import Hashes from "jshashes";

const MD5 = new Hashes.MD5();

export const getSummary = async (url) => {
  const { data } = await axios.post(`${openaiAPI.articleSummary}`, {
    url: url,
  }, {
    id: MD5.hex(url)
  });

  return data;
};

export const getBookmarkedSummary = async (collectionSourceId, user) => {
  const { data } = await axiosInstance.post(
    `${searchAPI.getCollectionSourceSUmmary}`,
    {
      collectionSourceId: collectionSourceId,
      userId: user.userId,
    }
  );

  return data;
};
