const { createSvgIcon } = require("@mui/material");

const MisinformationFingerprintIcon = createSvgIcon(
  <svg
    id="misinformation-fingerprint-icon"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.43 21.66"
  >
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <rect x=".39" y="14.11" width="16.55" height="2.35" />
        <polygon points="9 8.9 0 8.9 0 11.25 7.65 11.25 9 8.9" />
        <path d="M16.45,0l-6.98,12.11h13.97L16.45,0ZM15.9,9.85l-.35-4.49h1.89l-.35,4.49h-1.2Z" />
        <rect x=".39" y="19.31" width="16.55" height="2.35" />
      </g>
    </g>
  </svg>
);

export default MisinformationFingerprintIcon;