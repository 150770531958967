import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { setToolsSidebar } from "context/features/dataSlice";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import SideMenu from "features/Tools/components/SideMenu";
import MuiNavbar from "components/layout/MuiNavbar";
import Sidebar from "components/base/Sidebar";
import LabelBottomNavigation from "./MuiNavbar/BottomBar";

export default function ToolsLayout() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { toolsSidebar } = useSelector((state) => state.data);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "auto 1fr",
          marginBottom: isMobile ? "5vh" : "auto",
          height: { xs: "100%", sm: "100vh" },
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            gridColumnEnd: "span 2",
          }}
        >
          <MuiNavbar />
        </Box>
        <Sidebar setFn={setToolsSidebar} toggle={toolsSidebar}>
          <SideMenu />
        </Sidebar>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {isMobile && (
            <Box sx={{ pt: 2, px: 3, mb: 2 }}>
              <Button onClick={() => dispatch(setToolsSidebar(true))}>
                All
              </Button>
            </Box>
          )}
          <Outlet />
        </Box>
        <LabelBottomNavigation />
      </Box>
    </React.Fragment>
  );
}
