import { createSlice } from "@reduxjs/toolkit";
import groupRoles from "../data/roles.json";
import { find, each, findIndex } from "lodash";

export const DEFAULT_PAGING = {
  page: 0,
  pageSize: 20,
};

export const DEFAULT_SORT_MODEL = [{ field: "email", sort: "asc" }];

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    filterModel: { items: [{}] },
    group: null,
    groupCodes: [],
    groupControlsLoading: 0,
    groupDomains: [],
    groupLoading: 0,
    paginationModel: DEFAULT_PAGING,
    roles: [...groupRoles],
    sortModel: [...DEFAULT_SORT_MODEL],
    totalSize: 0,
    users: [],
  },
  reducers: {
    setFilterModel(state, action) {
      const { payload } = action;
      state.filterModel = payload;
    },
    setGroup(state, action) {
      const { payload } = action;
      state.group = payload;
    },
    setGroupCodes(state, action) {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.groupCodes = payload;
      } else {
        console.warn(
          "GROUPSLICE:SETGROUPCODES: Group Codes must be an array of GroupCodes"
        );
      }
    },
    setGroupControlsLoading(state, action) {
      const { payload } = action;
      state.groupControlsLoading = payload;
    },
    setGroupDomains(state, { payload }) {
      if (Array.isArray(payload)) {
        const arrayUpdates = [];
        each(payload, (item) => {
          const exists = !!find(state.groupDomains, { id: item.id });
          if (exists) {
            arrayUpdates.push({
              action: "UPDATE",
              item,
            });
          } else {
            arrayUpdates.push({
              action: "ADD",
              item,
            });
          }
        });
        each(state.groupDomains, (item) => {
          const doesNotExist = find(payload, (i) => i.id === item.id) == null;
          if (doesNotExist) {
            arrayUpdates.push({
              action: "REMOVE",
              item,
            });
          }
        });
        each(arrayUpdates, (dataUpdate) => {
          const index = findIndex(state.groupDomains, {
            id: dataUpdate.item.id,
          });
          switch (dataUpdate.action) {
            case "ADD":
              state.groupDomains.push(dataUpdate.item);
              break;

            case "UPDATE":
              state.groupDomains.splice(index, 1, dataUpdate.item);
              break;

            case "REMOVE":
              state.groupDomains.splice(index, 1);
              break;

            default:
              break;
          }
        });
      }
    },
    setGroupLoading(state, action) {
      const { payload } = action;
      state.groupLoading = payload;
    },
    setPaginationModel(state, action) {
      const { payload } = action;
      state.paginationModel = payload;
    },
    setSortModel(state, action) {
      const { payload } = action;
      state.sortModel = payload;
    },
    setTotalSize(state, action) {
      const { payload } = action;
      state.totalSize = payload;
    },
    setUsers(state, action) {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.users = payload;
      }
    },
  },
});

export const {
  setFilterModel,
  setGroup,
  setGroupCodes,
  setGroupControlsLoading,
  setGroupDomains,
  setGroupLoading,
  setPaginationModel,
  setSortModel,
  setTotalSize,
  setUsers,
} = groupSlice.actions;

export const groupReducer = groupSlice.reducer;
