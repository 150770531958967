import dayjs from "lib/dayjs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Box, Stack, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AddOutlined, DownloadOutlined } from "@mui/icons-material";

import { axiosInstance } from "lib/axios";
import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";
import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";
import { useIsMobile } from "hooks/is-mobile.hook";
import {
  AdminService,
  AdminUserTable,
  CreateUser,
  useAdmin,
} from "features/Admin";
import { headers } from "features/Admin/data/user-list";

export default function AdminPage() {
  const isMobile = useIsMobile();
  const adminService = new AdminService(axiosInstance);
  const { users, filterModel, sortModel, totalSize } = useAdmin();

  const csvLinkRef = useRef();

  const [csvData, setCsvData] = useState([]);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);

  async function handleExport() {
    setExportLoading(true);
    try {
      const { users } = await adminService.getUsers(
        0,
        totalSize,
        filterModel.items[0],
        sortModel[0]
      );
      setCsvData(users);
      setTriggerDownload(true);
    } catch (error) {
      console.error("Failed to fetch CSV data", error);
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    if (triggerDownload && csvData.length > 0) {
      csvLinkRef.current.link.click();
      setTriggerDownload(false);
    }
  }, [triggerDownload, csvData]);

  return (
    <Fragment>
      <Box
        direction="column"
        sx={{
          ...fillParent,
          ...scrollingBox,
          ...footerFlex,
        }}
      >
        <Box
          sx={{
            display: "grid",
            minHeight: "100%",
            gridTemplateAreas: isMobile
              ? '"title" "buttons" "table" "footer"'
              : '"title buttons" "table table" "footer footer"',
            gridTemplateColumns: isMobile ? "1fr" : "1fr auto",
            gridTemplateRows: isMobile ? "auto auto 1fr auto" : "auto 1fr auto",
            gap: 2,
          }}
        >
          <PageTitle
            sx={{
              gridArea: "title",
            }}
          >
            Sooth Administration
          </PageTitle>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mt: 3,
              gridArea: "buttons",
            }}
          >
            <Button
              startIcon={<AddOutlined fontSize="inherit" />}
              onClick={() => setAddUserModal(true)}
            >
              User
            </Button>
            <LoadingButton
              loading={exportLoading}
              variant="contained"
              startIcon={<DownloadOutlined fontSize="inherit" />}
              loadingPosition="start"
              onClick={handleExport}
              disabled={users.length <= 0}
            >
              Export
            </LoadingButton>
            <CSVLink
              data={csvData}
              filename={`${dayjs().format("YYYYMMDD")}-sooth-users.csv`}
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
              headers={headers}
            />
          </Stack>
          <AdminUserTable
            sx={{
              gridArea: "table",
            }}
          />
          <Footer
            sx={{
              gridArea: "footer",
            }}
          />
        </Box>
      </Box>
      {addUserModal && (
        <CreateUser
          open={addUserModal}
          handleClose={() => {
            setAddUserModal(false);
          }}
        />
      )}
    </Fragment>
  );
}
