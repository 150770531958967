import { Avatar, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function SigninButton({ onClick, image, title, link }) {
  if (onClick != null) {
    return (
      <Button
        onClick={onClick}
        startIcon={<Avatar src={image} />}
        color="secondary"
        size="large"
      >
        {title}
      </Button>
    );
  }
  return (
    <Button
      LinkComponent={Link}
      to={link}
      startIcon={<Avatar src={image} />} color="secondary" size="large"
    >
      {title}
    </Button>
  );
}
