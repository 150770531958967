import React, { useEffect, useState } from "react";
import { Box, List, Typography, styled } from "@mui/material";
import EditorBox from "../Editor";
import ReactMarkdown from "react-markdown";

const SummaryBox = ({
  editing,
  markdownSummary,
  htmlSummary,
  sethtmlSummary,
  saveSummary,
  isFocused,
  setEditing,
  setIsFocused,
}) => {
  const handleBlur = () => {
    setEditing((edit) => !edit);
  };
  return (
    <Box
      sx={{
        borderRadius: "10px",
      }}
    >
      {editing ? (
        <EditorBox
          summaryEditor={true}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          editorState={htmlSummary}
          setEditorState={sethtmlSummary}
          handleSave={saveSummary}
          saveNote={1}
          handleBlur={handleBlur}
          fromSummary={true}
        />
      ) : (
        <ReactMarkdown>{markdownSummary}</ReactMarkdown>
      )}
    </Box>
  );
};

export default SummaryBox;
