import React from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import SourceInfoBox from "features/SourceInfo/components/SourceInfoBox";

export default function WhoIsInfo({ data, sx={} }) {
  return (
    <SourceInfoBox title="Domain Information" sx={sx}>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">Domain</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">{data.domain}</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">Registrar</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">{data.registrar}</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">Registered</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">{data.registeredDate}</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">Expires</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">{data.expiresDate}</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">Updated</Typography>
        </Grid>
        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
          <Typography variant="body2">{data.updatedDate}</Typography>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" color="primary.dark">
          Registrant Contact
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {data.registrarContact}
        </Typography>
      </Box>
    </SourceInfoBox>
  );
}
