import { useTheme } from "@mui/material";

export default function AllSidesLogo({ fontSize }) {
  const { palette, shape } = useTheme();

  return (
    <img
      src="/images/AllSides-Logo.png"
      style={{
        height: fontSize,
        display: "block",
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius / 2,
        padding: shape.borderRadius / 4,
      }}
      alt="All Sides Media Logo"
    />
  );
}
