import { useEffect, useRef, useState } from "react";
import { getBooks } from "../services/book.service";
import { useSourceSearch } from "features/SourceSearch";

const IDPROP = "infoLink";

const useInfiniteScrollBooks = (
  offset,
  setLoading,
  setcategoryData,
  setHasMore
) => {
  const { currentSearchTerm } = useSourceSearch();
  const pageRef = useRef(0);
  const [error, setError] = useState("");

  const getData = async () => {
    try {
      setLoading(true);
      const data = await getBooks(currentSearchTerm, pageRef.current * 9, 9);
      const newBooks = data.data;
      setcategoryData(newBooks, IDPROP);
      setHasMore(!!newBooks.length);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setcategoryData([], IDPROP, true);
    pageRef.current = 0;
    getData();
    pageRef.current = 1;
  }, [currentSearchTerm]);

  useEffect(() => {
    if (pageRef.current) {
      if (offset !== 0) {
        getData();
        pageRef.current += 1;
      }
    }
  }, [offset]);

  return { error };
};

export default useInfiniteScrollBooks;
