import { Fragment, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

import { useVideos } from "../hooks";

export default function VideoCard({ video, videoId, handleAction, ...props }) {
  const { typography } = useTheme();
  const { getVimeoVideoData } = useVideos();

  const [metadata, setMetadata] = useState(null);

  const subheader = useMemo(() => {
    if (metadata == null) {
      return "";
    }
    return `${metadata.date.format("ll")} | ${metadata.duration}`;
  }, [metadata]);

  useEffect(() => {
    if (video != null) {
      setMetadata(video);
    }
  }, [video]);

  useEffect(() => {
    async function fetchData() {
      const metadata = await getVimeoVideoData(videoId);
      setMetadata(metadata);
    }
    if (videoId != null) {
      fetchData();
    }
  }, [videoId]);
  return (
    <Card {...props}>
      {metadata == null ? (
        <Fragment>
          <CardHeader
            title={
              <Skeleton sx={{ fontSize: typography.h5.fontSize }} width="90%" />
            }
            subheader={
              <Skeleton
                sx={{ fontSize: typography.caption.fontSize }}
                width="40%"
              />
            }
          />
          <Skeleton
            sx={{
              height: "150px",
            }}
            variant="rectangular"
          />
          <CardContent>
            <Skeleton sx={{ height: typography.body1.fontSize, mb: 1 }} />
            <Skeleton sx={{ height: typography.body1.fontSize, mb: 1 }} />
            <Skeleton
              sx={{ height: typography.body1.fontSize, mb: 1 }}
              width="50%"
            />
          </CardContent>
        </Fragment>
      ) : (
        <CardActionArea
          onClick={() => handleAction(metadata)}
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
          }}
        >
          <CardHeader
            title={metadata.title}
            subheader={subheader}
            sx={{
              minWidth: "100%",
            }}
          />
          <CardMedia
            height="150"
            component="img"
            image={metadata.thumbnail}
            alt={metadata.title}
          />
          <CardContent
            sx={{
              mb: "auto",
            }}
          >
            <Typography>{metadata.description}</Typography>
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  );
}
