import { useTheme } from "@mui/material/styles";
import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as FourStar } from "assets/images/four_star.svg";
import { ReactComponent as ThreeStar } from "assets/images/three_star.svg";
import { ReactComponent as TwoStar } from "assets/images/two_star.svg";
import { ReactComponent as OneStar } from "assets/images/one_star.svg";
import { ReactComponent as ZeroStar } from "assets/images/zero_star.svg";

import { useEffect, useState } from "react";


export default function CharityNavigatorScore({ score = 100, size = "small" }) {
  const theme = useTheme();
  const [height, setHeight] = useState("1rem");
  const [component, setComponent] = useState(null);


  if (size !== "small" && size !== "large") {
    console.error(Error("Size prop only allows 'small' and 'large' values"));
  }

  useEffect(() => {
    if(score >= 90){
       setComponent(FourStar);
    }else if(score >= 75){
        setComponent(ThreeStar);
    }else if(score >= 60){
        setComponent(TwoStar);
    }else if(score >= 50){
        setComponent(OneStar);
    }else{
        setComponent(ZeroStar);
    }
  }, [score]) 


  useEffect(() => {
    if (size === "large") {
      setHeight("2.5rem");
    } else {
      setHeight("1.4rem");
    }
  }, [size, setHeight]);

  if (score == null || Number.isNaN(Number(score))) {
    return;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <SvgIcon
        component={component}
        inheritViewBox
        sx={{
          //   fill: theme.palette.grey[700],
          width: 60,
        }}
      />
    </Box>
  );
}
