import { createSvgIcon } from "@mui/material";

export const PeriodicalsSpecialtyIcon = createSvgIcon(
  <svg
    id="periodicals-specialty-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <path d="M67.06272,37.16759H27.99998v35.45789h44V37.16759h-4.93726Zm-2.07227,4.0293v11.68506h-29.9809v-11.68506h29.9809Zm-29.9809,27.39929v-11.685h8.33716l1.40051,1.87354c1.18146,1.58044,3.03912,2.51111,5.01233,2.51111h.53625c1.97327,0,3.83087-.93066,5.01233-2.51111l1.40051-1.87354h8.2818v11.685h-29.9809Z" />
    <path d="M58.0557,30.25426c-1.35492-1.8125-3.48529-2.87976-5.74823-2.87976h-4.61499c-2.26288,0-4.39331,1.06726-5.74817,2.87976l-1.60614,2.14856h19.32367l-1.60614-2.14856Z" />
  </svg>
);
