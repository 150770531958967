import React, { useCallback, useEffect, useState } from "react";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Backdrop,
} from "@mui/material";
import { stateFromHTML } from "draft-js-import-html";
import { useNote } from "features/Bookmarks/hooks/useNote.hook";
import { toast } from "react-toastify";
import EditorBox from "../Editor";
import { marked } from "marked";

import "./styles.css";
import { useSelector } from "react-redux";
import Note from "./Note";
import Header from "./Header";
import LoaderPage from "components/layout/LoaderPage";
import { compact, uniqBy } from "lodash";

const Notes = ({ createBookmark }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editEditorState, setEditEditorState] = useState(EditorState.createEmpty());
  const [displayFonts, setDisplayFonts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [anchorEls, setAnchorEls] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [currentNoteIndex, setCurrentNoteIndex] = useState(null);

  const { notes, setNotes, handleDeleteNote, handleAddOrUpdateNote } =
    useNote(setLoading);
  const { note } = useSelector((state) => state.data);

  const handleClose = (index) => {
    setAnchorEls({ ...anchorEls, [index]: null });
  };

  function markdownToHtml(markdown) {
    return marked(markdown);
  }

  function htmlToContentState(html) {
    return stateFromHTML(html);
  }

  function markdownToContentState(markdown) {
    const html = markdownToHtml(markdown);
    return htmlToContentState(html);
  }

  const handleEdit = (index, note) => {
    const contentState = markdownToContentState(note.noteText_chr);
    const existingState = EditorState.createWithContent(contentState);
    setEditEditorState(existingState);
    setCurrentNoteIndex(index);
    setIsEditing(true);
  };

  const saveNote = async (html) => {
    try {
      
      let plainText = "";
      if(isEditing && currentNoteIndex !== null){
        plainText = editEditorState.getCurrentContent().getPlainText().trim();
      }else{
        plainText = editorState.getCurrentContent().getPlainText()
          .trim();
      }

      console.log("called", plainText, editEditorState.getCurrentContent());
      

      if (plainText.length == 0) {
        return;
      }
      setIsSaving(true);
      let trimmedHtml = html.trim();
      let updatedNotes = [...notes];
      if (isEditing && currentNoteIndex !== null) {
        await handleAddOrUpdateNote(
          trimmedHtml,
          currentNoteIndex,
          createBookmark
        );

        updatedNotes[currentNoteIndex] = html;
        updatedNotes = notes.map((note) => {
          if (note.noteId_ids === currentNoteIndex) {
            return { ...note, noteText_chr: html };
          }
          return note;
        });
      } else {
        const data = await handleAddOrUpdateNote(html, null, createBookmark);
        updatedNotes = [data, ...updatedNotes];
      }
      const cleanNotes = uniqBy(compact(updatedNotes), "noteId_ids");
      setNotes(cleanNotes);
      setEditorState(EditorState.createEmpty());
      setIsEditing(false);
      setCurrentNoteIndex(null);
    } catch (e) {
      console.log(e);
      toast.error("Some error occured please try again!");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (index) => {
    try {
      await handleDeleteNote(index);
      let updatedNotes = notes.filter((note) => note.noteId_ids !== index);
      updatedNotes = compact(updatedNotes);
      setNotes(updatedNotes);
      if (currentNoteIndex === index) {
        setEditorState(EditorState.createEmpty());
        setCurrentNoteIndex(null);
        setIsEditing(false);
      }
    } catch (e) {
      toast.error("Sorry could not delete the note.");
    }
    handleClose(index);
  };

  useEffect(() => {
    if (note.noteId_ids) {
      handleEdit(note.noteId_ids, note);
    }
  }, [note.noteId_ids]);

  if (loading) {
    return (
      <Stack direction="column" gap={3} p={2} pt={4}>
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
      </Stack>
    );
  }

  return (
    <Box p={2}>
      <Header
        isEditing={isEditing}
        displayFonts={displayFonts}
        setDisplayFonts={setDisplayFonts}
        editorState={editorState}
        setEditorState={setEditorState}
        saveNote={saveNote}
        isSaving={isSaving && currentNoteIndex === null}
      />
      <Box p={2}>
        {notes.map((note, index) => (
          <Note
            start={note.noteId_ids === notes[0].noteId_ids}
            key={note.noteId_ids}
            note={note}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            currentNoteIndex={currentNoteIndex}
            setCurrentNoteIndex={setCurrentNoteIndex}
            isEditing={isEditing}
            displayFonts={displayFonts}
            setDisplayFonts={setDisplayFonts}
            editorState={editEditorState}
            setEditorState={setEditEditorState}
            saveNote={saveNote}
            isSaving={isSaving}
          />
        ))}
      </Box>
      <Box
        p={2}
        sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}
      ></Box>
    </Box>
  );
};

export default Notes;
