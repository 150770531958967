import { max } from "lodash";
import { abbreviateNumber } from "js-abbreviation-number";
import React, { useEffect, useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { round } from "utils/math.util";
import { useInfoDiet } from "../hooks/info-diet.hook";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";

export default function SourceDetailsTable() {
  const [maxCount, setMaxCount] = useState(0);

  const isMobile = useIsMobile();
  const theme = useTheme();

  const {
    infoDietSourceTableData,
    infoDietTimeframe,
    getOpenedMetrics,
    openedLoading,
    infoDietEndDate,
    infoDietStartDate,
  } = useInfoDiet();
  useEffect(() => {
    getOpenedMetrics();
  }, [infoDietTimeframe, infoDietEndDate, infoDietStartDate]);

  useEffect(() => {
    const maxCount = max(infoDietSourceTableData.map((metric) => metric.count));
    setMaxCount(maxCount);
  }, [infoDietSourceTableData]);

  const tableRows = [
    {
      field: "count",
      headerName: "Links Opened",
      flex: 2.5,
      renderCell({ value }) {
        const abbrValue = abbreviateNumber(value, 1, { padding: false });
        const barPercentage = value / maxCount;
        const barLength = round(maxCount * barPercentage, 1);
        return isMobile ? (
          abbrValue
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              minWidth: "100%",
            }}
          >
            <Typography
              component="div"
              variant="body2"
              sx={{
                flex: 4,
              }}
            >
              {abbrValue}
            </Typography>
            <Stack
              direction="row"
              justifyContent="stretch"
              gap={0}
              sx={{ flex: 8 }}
            >
              <Box
                sx={{
                  height: theme.typography.body1.fontSize,
                  bgcolor: theme.palette.secondary.light,
                  flex: barLength,
                  content: '""',
                }}
              />
              <Box
                sx={{
                  flex: maxCount - barLength,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    },
    { field: "sourceName", headerName: "Source", flex: 3 },
    { field: "category", headerName: "Category", flex: 2 },
    {
      field: "biasRating",
      headerName: "Media Bias",
      flex: 2.5,
      renderCell({ value }) {
        return value == null ? (
          value
        ) : (
          <AllSidesRating activeCategory={value} />
        );
      },
    },
    {
      field: "newsguardScore",
      headerName: "NewsGuard",
      flex: 2,
      renderCell(params) {
        return <NewsguardScore score={params.value} />;
      },
    },
  ];
  return (
    <React.Fragment>
      {openedLoading ? (
        <Stack direction="row" justifyContent="space-between">
          {Array.from(Array(5), (e, r) => (
            <Stack direction="column" key={`row-${r}`}>
              <Skeleton variant="text" height="2.6rem" width="50px" />
              {Array.from(Array(10), (e, c) => (
                <Skeleton
                  variant="text"
                  height="1.4rem"
                  width="75px"
                  key={`cell-${c}`}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      ) : infoDietSourceTableData.length < 1 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>
            No Source click data for selected time period.
          </Typography>
        </Box>
      ) : (
        <DataGrid
          columns={tableRows}
          rows={infoDietSourceTableData}
          initialState={{
            sorting: {
              sortModel: [{ field: "count", sort: "desc" }],
            },
          }}
          autoPageSize
          disableColumnMenu
          disableRowSelectionOnClick
          sx={{
            border: "none",
            maxWidth: "100vw",
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
        />
      )}
    </React.Fragment>
  );
}
