import { find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { ArrowBackOutlined, FilterList } from "@mui/icons-material";

import { useIsMobile } from "hooks/is-mobile.hook";
import { useSourceSearch } from "../hooks";

// import { ReturnDownIcon } from "assets/icons/ReturnDownIcon";
import PillFilter from "./PillFilter";
import { useIsTab } from "hooks/is-tab.hook";
import Timeframe from "./Timeframe";
import { logEvent } from "services/amplitude.service";

export default function PrimarySearchBox({ isDarkBackground = false }) {
  const {
    buildSearchUrl,
    currentCategory,
    currentSearchTerm,
    currentSource,
    currentSubCategory,
    currentTimeframe,
    defaultSource,
    defaultTimeframe,
    endDate,
    isActive,
    handleActiveStateChange,
    handleSourceCategoryChange,
    sources,
    sourceCategories,
    startDate,
    subCategories,
    timeframes,
  } = useSourceSearch();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const input = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(currentSearchTerm);
  const [source, setSource] = useState(currentSource);
  const [category, setCategory] = useState(currentCategory);
  const [localCategories, setLocalCategories] = useState(
    handleSourceCategoryChange(source)
  );
  const [subCategory, setSubCategory] = useState(currentSubCategory);
  const [timeframe, setTimeframe] = useState(currentTimeframe);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [collapse, setCollapse] = useState(false);
  const [elevation, setElevation] = useState(0);

  function handleOpen() {
    setOpen(true);
    setCollapse(true);
    setTimeout(() => input.current.focus(), 0);
  }

  function handleClose() {
    setCollapse(false);
  }

  function handleInputOnChange(event) {
    setSearchTerm(event.target.value);
  }

  function handleEndCollapse() {
    setOpen(false);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  function handleFilterChange(value, setFn) {
    setFn(value);
  }

  function handleSubmit() {
    const categories = handleSourceCategoryChange(source);
    const catObj = find(categories, { key: category });
    const to = buildSearchUrl({
      query: searchTerm,
      source,
      category: catObj == null ? null : catObj.key,
      date: timeframe,
      subCategory,
      endDate: localEndDate,
      startDate: localStartDate,
    });
    if (to) {
      handleActiveStateChange(false);
      navigate(to);
    }
    logEvent("submit_navbar_search", { url: to });
  }

  useEffect(() => {
    setLocalCategories(handleSourceCategoryChange(source));
    // if (!find(localCategories, { key: category })) {
    //   handleFilterChange(null, setCategory, "SETCATEGORYFROMSOURCEUSEEFFECT");
    // }
    if (source === "books" || source === "wiki") {
      handleFilterChange(defaultTimeframe.key, setTimeframe);
      handleFilterChange(null, setCategory);
    }
  }, [source, defaultTimeframe, handleSourceCategoryChange]);

  useEffect(() => {
    if (category !== "academia") {
      handleFilterChange(null, setSubCategory);
    } else {
      handleFilterChange(defaultTimeframe.key, setTimeframe);
    }
  }, [category]);

  useEffect(() => {
    if (isActive) {
      handleOpen();
      setElevation(12);
    } else {
      handleClose();
      setElevation(0);
    }
  }, [isActive]);

  useEffect(() => {
    handleActiveStateChange(false);
  }, [location]);

  useEffect(() => {
    if (searchTerm !== currentSearchTerm) {
      if (currentSearchTerm != null) {
        setSearchTerm(currentSearchTerm);
      } else {
        setSearchTerm("");
      }
    }
    if (source !== currentSource) {
      if (currentSource != null) {
        setSource(currentSource);
      } else {
        setSource(defaultSource.key);
      }
    }
    if (category !== currentCategory) {
      if (currentCategory != null) {
        handleFilterChange(currentCategory, setCategory);
      } else {
        setCategory(null);
      }
    }
    if (subCategory !== currentSubCategory) {
      if (currentSubCategory != null) {
        setSubCategory(currentSubCategory);
      } else {
        setSubCategory(null);
      }
    }
    if (timeframe !== currentTimeframe) {
      if (currentTimeframe != null) {
        setTimeframe(currentTimeframe);
      } else {
        setTimeframe(defaultTimeframe.key);
      }
    }
    if (localStartDate !== startDate) {
      if (startDate != null) {
        setLocalStartDate(startDate);
      }
    }
    if (localEndDate !== endDate) {
      if (endDate != null) {
        setLocalEndDate(endDate);
      }
    }
  }, [
    currentSearchTerm,
    currentSource,
    defaultSource,
    currentCategory,
    sourceCategories,
    currentSubCategory,
    currentTimeframe,
    defaultTimeframe,
    startDate,
    endDate,
  ]);
  return (
    <ClickAwayListener onClickAway={() => handleActiveStateChange(false)}>
      <Box tabIndex="-1" onKeyUp={handleKeyPress}>
        <Paper
          elevation={elevation}
          className={open ? "active" : "inactive"}
          sx={{
            position: open ? (isMobile ? "fixed" : "sticky") : "unset",
            bgcolor:
              isDarkBackground && theme.palette.mode === "light"
                ? "common.white"
                : open
                ? "background.default"
                : "secondary.dark",
            top: 0,
            // bottom: isMobile ? 0 : "unset",
            left: isMobile ? 0 : "unset",
            width: { xs: "80vw", sm: "30vw" },
            borderRadius: open && isMobile ? 0 : 3,
            overflow: "hidden",
            px: 2,
            zIndex: theme.zIndex.appBar,
            "@keyframes change-width-active": {
              to: {
                width: "50vw",
              },
              from: {
                width: "30vw",
              },
            },
            "@keyframes change-width-inactive": {
              to: {
                width: "30vw",
              },
              from: {
                width: "50vw",
              },
            },
            "&.active": {
              width: { xs: "100vw", sm: "50vw" },
              animation: isMobile ? "unset" : "0.5s ease change-width-active",
            },
            "&.inactive": {
              animation: isMobile ? "unset" : "0.2s ease change-width-inactive",
            },
          }}
        >
          <TextField
            size="medium"
            inputRef={input}
            className={open ? "active" : "inactive"}
            fullWidth
            variant={open ? "standard" : "outlined"}
            placeholder="Start your search"
            onFocus={() => handleActiveStateChange(true)}
            value={searchTerm}
            onChange={handleInputOnChange}
            InputProps={{
              startAdornment: open && (
                <InputAdornment position="start">
                  <IconButton
                    color="primary"
                    onClick={() => handleActiveStateChange(false)}
                    edge="start"
                  >
                    <ArrowBackOutlined
                      sx={{
                        width: "1.6rem",
                        height: "1.6rem",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: open ? (
                <InputAdornment position="end">
                  <Button variant="text" onClick={handleSubmit} edge="end">
                    Search
                  </Button>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleActiveStateChange(true)}
                    edge="end"
                  >
                    <FilterList />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "&.inactive .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.active > .MuiInputBase-root": {
                p: 1,
                px: 2,
                mb: 2,
              },
            }}
          />
          <Collapse in={collapse} onExited={handleEndCollapse}>
            <Box
              sx={{
                minHeight: "10vh",
                display: "grid",
                gridTemplateColumns: "repeat(12, minmax(min-content, 1fr))",
              }}
            >
              <PillFilter
                title="Source Type"
                filters={sources}
                currentFilter={source}
                handleChange={(value) => {
                  handleFilterChange(value, setSource);
                }}
                required
                keyPrefix="source"
                sx={{
                  gridColumn: { xs: "span 12", md: "span 7" },
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  borderRight: isTab
                    ? "unset"
                    : `1px solid ${theme.palette.grey[300]}`,
                }}
              />
              <Timeframe
                frequencies={timeframes}
                timeframeValue={timeframe}
                setTimeframeValue={setTimeframe}
                endDateValue={localEndDate}
                setEndDateValue={setLocalEndDate}
                startDateValue={localStartDate}
                setStartDateValue={setLocalStartDate}
                disabled={
                  source === "books" ||
                  source === "wiki" ||
                  category === "academia"
                }
                sx={{
                  gridColumn: { xs: "span 12", md: "span 5" },
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  p: 2,
                }}
              ></Timeframe>
              <PillFilter
                title="Categories"
                filters={localCategories}
                currentFilter={category}
                handleChange={(value) => {
                  handleFilterChange(value, setCategory);
                }}
                keyPrefix="category"
                disabled={source !== "categories" && source !== "rating"}
                sx={{
                  gridColumn: "span 12",
                  borderBottom: category === "academia" ? `1px solid ${theme.palette.grey[300]}` : null,
                }}
              />
              <Collapse
                in={category === "academia"}
                sx={{
                  gridColumn: "span 12",
                }}
              >
                <PillFilter
                  title="Academic Sources"
                  filters={subCategories}
                  currentFilter={subCategory}
                  keyPrefix="academic-source"
                  handleChange={(value) =>
                    handleFilterChange(value, setSubCategory)
                  }
                />
              </Collapse>
            </Box>
          </Collapse>
        </Paper>
      </Box>
    </ClickAwayListener>
  );
}
