import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";

export default function AllSidesCategoryIcon({
  category,
  color,
  active,
  size,
}) {
  const theme = useTheme();
  const [height, setHeight] = useState("1.5rem");
  const [width, setWidth] = useState("1.5rem");
  useEffect(() => {
    if (size === "large") {
      setHeight("2.8rem");
      setWidth("2.8rem");
    }
  }, [size]);
  return (
    <Box
      width={width}
      height={height}
      sx={{
        bgcolor: active
          ? color
          : theme.palette.mode === "dark"
          ? theme.palette.grey[600]
          : theme.palette.grey[300],
        color: theme.palette.common.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        p: 0,
        fontSize: size === "large" ? "2.2rem" : "1.4rem",
      }}
    >
      {category}
    </Box>
  );
}
