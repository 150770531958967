import React from "react";
import News from "features/News";
import SearchLayout from "components/layout/SearchLayout";
import BookmarkDrawer from "features/Bookmarks";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
} from "context/features/dataSlice";
import SourceSidebar from "features/SourceInfo";

const Newspage = () => {
  const { isSidebarOpen } = useSelector((state) => state.data);
  const { isSourceSidebarOpen } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const sidebarClosedHandler = () => {
    dispatch(setIsSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const showSidebar = () => {
    dispatch(setIsSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const sourceSidebarClosedHandler = () => {
    dispatch(setIsSourceSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const showSourceSidebar = () => {
    dispatch(setIsSourceSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <React.Fragment>
      <SearchLayout>
        <News />
      </SearchLayout>
      <BookmarkDrawer
        isSidebarOpen={isSidebarOpen}
        sidebarClosedHandler={sidebarClosedHandler}
        showSidebar={showSidebar}
      />
      <SourceSidebar
        isSourceSidebarOpen={isSourceSidebarOpen}
        sourceSidebarClosedHandler={sourceSidebarClosedHandler}
        showSourceSidebar={showSourceSidebar}
      />
    </React.Fragment>
  );
};

export default Newspage;
