import { AcademiaIcon } from "assets/icons/AcademiaIcon";
import AcademiaIconLink from "assets/icons/icon_academia.svg";
import { GlobalNewsIcon } from "assets/icons/GlobalNewsIcon";
import GlobalNewsIconLink from "assets/icons/icon_global_news.svg";
import { InterGovernmentalIcon } from "assets/icons/InterGovernmentalIcon";
import InterGovernmentalIconLink from "assets/icons/icon_intergovernmental.svg";
import { OtherSourcesIcon } from "assets/icons/OtherSourcesIcon";
import { PeriodicalsSpecialtyIcon } from "assets/icons/PeriodicalsSpecialtyIcon";
import PeriodicalsSpecialtyIconLink from "assets/icons/icon_periodicals_specialty.svg";
import { ThinkTankIcon } from "assets/icons/ThinkTankIcon";
import ThinkTankIconLink from "assets/icons/icon_think_tanks.svg";
import { UsGovernmentIcon } from "assets/icons/UsGovernmentIcon";
import UsGovernmentIconLink from "assets/icons/icon_usgov.svg";
import React from "react";

export default function CategoryIcon({
  isBackground = false,
  iconId,
  sx,
  ...props
}) {
  switch (iconId) {
    case "Periodicals & Specialty News":
    case "category1":
      return isBackground ? (
        PeriodicalsSpecialtyIconLink
      ) : (
        <PeriodicalsSpecialtyIcon sx={sx} />
      );
    case "Think Tanks":
    case "category2":
      return isBackground ? (
        ThinkTankIconLink
      ) : (
        <ThinkTankIcon sx={sx} {...props} />
      );
    case "Global News":
    case "category3":
      return isBackground ? (
        GlobalNewsIconLink
      ) : (
        <GlobalNewsIcon sx={sx} {...props} />
      );
    case "Academia & Scholarly Resources":
    case "category4":
      return isBackground ? (
        AcademiaIconLink
      ) : (
        <AcademiaIcon sx={sx} {...props} />
      );
    case "Other Selected Sources":
    case "category5":
      return isBackground ? (
        AcademiaIconLink
      ) : (
        <OtherSourcesIcon sx={sx} {...props} />
      );
    case "US Government":
    case "category6":
      return isBackground ? (
        UsGovernmentIconLink
      ) : (
        <UsGovernmentIcon sx={sx} {...props} />
      );
    case "Intergovernmental Organizations":
    case "category7":
      return isBackground ? (
        InterGovernmentalIconLink
      ) : (
        <InterGovernmentalIcon sx={sx} {...props} />
      );
    default:
      return isBackground ? "" : null;
  }
}
