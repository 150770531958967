import { createSvgIcon } from "@mui/material";

const SummarizerIcon = createSvgIcon(
<svg id="summarizer-icon" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.81 21.82">
  <g id="Layer_2-2" data-name="Layer 2">
    <g>
      <path d="M21.81,5.35h0c-2.42.65-4.69,2.93-5.35,5.35h0c-.65-2.42-2.93-4.69-5.35-5.35h0c2.42-.65,4.69-2.93,5.35-5.35h0c.65,2.42,2.93,4.69,5.35,5.35Z"/>
      <path d="M8.67,21.82H0v-2.35h8.67c1.77,0,2.99-.5,3.89-1.4l1.71-1.71c1.35-1.35,2.64-2.09,4.55-2.09h1v2.35h-1c-1.28,0-2.48.5-3.39,1.4l-1.71,1.71c-1.35,1.35-3.14,2.09-5.05,2.09Z"/>
      <path d="M19.82,16.61h-4.97c-1.91,0-3.21-.74-4.56-2.09l-1.71-1.71c-.91-.91-2.17-1.4-3.88-1.4H0v-2.35h4.71c1.91,0,3.7.74,5.05,2.09l1.71,1.71c.91.91,2.11,1.4,3.39,1.4h4.97v2.35Z"/>
      <rect y="14.26" width="19.82" height="2.35"/>
    </g>
  </g>
</svg>
);
export default SummarizerIcon;