import { axiosInstance } from "lib/axios";
import { citationAPI, searchAPI } from "services/apis.service";
import axios from "axios";
import Hashes from "jshashes";

const MD5 = Hashes.MD5();

export const fetchCitationStyles = async () => {
  const { data } = await axios.get(citationAPI.getCitationStyles);

  return data;
};

export const fetchCitationFields = async (user, currentItemType) => {
  const { data } = await axiosInstance.post(
    searchAPI.GetCitationDynamicData,
    {
      user: user.userId,
      itemTypeId: currentItemType.id,
    },
    {
      cache: true,
    }
  );
  return data;
};

export const fetchCitationMetaData = async (signal, url) => {
  let config = {
    method: "post",
    url: citationAPI.getCitationMeta,
    headers: {
      "Content-Type": "text/plain",
    },
    data: url,
    signal,
  };
  const { data } = await axios.request(config);
  return data;
};

export const fetchSavedCitationMeta = async (collectionSourceId, user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getCitation,
    {
      collectionSourceId: collectionSourceId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const generateCitation = async (
  currentItemType,
  selectedStyle,
  articleData,
  authors
) => {
  let config = {
    method: "post",
    cache: false,
    url: `${citationAPI.getCitation}?responseformat=html&style=${selectedStyle}`,
    data: {
      items: [
        {
          ...articleData,
          itemType:
            currentItemType.toLowerCase() === "webpage"
              ? "webpage"
              : currentItemType,
          styles: selectedStyle,
          key: articleData.key ? articleData.key : "VH$KV",

          ...(authors.length > 0 &&
            authors[0].firstName.length > 0 && {
              creators: authors,
            }),
        },
      ],
    },
  };
  const { data } = await axios.request(config);
  return data;
};
