import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";

export const fetchQuickSearchResults = async () => {
  const response = await axiosInstance.post(
    searchAPI.quickSearch,
    {
      numberToSelect: 6,
    },
    {
      cache: false,
    }
  );
  return response.data.data;
};
