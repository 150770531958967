import dayjs from "lib/dayjs";
import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    accountExpiration: dayjs.utc().add(2, "day").format(), //"2025-06-06T00:00:00+05:00",
    accountType: "trial", //"individual-k12-yearly-sub",
    autoRenew: false,
    billingHistory: [],
    currentPayment: "",
    groupId: null,
    gradeLicenseLevel: null,
    groupRole: "Administrator",
    init: false,
    subscriptionId: null,
    timeTillExpired: null,
  },
  reducers: {
    setAccountExpiration(state, action) {
      const { payload } = action;
      state.accountExpiration = payload;
    },
    setAccountType(state, action) {
      const { payload } = action;
      state.accountType = payload;
    },
    setAutoRenew(state, action) {
      const { payload } = action;
      state.autoRenew = payload;
    },
    setBillingHistory(state, action) {
      const { payload } = action;
      if (!Array.isArray) {
        throw new Error(
          "ACCOUNTSLICE|SETBILLINGHISTORY: Payload must be an array."
        );
      }
      state.billingHistory = payload;
    },
    setCurrentPayment(state, action) {
      const { payload } = action;
      state.currentPayment = payload;
    },
    setGroupId(state, action) {
      const { payload } = action;
      state.groupId = payload;
    },
    setGradeLicenseLevel(state, action) {
      const { payload } = action;
      state.gradeLicenseLevel = payload;
    },
    setGroupRole(state, action) {
      const { payload } = action;
      state.groupRole = payload;
    },
    setInit(state, action) {
      const { payload } = action;
      if (typeof payload === "boolean" && payload != null) {
        state.init = payload;
      } else {
        console.warn("ACCOUNTSLICE:SETINIT: init type must be a Boolean");
      }
    },
    setSubscriptionId(state, action) {
      const { payload } = action;
      state.subscriptionId = payload;
    },
    setTimeTillExpired(state, action) {
      const { payload } = action;
      state.timeTillExpired = payload;
    },
  },
});

export const {
  setAccountExpiration,
  setAccountType,
  setAutoRenew,
  setBillingHistory,
  setCurrentPayment,
  setGroupId,
  setGradeLicenseLevel,
  setGroupRole,
  setInit,
  setSubscriptionId,
  setTimeTillExpired,
} = accountSlice.actions;

export const accountReducer = accountSlice.reducer;
