import { Fragment, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";

import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";

import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";
import { useVideos, VideoCard, VideoDialog } from "features/Support";
import { filter, map, orderBy } from "lodash";

export default function SupportPage() {
  const { getVimeoUsersVideosMetadata } = useVideos();
  const [activeVideo, setActiveVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  function handleOpen(video) {
    setActiveVideo(video);
  }

  function handleClose() {
    setActiveVideo(null);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const videos = await getVimeoUsersVideosMetadata("215554981");
        setVideos(videos);
      } catch (error) {
        const { response } = error;
        if (response != null) {
          switch (response.status) {
            case 403:
            case 503:
              console.log(response.status);
              setErrorMessage({
                status: 403,
                title: "Vimeo Video Access Denied",
                message: (
                  <Fragment>
                    <Typography>Unable to access videos from Vimeo.</Typography>
                    <Typography>
                      Vimeo is a public video service and may be blocked on your
                      network, please speak with your IT support to determine if
                      video access is needed.
                    </Typography>
                  </Fragment>
                ),
              });
              break;

            default:
              setErrorMessage({
                status: 400,
                message: (
                  <Typography>
                    Unable to get videos from Vimeo. Please check back later.
                  </Typography>
                ),
              });
              break;
          }
        } else {
          setErrorMessage({
            status: 500,
            message: (
              <Typography>
                There was an error getting the videos from Vimeo. Please check
                back later.
              </Typography>
            ),
          });
        }
      }
    }
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>Help Videos</PageTitle>
        {errorMessage != null && (
          <Alert severity="error">
            <AlertTitle>
              {errorMessage.title != null
                ? errorMessage.title
                : "Problem getting help videos"}
            </AlertTitle>
            {errorMessage.message}
          </Alert>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: 2,
          }}
        >
          {map(
            orderBy(
              filter(videos, (video) => video.tags.includes("help")),
              ["dateOrder"],
              ["desc"]
            ),
            (video) => (
              <VideoCard
                video={video}
                handleAction={handleOpen}
                key={video.id}
              />
            )
          )}
        </Box>
        {activeVideo != null && (
          <VideoDialog
            open={activeVideo != null}
            handleClose={handleClose}
            title={activeVideo.title}
            id={activeVideo.id}
          />
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
