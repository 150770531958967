import { axiosInstance } from "lib/axios"
import { searchAPI } from "services/apis.service"

export const getWorksCited = async (id) => {
    const { data } = await axiosInstance.post(searchAPI.getWorkCited, {
      CollectionId: id,
      Filter: "",
      ReturnAllRows: false,
      SortExpression: "",
      Paginate: true,
    }, {
        cache: false
    });
    return data;
}

export const getCollectionsSourceWorksCited = async ({
  workcitedId = null,
  collectionId = null,
  name = "",
  formatStyle = "",
}) => {
  const payload = {
    name,
    formatStyle,
    returnAllRows: false,
  };
  console.log(workcitedId, "work cited id ");
  
  if (workcitedId) {
    payload.workcitedId = Number(workcitedId);
  } if (collectionId) {
    payload.collectionId = collectionId;
  }
  
  const { data } = await axiosInstance.post(
    searchAPI.getCollectionSourceForWorkCited,
    payload,
    {
      cache: false,
    }
  );
  return data;
};

export const addUpdateWorkCitedService = async ({
  workcitedId = null,
  collectionId = null,
  name = "",
  formatStyle = "",
  collectionSourceIds= []
}) => {
  const payload = {
    name,
    formatStyle,
    collectionSourceIds,
  };

  if (workcitedId) {
    payload.workcitedId = workcitedId;
  } else if (collectionId) {
    payload.collectionId = collectionId;
  }

  const { data } = await axiosInstance.post(
    searchAPI.addUpdateWorkCited,
    payload,
  );

  return data;
};

export const getWorksCitedDocument = async (id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getWorkCitedDocument,
    {
      workCitedId: id,
    },
    {
      responseType: "blob", 
      cache: false,
    }
  );
  return new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });;
};

export const deleteWorkCited = async (id) => {
    await axiosInstance.delete(searchAPI.deleteWorkCited, {
      data: {
        workCitedId: id,
      },
    });
}