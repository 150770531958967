import { axiosInstance } from "lib/axios";
import { authAPI, searchAPI } from "services/apis.service";

export const getrecentCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getRecentCollections,
    {
      callingUserId: user.userId,
      userId: user.userId,
    },
    {
      cache: false,
    }
  );
  return data;
};

export const getrecentCollectionSource = async (user, filter, signal) => {
  const { data } = await axiosInstance.post(
    searchAPI.getCollectionsSource,
    {
      filter: filter,
      callingUserId: user.userId,
      userId: user.userId,
      // pageSize: 25,
    },
    {
      cache: false,
      signal: signal,
    }
  );
  return data;
};
