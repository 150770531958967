import { authAPI } from "services/apis.service";
import { UserInfo } from "../models";

export class UserInfoService {
  #axios;

  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async getUserInfo() {
    try {
      const response = await this.#axios.get(authAPI.getUserDetails, {
        cache: false,
      });

      return new UserInfo(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateGradeLicenseKey(groupLicenseKey, userId) {
    try {
      await this.#axios.post(
        authAPI.updateGroupLicenseKey,
        {
          userId,
          groupLicenseKey,
        },
        { cache: false }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
