export const sortOptions = [
  { name: "A - Z", key: 0 },
  { name: "Z - A", key: 1 },
  { name: "Largest to Smallest", key: 2 },
  { name: "Smallest to Largest", key: 3 },
];

export const colorArray = [
  "#126ba3",
  "#a3becf",
  "#6e3aa6",
  "#bd6c81",
  "#a10830",
];

export const frequencies = [
  { name: "All Time", value: 0 },
  { name: "Last Month", value: 1 },
  { name: "Last Year", value: 2 },
  { name: "Custom Date", value: 3 },
];

export const ITEMS_PER_PAGE = 10;

export const metrics = [
  { name: "Opened", value: 0 },
  { name: "Source Category", value: 2 },
  { name: "Media Bias Rating", value: 3 },
];
