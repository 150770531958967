import { useTheme } from "@mui/material";

export default function CharityNavigatorLogo({ fontSize }) {
  const { palette, shape } = useTheme();
  return (
    <img
      src="/images/CharityNav_Logo.png"
      style={{
        height: fontSize,
        display: "block",
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius / 2,
        padding: shape.borderRadius / 4,
      }}
      className="charity-logo"
      alt="logo"
    />
  );
}
