export const itemTypes = [
  {
    name: "Blog Post",
    key: "blogPost",
    id: 1,
  },
  {
    name: "Web Page",
    key: "webpage",
    id: 2,
  },
  {
    name: "Artwork",
    key: "artwork",
    id: 4,
  },
  {
    name: "Attachment",
    key: "attachment",
    id: 5,
  },
  {
    name: "Audio Recording",
    key: "audioRecording",
    id: 6,
  },
  {
    name: "Bill",
    key: "bill",
    id: 7,
  },
  {
    name: "Book",
    key: "book",
    id: 8,
  },
  {
    name: "Book Section",
    key: "bookSection",
    id: 9,
  },
  {
    name: "Case",
    key: "case",
    id: 10,
  },
  {
    name: "Computer Program",
    key: "computerProgram",
    id: 11,
  },
  {
    name: "Conference Paper",
    key: "conferencePaper",
    id: 12,
  },
  {
    name: "Dataset",
    key: "dataset",
    id: 13,
  },
  {
    name: "Dictionary Entry",
    key: "dictionaryEntry",
    id: 14,
  },
  {
    name: "Document",
    key: "document",
    id: 15,
  },
  {
    name: "Email",
    key: "email",
    id: 16,
  },
  {
    name: "Encyclopedia Article",
    key: "encyclopediaArticle",
    id: 17,
  },
  {
    name: "Film",
    key: "film",
    id: 18,
  },
  {
    name: "Forum Post",
    key: "forumPost",
    id: 19,
  },
  {
    name: "Hearing",
    key: "hearing",
    id: 20,
  },
  {
    name: "Instant Message",
    key: "instantMessage",
    id: 21,
  },
  {
    name: "Interview",
    key: "interview",
    id: 22,
  },
  {
    name: "Journal Article",
    key: "journalArticle",
    id: 23,
  },
  {
    name: "Letter",
    key: "letter",
    id: 24,
  },
  {
    name: "Magazine Article",
    key: "magazineArticle",
    id: 25,
  },
  {
    name: "Manuscript",
    key: "manuscript",
    id: 26,
  },
  {
    name: "Map",
    key: "map",
    id: 27,
  },
  {
    name: "Newspaper Article",
    key: "newspaperArticle",
    id: 28,
  },
  {
    name: "Patent",
    key: "patent",
    id: 30,
  },
  {
    name: "Podcast",
    key: "podcast",
    id: 31,
  },
  {
    name: "Preprint",
    key: "preprint",
    id: 32,
  },
  {
    name: "Presentation",
    key: "presentation",
    id: 33,
  },
  {
    name: "Radio Broadcast",
    key: "radioBroadcast",
    id: 34,
  },
  {
    name: "Report",
    key: "report",
    id: 35,
  },
  {
    name: "Standard",
    key: "standard",
    id: 36,
  },
  {
    name: "Statute",
    key: "statute",
    id: 37,
  },
  {
    name: "Thesis",
    key: "thesis",
    id: 38,
  },
  {
    name: "Tv Broadcast",
    key: "tvBroadcast",
    id: 39,
  },
  {
    name: "Video Recording",
    key: "videoRecording",
    id: 40,
  },
];
