import React, { useEffect, useState } from 'react'
import Table from './Table'
import useWorkCited from '../hooks/use-work-cited.hook'
import { useParams } from 'react-router-dom';

const MainPage = ({setWorkCitedName, setWorkCitedFormatStyle}) => {
  const [worksCited, setWorksCited] = useState([]);

  const { getWorkCited, getWorkCitedDocument, deleteWorkCitedDocument } =
    useWorkCited(worksCited, setWorksCited);

    const {id} = useParams();

    useEffect(() => {
      getWorkCited();
    }, [id])

  return (
    <Table
      deleteWorkCitedDocument={deleteWorkCitedDocument}
      rows={worksCited}
      getWorkCitedDocument={getWorkCitedDocument}
      setWorkCitedName={setWorkCitedName}
      setWorkCitedFormatStyle={setWorkCitedFormatStyle}
    />
  );
}

export default MainPage