import { find } from "lodash";
import { Fragment, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import { getFormattedDate } from "utils/time.util";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

import { SubscriptionTrialOptions } from "../../components";
import { useUserData, useSubscriptions } from "../../hooks";
import { GroupSubscriptionDetails } from "features/Group/components/subscriptions/GroupSubscriptionDetails";
import { NavLink } from "react-router-dom";
import { useGroup } from "features/Group";
import { NumericFormat } from "react-number-format";

export default function IndividualSubscriptionDetails() {
  const isMobile = useIsMobile();
  const { typography } = useTheme();
  const {
    accountExpiration,
    accountType,
    autoRenew,
    billingHistory,
    currentPayment,
    isSubscriptionExpired,
    groupId,
    timeTillExpired,
  } = useUserData();
  const { group } = useGroup();
  const { products, subsLoading, createPortalSession } = useSubscriptions();
  const [product, setProduct] = useState(null);
  const [portalLink, setPortalLink] = useState(null);
  const [renewModalOpen, setRenewModalOpen] = useState(false);

  const columnsDef = [
    {
      field: "created",
      headerName: "Date",
      flex: 2,
      valueFormatter: (value) => {
        return getFormattedDate(value);
      },
    },
    {
      field: "planType",
      headerName: "Plan",
      flex: 5,
      valueFormatter: (value) => {
        const product = find(
          products,
          (product) =>
            product.metadata.planType != null &&
            product.metadata.planType.toLowerCase() === value.toLowerCase()
        );
        if (product) {
          return product.name;
        }
        return value;
      },
    },
    { field: "paymentMethod", headerName: "Payment", flex: 4 },
    {
      field: "price",
      headerName: "Amount",
      flex: 2,
      renderCell: ({ value }) => (
        <NumericFormat
          value={value}
          decimalScale={2}
          prefix="$"
          defaultValue="-"
          displayType="text"
          fixedDecimalScale={true}
          thousandSeparator=","
          thousandsGroupStyle="thousand"
        />
      ),
    },
  ];

  useEffect(() => {
    async function fetchLink() {
      const link = await createPortalSession();
      setPortalLink(link);
    }
    fetchLink();
  }, []);

  useEffect(() => {
    const product = find(products, (product) => {
      return (
        product.metadata.planType &&
        product.metadata.planType.toLowerCase() === accountType
      );
    });

    setProduct(product);
  }, [accountType, products]);

  return (
    <Fragment>
      <Stack
        direction="column"
        gap={1}
        sx={{
          gridArea: "current",
        }}
      >
        {group != null && group.isPaymentPending && (
          <Alert severity="warning">
            <AlertTitle>Payment Pending</AlertTitle>
            Please ensure payment has been completed before your expiration date
            or your users may lose access to the Sooth platform.
          </Alert>
        )}
        <Stack direction="row" alignItems="center">
          {product && !subsLoading ? (
            <Typography
              variant="subtitle1"
              sx={{
                flex: isMobile ? 1 : 3,
              }}
            >
              {product.name}
            </Typography>
          ) : (
            <Skeleton
              sx={{
                flex: isMobile ? 1 : 3,
                fontSize: typography.subtitle1.fontSize,
              }}
            />
          )}
          {!subsLoading ? (
            <Typography
              sx={{
                flex: isMobile ? 1 : 3,
              }}
            >
              {`${
                autoRenew
                  ? "Renewal: "
                  : isSubscriptionExpired
                  ? "Expired: "
                  : "Expires: "
              } ${getFormattedDate(accountExpiration, false)}`}
            </Typography>
          ) : (
            <Skeleton
              sx={{
                flex: isMobile ? 1 : 3,
                fontSize: typography.body1.fontSize,
              }}
            />
          )}
          <Box
            sx={{
              flex: isMobile ? 1 : 3,
            }}
          >
            {group != null && group.isPaymentPending && (
              <Typography>Payment Status: Pending</Typography>
            )}
          </Box>
          <Box
            sx={{
              flex: isMobile ? 1 : 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {groupId == null && isSubscriptionExpired ? (
              <Button onClick={() => setRenewModalOpen(true)} variant="text">
                Renew Subscription
              </Button>
            ) : (
              groupId == null && (
                <Button
                  variant="text"
                  LinkComponent={Link}
                  href={portalLink}
                  disabled={subsLoading || portalLink == null}
                >
                  Manage
                </Button>
              )
            )}
            {groupId != null &&
              group != null &&
              !group.isPaymentPending &&
              timeTillExpired < 91 && (
                <Button LinkComponent={NavLink} to="group-plan" variant="text">
                  Renew Subscription
                </Button>
              )}
          </Box>
        </Stack>
        {currentPayment && (
          <Typography variant="subtitle2">
            <Typography variant="subtitle2" component="span" fontWeight="bold">
              Payment:
            </Typography>{" "}
            {!subsLoading ? (
              currentPayment
            ) : (
              <Skeleton
                sx={{ width: "5vw", fontSize: typography.subtitle2.fontSize }}
              />
            )}
          </Typography>
        )}
      </Stack>
      <GroupSubscriptionDetails
        sx={{
          gridArea: "group",
        }}
      />
      <Box
        sx={{
          gridArea: "history",
          pb: 8,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            mt: 4,
          }}
        >
          Billing History
        </Typography>
        <DataGrid
          columns={columnsDef}
          rows={billingHistory}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }],
            },
          }}
          density="compact"
          autoPageSize
          disableColumnMenu
          disableRowSelectionOnClick
          sx={{
            border: "none",
            maxWidth: "100vw",
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
        />
      </Box>
      {autoRenew && (
        <Box
          sx={{
            gridArea: "cancel",
            mb: 2,
          }}
        >
          <Alert
            variant="outlined"
            severity="warning"
            action={
              <Button
                color="error"
                variant="text"
                LinkComponent={Link}
                href={portalLink}
                disabled={subsLoading || portalLink == null}
              >
                Manage Subscription
              </Button>
            }
          >
            <AlertTitle>Cancel Subscription</AlertTitle>
            Do you wish to cancel your subscription? Click manage subscription
            to start the process.
          </Alert>
        </Box>
      )}
      {isSubscriptionExpired && (
        <Dialog
          open={renewModalOpen}
          onClose={() => setRenewModalOpen(false)}
          fullWidth
          maxWidth={isMobile ? "xl" : "md"}
        >
          <DialogTitle>Renew Subscription</DialogTitle>
          <DialogContent>
            <SubscriptionTrialOptions />
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
}
