import { useState } from "react";
import { useEffect } from "react";
import { itemTypes } from "data/item-types";
import { useSelector } from "react-redux";
import useCitationFields from "./use-citation-fields.hook";
import {
  fetchCitationMetaData,
  fetchSavedCitationMeta,
} from "features/Bookmarks/services/citation.service";
import axios from "axios";
import dayjs from "dayjs";
import { mapCitationData } from "data/citation";
import { toast } from "react-toastify";

const useCitationData = (
  setLoading,
  setArticleData,
  setCurrentItemType,
  setAuthors,
  setDate,
  setAccessDate,
  setCitation,
  setSelectedStyle,
  articleData,
  additionalData,
  setAdditionalData,
  selectedBookmarkOption,
  citationLoaded,
  setCitationLoaded,
  setOriginalArticleData,
  setOriginalAuthors
) => {
  const [baseFields, setBaseFields] = useState({});
  const [fields, setFields] = useState({});
  const [requiredFields, setRequiredFields] = useState([]);

  const { summaryInfoData, collectionSourceId, isCitationAvailable } =
    useSelector((state) => state.data);
  const { user } = useSelector((state) => state.auth);
  const { fetchFields } = useCitationFields();

  // fetch saved page

  const findBaseFieldKeyByValue = (valueToFind) => {
    const baseFieldKey = Object.keys(baseFields).find(
      (key) => baseFields[key] === valueToFind
    );
    return baseFieldKey;
  };

  const getBaseFieldVal = (newBaseFields, field) => {
    const baseFieldKey = findBaseFieldKeyByValue(newBaseFields[field]);

    if (articleData[baseFieldKey]) {
      return articleData[baseFieldKey];
    }
    return additionalData[newBaseFields[field]];
  };

  const checkBaseField = (newBaseFields, field) => {
    return (
      newBaseFields[field] && findBaseFieldKeyByValue(newBaseFields[field])
    );
  };

  const resetAdditionalData = (newArticleData, fields, articleData) => {
    let newAdditionalData = { ...additionalData };
    Object.keys(articleData).forEach((key) => {
      if (!fields.hasOwnProperty(key) && !newArticleData.hasOwnProperty(key)) {
        newAdditionalData[key] = articleData[key];
      }
    });

    return newAdditionalData;
  };

  const resetArticleData = (
    newArticleData,
    fields,
    articleData,
    newBaseFields
  ) => {
    Object.keys(fields).forEach((field) => {
      if (articleData.hasOwnProperty(field)) {
        newArticleData[field] = articleData[field];
      } else if (checkBaseField(newBaseFields, field)) {
        newArticleData[field] = getBaseFieldVal(newBaseFields, field);
        additionalData[newBaseFields[field]] = newArticleData[field];
      } else if (additionalData.hasOwnProperty(field)) {
        newArticleData[field] = additionalData[field];
      } else {
        newArticleData[field] = "";
      }
    });

    const baseFieldArr = Object.values(baseFields);
    Object.keys(fields).forEach((field) => {
      if (baseFieldArr.includes(field)) {
        const baseKey = Object.keys(baseFields).find(
          (key) => baseFields[key] === field
        );
        newArticleData[field] = articleData[baseKey];
      }
    });
    setBaseFields(newBaseFields);
    return newArticleData;
  };

  const mapArticleData = (res, fields) => {
    let newArticleData = {},
      additionalData = {};
    newArticleData = resetArticleData(
      newArticleData,
      fields.fields,
      res,
      fields.baseFields
    );
    additionalData = resetAdditionalData(newArticleData, fields, res);
    setArticleData(newArticleData);
    setOriginalArticleData(newArticleData);
    setAdditionalData(additionalData);
  };

  async function mapBackendToFrontend(data, currentItemType) {
    const res = mapCitationData(data);
    const fields = await fetchFields(
      1,
      currentItemType,
      baseFields,
      setLoading,
      setFields,
      setRequiredFields
    );
    mapArticleData(res, fields);
    return res;
  }

  const fetchIfExistingCitation = async () => {
    try {
      let MetaData = await fetchSavedCitationMeta(collectionSourceId, user);
      const { data, authors } = setAuthorNames(MetaData);
      setOriginalAuthors(authors.map((author) => ({ ...author })));
      const currentItemType = setFetchedItemType(data);
      setSelectedStyle(data[0].styles_chr);
      setCitation(data[0].citation_chr);
      await mapBackendToFrontend(data[0], currentItemType.key);
      // setDate(getValidDate(data[0].date_dtm));
      // setAccessDate(getValidDate(data[0].accessDate_dtm));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setCitationLoaded(true);
    }
  };

  const setAuthorNames = (data) => {
    let authors = [];
    if (isCitationAvailable && data[0]?.creators?.length > 0) {
      authors = data[0]?.creators?.map((author) => {
        return {
          firstName: author.firstName_chr,
          lastName: author.lastName_chr,
          creatorType: "author",
          citationCreatorId: author.citationCreatorId_ids,
        };
      });
      data[0].creators = authors;
      setAuthors(authors);
    } else if (data[0]?.creators?.length > 0) {
      authors = data[0]?.creators?.map((author) => {
        if (author.name) {
          return {
            firstName: author.name,
            creatorType: "author",
          };
        }
        return {
          firstName: author.firstName,
          lastName: author.lastName,
          creatorType: "author",
        };
      });
      setAuthors(authors);
    }
    return { data, authors: [...authors] };
  };

  const setFetchedItemType = (data) => {
    const itemType = isCitationAvailable
      ? data[0].itemType_chr
      : data[0].itemType;
    const currentItemType = itemTypes.filter(
      (item) => item.key.toLowerCase() === itemType.toLowerCase()
    )[0];
    setCurrentItemType(currentItemType.key);
    if (itemType === "webpage") {
      setCurrentItemType("webPage");
    }
    return currentItemType;
  };

  // fetch citation meta

  const handleEmptyDataFields = (data, fields) => {
    if (!data.length || typeof data[0] !== "object" || data[0] === null) {
      data[0] = { itemType: "webpage", styles: "apa" };
    }

    Object.keys(fields).forEach((field) => {
      if (!(field in data[0]) || !data[0][field]) {
        data[0][field] = field.toLocaleLowerCase().includes("date") ? null : "";
      }
    });

    return data;
  };

  const handleExternalArticleBaseFieldsData = (data, baseFields) => {
    const tempExternalData = {
      title: summaryInfoData.title,
      url: summaryInfoData.url,
      publicationTitle: summaryInfoData.publisher,
      data: summaryInfoData.published_date,
    };

    for (const key in baseFields) {
      const mappedField = baseFields[key];

      if (tempExternalData[mappedField] && !data[0][key]) {
        data[0][key] = tempExternalData[mappedField];
      }
    }

    return data;
  };

  const handleExternalArticleData = (data, fields) => {
    if (summaryInfoData.title && fields.title) {
      if (!data[0]?.title)
        data[0] = {
          ...data[0],
          title: summaryInfoData.title,
        };
    }
    if (summaryInfoData.url && fields.url) {
      data[0] = {
        ...data[0],
        url: summaryInfoData.url,
      };
    }
    if (summaryInfoData.publisher && fields.publicationTitle) {
      data[0] = {
        ...data[0],
        publicationTitle: summaryInfoData.publisher,
      };
    }
    return data;
  };
  function getValidDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null;
    } else {
      return date;
    }
  }

  const handleArticleDates = (data, fields) => {
    if (summaryInfoData.published_date && fields.date) {
      setDate(dayjs(summaryInfoData.published_date));
      data[0] = {
        ...data[0],
        date: dayjs(summaryInfoData.published_date),
      };
    } else {
      if (getValidDate(data[0].date)) {
        setDate(dayjs(data[0].date));
      } else {
        setDate(null);
      }
    }
    if (fields.accessDate) setAccessDate(dayjs(data[0].accessDate));
  };

  const format_response = (data) => {
    const creators = data[0].creators || [];

    setArticleData((prev) => {
      const newState = {
        ...prev,
        ...data[0],
        date: getValidDate(data[0].date),
        accessDate: getValidDate(data[0].accessDate),
      };
      return newState;
    });
  };

  const handleErrorCitationResponse = async (currentItemType) => {
    setCurrentItemType(currentItemType);
    let fields = await fetchFields(
      1,
      currentItemType,
      baseFields,
      setLoading,
      setFields,
      setRequiredFields
    );
    setBaseFields(fields.baseFields);
    let data = {};
    data = handleEmptyDataFields(data, fields.fields);
    data = handleExternalArticleData(data, fields.fields);
    data = handleExternalArticleBaseFieldsData(data, fields.baseFields);
    handleArticleDates(data, fields.fields);
    setArticleData(data[0]);
  };

  const handleSuccessCitationResponse = async (data, currentItemType) => {
    let fields = await fetchFields(
      1,
      currentItemType.key,
      baseFields,
      setLoading,
      setFields,
      setRequiredFields
    );
    setBaseFields(fields.baseFields);

    data = handleEmptyDataFields(data, fields.fields);
    data = handleExternalArticleData(data, fields.fields);
    data = handleExternalArticleBaseFieldsData(data, fields.baseFields);
    handleArticleDates(data, fields.fields);

    format_response(data);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchCitationData = async () => {
      setLoading(true);

      if (isCitationAvailable) {
        fetchIfExistingCitation();
        return;
      }

      try {
        const metaData = await fetchCitationMetaData(
          signal,
          summaryInfoData.url
        );

        if (!metaData[0].itemType) {
          throw new Error("Item type is missing in the metadata.");
        }

        const { data, authors } = setAuthorNames(metaData);

        const currentItemType = setFetchedItemType(data);

        await handleSuccessCitationResponse(data, currentItemType);
        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          await handleErrorCitationResponse("webPage");
        }
      } finally {
        setCitationLoaded(true);
      }
    };

    if (!citationLoaded && selectedBookmarkOption === "citation")
      fetchCitationData();
  }, [selectedBookmarkOption]);

  return {
    baseFields,
    fields,
    requiredFields,
    setRequiredFields,
    setFields,
    setBaseFields,
    resetArticleData,
    resetAdditionalData,
  };
};

export default useCitationData;
