import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  SvgIcon,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useIsMobile } from "hooks/use-breakpoint-checks.hook";

import ProfilePopupMobile from "../popup/ProfilePopUpMobile";
import PopupToolModal from "../popup/PopupToolModal";
import ProfileAvatar from "components/base/ProfileAvatar";
import { ReactComponent as PulseIconLogo } from "assets/icons/pulse-icon-white.svg";
import { ReactComponent as ResearchToolsWhite } from "assets/icons/research-tools-icon-white.svg";
import { ReactComponent as CollectionWhiteLogo } from "assets/icons/collections-icon-white.svg";

export default function LabelBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const { user } = useSelector((state) => state.auth);
  const [value, setValue] = useState("recents");
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openToolModal, setOpenToolModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleCloseModal();
  };

  const handleToolClick = () => {
    setOpenToolModal(!openToolModal);
    setOpenProfileModal(false);
  };

  const handleAvatarClick = () => {
    setOpenProfileModal(!openProfileModal);
    setOpenToolModal(false);
  };

  const handleCloseModal = () => {
    setOpenProfileModal(false);
    setOpenToolModal(false);
  };

  useEffect(() => {
    const pathname = location.pathname.substring(1);
    if (pathname.length > 0) {
      const root = pathname.split("/")[0];
      setValue(root);
    } else {
      setValue("search");
    }
  }, [location]);

  return (
    isMobile && (
      <Box
        sx={{
          pb: 7,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1301,
        }}
      >
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            sx={{ bgcolor: theme.palette.background.default }}
          >
            <BottomNavigationAction
              onClick={() => navigate("/")}
              label="Search"
              value="search"
              icon={
                <SearchIcon
                  sx={{ width: 22, height: 22, color: theme.palette.grey[700] }}
                />
              }
            />
            <BottomNavigationAction
              onClick={() => navigate("/pulse")}
              label="Pulse"
              value="Pulse"
              icon={
                <SvgIcon
                  component={PulseIconLogo}
                  inheritViewBox
                  sx={{
                    fill: theme.palette.grey[700],
                    width: 25,
                  }}
                />
              }
            />
            <BottomNavigationAction
              label="Collections"
              value="collections"
              onClick={() => navigate("/collections")}
              icon={
                <SvgIcon
                  component={CollectionWhiteLogo}
                  inheritViewBox
                  sx={{
                    fill: theme.palette.grey[700],
                    width: 25,
                  }}
                />
              }
              sx={{
                "&.Mui-selected > svg": {
                  fill: theme.palette.primary.main,
                },
              }}
            />
            <BottomNavigationAction
              label="Tools"
              value="tools"
              onClick={handleToolClick}
              icon={
                <SvgIcon
                  component={ResearchToolsWhite}
                  inheritViewBox
                  sx={{
                    fill: theme.palette.grey[700],
                    width: 25,
                  }}
                />
              }
              sx={{
                "&.Mui-selected > svg": {
                  fill: theme.palette.primary.main,
                },
              }}
            />
            <BottomNavigationAction
              label="Profile"
              value="profile"
              // onClick={handleAvatarClick}
              onClick={() => navigate("/profile/settings")}
              icon={<ProfileAvatar userId={user.userId} />}
            />
          </BottomNavigation>
        </Paper>
        <ProfilePopupMobile
          openProfileModal={openProfileModal}
          handleCloseModal={handleCloseModal}
        />
        <PopupToolModal
          openToolModal={openToolModal}
          handleCloseModal={handleCloseModal}
        />
      </Box>
    )
  );
}
