import { find, get } from "lodash";
import { forwardRef, Fragment, useMemo } from "react";
import { NumericFormat } from "react-number-format";
import { Box, Stack, Typography } from "@mui/material";

import { useIsMobile } from "hooks/is-mobile.hook";
import FormikTextField from "components/base/FormikTextField";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator={true}
    />
  );
});

export default function LicenseLevelRow({
  planType,
  index,
  priceData,
  credits,
  prorationPercentage,
  errors,
  touched,
  handleBlur,
  handleChange,
  ...props
}) {
  const isMobile = useIsMobile();

  const name = useMemo(() => `planTypeData[${index}].noOfLicenses`, [index]);
  const cleanSeats = useMemo(() => {
    return planType.noOfLicenses == null || planType.noOfLicenses === ""
      ? -1
      : planType.noOfLicenses;
  }, [planType]);

  const pricing = useMemo(
    () =>
      find(priceData, ["key", planType != null ? planType.classGroupId : 0]),
    [priceData, planType]
  );

  const isK5License = useMemo(() => {
    return planType != null && planType.key === "group-k5";
  }, [planType]);

  const isProrated = useMemo(
    () => prorationPercentage !== 1,
    [prorationPercentage]
  );

  function totalPrice(seats, credits, pricePerSeat, isK5License) {
    let total;
    if (isK5License) {
      total = (seats - credits) * pricePerSeat;
    } else {
      total = seats * pricePerSeat;
    }
    return total;
  }

  if (planType == null) {
    return null;
  }

  return (
    <Box {...props}>
      {isMobile && (
        <FormikTextField
          fullWidth
          error={get(errors, name)}
          touched={get(touched, name, null)}
          handleBlur={handleBlur}
          handleChange={handleChange}
          value={planType.noOfLicenses}
          label={planType.label}
          name={name}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      )}
      <Stack direction="row" alignItems="start" minHeight="50px">
        {!isMobile && (
          <FormikTextField
            fullWidth
            error={get(errors, name)}
            touched={get(touched, name, null)}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={planType.noOfLicenses}
            label={planType.label}
            name={name}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            sx={{
              flex: 3,
            }}
          />
        )}
        {!isK5License ? (
          <Box
            sx={{
              flex: isMobile ? 6 : 5,
            }}
          />
        ) : (
          <Fragment>
            <Stack direction="column" sx={{ flex: isMobile ? 3 : 2.5 }} gap={0}>
              <Typography variant="caption">Net Licenses:</Typography>
              <Typography>
                <NumericFormat
                  value={cleanSeats < 0 ? 0 : cleanSeats - credits}
                  defaultValue="-"
                  displayType="text"
                  thousandSeparator=","
                  thousandsGroupStyle="thousand"
                />
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ flex: isMobile ? 3 : 2.5 }} gap={0}>
              <Typography variant="caption">Credits:</Typography>
              <Typography>
                <NumericFormat
                  value={credits}
                  defaultValue="-"
                  displayType="text"
                  thousandSeparator=","
                  thousandsGroupStyle="thousand"
                />
              </Typography>
            </Stack>
          </Fragment>
        )}
        <Box
          sx={{
            flex: isMobile ? 2 : 1,
          }}
        >
          <Typography align="right">
            {planType.key === "group-k5" &&
            cleanSeats > 0 &&
            cleanSeats <= credits ? (
              "Free"
            ) : (
              <NumericFormat
                value={pricing == null ? null : pricing.value / 100}
                decimalScale={2}
                prefix="$"
                defaultValue="-"
                displayType="text"
                fixedDecimalScale={true}
                thousandSeparator=","
                thousandsGroupStyle="thousand"
              />
            )}
          </Typography>
        </Box>
        <Stack
          direction="column"
          gap={0}
          sx={{
            flex: isMobile ? 4 : 3,
          }}
        >
          <Typography
            align="right"
            sx={{
              textDecoration: isProrated ? "line-through" : "none",
            }}
          >
            <NumericFormat
              value={
                pricing != null
                  ? cleanSeats < 0
                    ? totalPrice(0, credits, pricing.value, isK5License) / 100
                    : totalPrice(
                        cleanSeats,
                        credits,
                        pricing.value,
                        isK5License
                      ) / 100
                  : null
              }
              decimalScale={2}
              prefix="$"
              defaultValue="-"
              displayType="text"
              fixedDecimalScale={true}
              thousandSeparator=","
              thousandsGroupStyle="thousand"
            />
          </Typography>
          {isProrated ? (
            <Typography align="right">
              Prorate:{" "}
              <NumericFormat
                value={
                  pricing != null
                    ? cleanSeats < 0
                      ? (totalPrice(0, credits, pricing.value, isK5License) *
                          prorationPercentage) /
                        100
                      : (totalPrice(
                          cleanSeats,
                          credits,
                          pricing.value,
                          isK5License
                        ) *
                          prorationPercentage) /
                        100
                    : null
                }
                decimalScale={2}
                prefix="$"
                defaultValue="-"
                displayType="text"
                fixedDecimalScale={true}
                thousandSeparator=","
                thousandsGroupStyle="thousand"
              />
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
}
