export const sources = [
  { label: "By Categories", key: "categories", default: true },
  { label: "By Media Bias", key: "rating" },
  { label: "Encyclopedia & Dictionary", key: "wiki" },
  { label: "Books", key: "books" },
];

export const categories = [
  { label: "Periodicals & Specialty News", key: "periodics" },
  { label: "Think Tanks", key: "thinkthanks" },
  { label: "Global News", key: "globalnews" },
  { label: "Academia & Scholarly Resources", key: "academia" },
  { label: "Other Selected Sources", key: "others" },
  { label: "US Government", key: "usgovernment" },
  { label: "Intergovernmental Organizations", key: "intergovernment" },
];

export const academiaOptions = [
  { label: "CORE", key: "CORE" },
  { label: "Semantic Scholar", key: "Semantic Scholar" },
  { label: "Arxiv", key: "Arxiv" },
  { label: "Springer Nature", key: "Springer Nature" },
  { label: "PLOS", key: "PLOS" },
];

export const biasCategories = [
  { label: "Left & Lean Left", key: "Left" },
  { label: "Center", key: "Center" },
  { label: "Right & Lean Right", key: "Right" },
];

export const filterValue = [
  { label: "Past 24 hours", key: "Past 24 hours" },
  { label: "Past Week", key: "Past Week" },
  { label: "Past Month", key: "Past Month" },
  { label: "Past Year", key: "Past Year" },
  { label: "Past 2 Years", key: "Past 2 Years" },
  { label: "Past 3 Years", key: "Past 3 Years" },
  { label: "All time", key: "All time" },
  { label: "Custom Date", key: "custom-date" },
];
