import { Box, Skeleton, Stack } from "@mui/material";

export default function ItemSkeleton({sx}) {
  return (
    <Stack spacing={2} minWidth="100%" sx={sx} justifyContent="space-evenly">
      <Stack direction="row" spacing={1}>
        <Box sx={{flex: 2}}>
          <Skeleton variant="text" width="40%"/>
        </Box>
        <Skeleton variant="rectangular"  sx={{flex: 1}}/>
        <Skeleton variant="rectangular"  sx={{flex: 1}}/>
      </Stack>
      <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "stretch" }}
      >
        <Box width="60%">
          <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} width="60%" />
        </Box>
        <Skeleton variant="rectangular" width="40%" height={50} sx={{borderRadius: 1}} />
      </Stack>
    </Stack>
  );
}