import React, { useState } from "react";
import {
  Button,
  IconButton,
  Box,
  TextField,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

const EditPopup = ({ edit, handleRenameCollection, setEdit, name }) => {
  const [title, setTitle] = useState(name);
  const params = useParams();
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const handleEdit = async () => {
    await handleRenameCollection(params.id, title);
    setEdit(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEdit();
    }
  };

  const onClose = () => {
    setEdit(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "34vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal
      open={edit}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
      }}
    >
      <Box sx={modalStyle}>
        <Box sx={{ p: 5 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Rename Collection
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1">
              Please enter the new title for this collection.
            </Typography>
            <TextField
              sx={{ width: "100%", mt: 2 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            display: "flex",
            justifyContent: "space-between",
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button onClick={onClose} variant="outlined" sx={{ width: "45%" }}>
            Cancel
          </Button>
          <Button
            onClick={handleEdit}
            sx={{
              width: "45%",
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPopup;
