import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { useSelector } from "react-redux";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const RotatingTypography = styled(Typography)`
  position: absolute;
  animation: ${rotate} 1.2s linear infinite;
  z-index: 999;
  color: #1976d2;
`;

function CustomLoader(props) {
  const loaderSizes = [75, 65, 45];
  const strokeWidth = 2;
  const { darkTheme } = useSelector((state) => state.styles);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: props.height ? props.height : "100vh",
        backgroundColor: darkTheme
          ? "#333333"
          : props.style
          ? "rgb(239, 233, 233)"
          : "#fff",
        position: "relative",
      }}
    >
      {loaderSizes.map((size, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: size,
            height: size,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <CircularProgress
            size={size - (index > 0 ? strokeWidth * 2 * (index - 1) : 0)}
            thickness={strokeWidth}
            style={{
              animationDirection: index % 2 === 0 ? "normal" : "reverse",
            }}
            variant="indeterminate"
          />
          {size === 45 && (
            <RotatingTypography variant="h4" component="div">
              S
            </RotatingTypography>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default CustomLoader;
