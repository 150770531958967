import React from "react";
import { Box, Typography } from "@mui/material";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import MisinformationSearch from "features/Tools/components/MisinformationSearch";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";

export default function MisinformationSearchPage() {
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <PageTitle>Misinformation Search</PageTitle>
        <MisinformationSearch />
      </Box>
      <Footer />
    </Box>
  );
}
