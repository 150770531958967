import { createSvgIcon } from "@mui/material";

const SummarizerIcon = createSvgIcon(
<svg id="domain-checker-icon" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.08 14.76">
  <g id="Layer_2-2" data-name="Layer 2">
    <g>
      <rect x="24.12" y=".79" width="3.37" height="16.55" transform="translate(34.87 -16.74) rotate(90)"/>
      <path d="M8.52,0L0,14.76h17.03L8.52,0ZM7.85,12.01l-.42-5.48h2.31l-.42,5.48h-1.46Z"/>
    </g>
  </g>
</svg>
);
export default SummarizerIcon;