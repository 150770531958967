import { axiosInstance } from "lib/axios";
import { searchAPI } from "services/apis.service";
import Hashes from "jshashes";

const MD5 = new Hashes.MD5();

export const getComments = async (collectionId, userId, noteId) => {
  let payload = { collectionSourceId: collectionId, userId: userId };
  if (noteId) {
    payload = { ...payload, noteId: noteId };
  }
  const { data } = await axiosInstance.post(
    searchAPI.getNotes,
    payload,
    {
      id: MD5.hex(`notes:${collectionId}:${userId}:${noteId}`),
      cache: false,
    }
  );
  return data;
};

export const deleteNote = async (noteId, userId) => {
  await axiosInstance.delete(searchAPI.deleteNote, {
    data: {
      noteId: noteId,
      userId: userId,
    },
  });
};

export const addOrUpdateNote = async (
  noteText,
  noteId,
  userId,
  collectionId
) => {
  let payload = {
    collectionSourceId: collectionId,
    userId: userId,
    noteText: noteText,
  };
  if (noteId) {
    payload = { ...payload, noteId: noteId };
  }
  const { data } = await axiosInstance.post(searchAPI.addUpdateNotes, payload, {
    cache: false,
  });
  return data;
};
