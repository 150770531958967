import axios from "axios";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authAPI } from "services/apis.service";
import { validateUserPassword } from "utils/validation.util";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setloading] = useState(false);

  async function onSubmitLogin(e) {
    e.preventDefault();

    if (!validateUserPassword(password)) {
      toast.error("Please enter a valid password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }

    try {
      setloading(true);

      const res = await axios.post(authAPI.resetPasswrd, {
        userId: searchParams.get("userId"),
        code: searchParams.get("code"),
        resetPassword: password,
        resetConfirmPassword: confirmPassword,
      });

      if (res.data) {
        toast.success(res.data.message);
        navigate("/");
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.title);
      setloading(false);
    }
  }

  return (
    <Stack direction="column" gap={2}>
      <Typography variant="h3">Reset Password</Typography>
      <Box
        component="form"
        onSubmit={onSubmitLogin}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 5,
        }}
      >
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="password">New Password</InputLabel>
          <OutlinedInput
            required
            id="password"
            type={passwordShow ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordShow((value) => !value)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {!passwordShow ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <FormHelperText id="password-helper-text">
            NOTE: Password should be minimum 8 characters with at least one
            uppercase letter, one lowercase letter, one special character and
            one number.
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="confirm-password">
            Confirm New Password
          </InputLabel>
          <OutlinedInput
            required
            id="confirm-password"
            type={passwordShow ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordShow((value) => !value)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {!passwordShow ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm New Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </FormControl>

        <Stack direction="row" gap={2} justifyContent="end">
          <Button variant="outlined" LinkComponent={Link} to="/">
            Back
          </Button>
          <Button loading={loading} type="submit">
            Sign Up
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
