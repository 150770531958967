export const metrics = [
  { name: "Opened", value: 0 },
  { name: "Source Category", value: 2 },
  { name: "Media Bias Rating", value: 3 },
];
export const frequencies = [
  { name: "All Time", value: 0 },
  { name: "Last Month", value: 1 },
  { name: "Last Year", value: 2 },
  { name: "Custom Date", value: 3 },
];
export const colorArray = [
  "#126ba3",
  "#a3becf",
  "#6e3aa6",
  "#bd6c81",
  "#a10830",
];
export const openedDataSample = [
  {
    category_chr: "Global News",
    entity_chr: "BNN Bloomberg",
    mediaBiasRating_chr: null,
    score_chr: "92.50",
    metricCount_lng: 2000,
  },
  {
    category_chr: "Global News",
    entity_chr: "CNBC",
    mediaBiasRating_chr: "Center",
    score_chr: "95.00",
    metricCount_lng: 20,
  },
  {
    category_chr: "Global News",
    entity_chr: "Los Angeles Times",
    mediaBiasRating_chr: null,
    score_chr: "100.00",
    metricCount_lng: 2,
  },
  {
    category_chr: "Global News",
    entity_chr: "Reuters",
    mediaBiasRating_chr: "Center",
    score_chr: "100.00",
    metricCount_lng: 200,
  },
  {
    category_chr: "Global News",
    entity_chr: "The Boston Globe",
    mediaBiasRating_chr: "Left",
    score_chr: "92.50",
    metricCount_lng: 6500,
  },
  {
    category_chr: "Global News",
    entity_chr: "The Guardian",
    mediaBiasRating_chr: "Lean Left",
    score_chr: "100.00",
    metricCount_lng: 250,
  },
  {
    category_chr: "Global News",
    entity_chr: "The New York Times",
    mediaBiasRating_chr: "Lean Left",
    score_chr: "87.50",
    metricCount_lng: 12000,
  },
  {
    category_chr: "Global News",
    entity_chr: "The Washington Post",
    mediaBiasRating_chr: null,
    score_chr: "100.00",
    metricCount_lng: 2,
  },
];
export const sourceCategoryDataSample = [
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Global News",
      "metricCount_lng": 1500
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Periodicals & Speciality News",
      "metricCount_lng": 1000
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Academia",
      "metricCount_lng": 800
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Think Tanks",
      "metricCount_lng": 750
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Other Selected Sources",
      "metricCount_lng": 400
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "US Gov",
      "metricCount_lng": 175
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "IGO",
      "metricCount_lng": 85
  }
]
export const biasRatingDataSample = [
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Left",
      "metricCount_lng": 127
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Lean Left",
      "metricCount_lng": 562
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Center",
      "metricCount_lng": 1293
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Lean Right",
      "metricCount_lng": 619
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "Right",
      "metricCount_lng": 70
  },
  {
      "userId_chr": "00251a0c-27e7-4b01-a581-67167fdf8659",
      "metricProperty_chr": "NA",
      "metricCount_lng": 1532
  }
]