import { createSlice } from "@reduxjs/toolkit";

export const subscriptionsSlice = createSlice({
  name: "subs",
  initialState: {
    coupons: [],
    products: [],
    priceData: [],
  },
  reducers: {
    setCoupons(state, action) {
      const { payload } = action;
      state.coupons = payload;
    },
    setProducts(state, action) {
      const { payload } = action;
      state.products = payload;
    },
    setPriceData(state, action) {
      const { payload } = action;
      state.priceData = payload;
    },
  },
});

export const { setCoupons, setProducts, setPriceData } =
  subscriptionsSlice.actions;

export const subscriptionsReducer = subscriptionsSlice.reducer;
