import React from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTheme } from "../../../ThemeContext";

const ThemePreference = () => {
  const { themePref, toggleTheme } = useTheme();

  const handleThemeChange = (event, newTheme) => {
    if (newTheme !== null) {
      toggleTheme(newTheme);
    }
  };

  return (
    <ToggleButtonGroup
      value={themePref}
      exclusive
      onChange={handleThemeChange}
      aria-label="theme selection"
    >
      <ToggleButton value="light" aria-label="Light">
        <Typography p={1}>Light</Typography>
      </ToggleButton>
      <ToggleButton value="dark" aria-label="Dark">
        <Typography p={1}>Dark</Typography>
      </ToggleButton>
      <ToggleButton value="system" aria-label="System">
        <Typography p={1}>System</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ThemePreference;
