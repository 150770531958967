export const biasRating = [
  {
    sortOrder_num: 1,
    category_id: "Left",
    categoryName_chr: "Left & Lean Left",
    description_chr: [
      "Sources with a Left AllSides Media Bias Rating™ display bias in ways that strongly align with liberal, progressive, or left-wing thought and/or policy agendas. Some of these sources may be considered left-wing news.",
      "Sources with a Lean Left AllSides Media Bias Rating™ display bias in ways that moderately align with liberal, progressive, or left-wing thought and/or policy agendas. A Lean Left bias is a moderately liberal rating on the political spectrum.",
    ],
    note_chr: null,
    isHidden_ysn: false,
  },
  {
    sortOrder_num: 3,
    category_id: "Center",
    categoryName_chr: "Center",
    description_chr:
      "Sources with a Center AllSides Media Bias Rating™ either do not show much political bias or display a balance of articles with left and right perspectives.",
    note_chr: null,
    isHidden_ysn: false,
  },
  {
    sortOrder_num: 2,
    category_id: "Right",
    categoryName_chr: "Right & Lean Right",
    description_chr: [
      "Sources with a Right AllSides Media Bias Rating™ display media bias in ways that strongly align with conservative, traditional, or right-wing thought and/or policy agendas. Some of these sources or writers may be considered right-wing news.",
      "Sources with a Lean Right AllSides Media Bias Rating™ display media bias in ways that moderately align with conservative, traditional, libertarian, or right-wing thought and/or policy agendas. A Lean Right bias is a moderately conservative rating on the political spectrum.",
    ],
    note_chr: null,
    isHidden_ysn: false,
  },
];
