import {
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { getFormattedDate } from "utils/time.util";
import LanguageIcon from "@mui/icons-material/Language";
import Table from "../../PublicCollection/components/Table";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ProfileAvatar from "components/base/ProfileAvatar";
import PageTitle from "components/layout/PageTitle";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import ClearIcon from "@mui/icons-material/Clear";

export default function Explore({
  publicCollections,
  setCurrentPublicCollection,
  filter,
  setFilter,
}) {
  const isMobile = useIsMobile();
  return (
    <Stack direction="column" mt={3} gap={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageTitle alignItems="center" sx={{ mt: 0 }}>
          {filter ? "Search Public" : "Explore"}
        </PageTitle>
        <TextField
          placeholder="Search public Collections"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ height: 30, color: "#5c6d7e" }} />
              </InputAdornment>
            ),
            endAdornment: filter && (
              <InputAdornment position="end">
                <ClearIcon
                  sx={{ cursor: "pointer", height: 30 }}
                  onClick={() => setFilter("")}
                />
              </InputAdornment>
            ),
          }}
          sx={{ width: "30%" }}
        />
      </Stack>
      {!filter && (
        <React.Fragment>
          <Typography variant="h4">For you</Typography>
          <Stack direction={{ xs: "column", sm: "row" }}>
            {publicCollections.slice(0, 4).map((collection) => {
              return (
                <Link
                  to={`/collections/public/${collection.publicCollectionID_chr}`}
                  style={{
                    textDecoration: "none",
                    display: "block",
                    flex: 1,
                  }}
                  key={collection.publicCollectionID_chr}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(12, minmax(1rem, 1fr))",
                      gridTemplateRows: "repeat(4, minmax(2rem, auto))",
                      p: 2,
                      backgroundColor: "secondary.main",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body4"
                      noWrap
                      sx={{
                        gridColumn: "span 10",
                      }}
                    >
                      {collection.collectionName_chr}
                    </Typography>
                    <LanguageIcon
                      sx={{
                        gridColumn: "span 2",
                        justifySelf: "right",
                        color: "secondary.dark",
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="grey.500"
                      sx={{ gridRow: "span 2", gridColumn: "span 12" }}
                    >{`${
                      collection.publicCollectionSource_Count_int
                        ? collection.publicCollectionSource_Count_int
                        : 0
                    } bookmarks`}</Typography>
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{
                        gridColumn: "span 7",
                      }}
                    >
                      <ProfileAvatar userId={collection.userId_chr} />
                      <Typography noWrap variant="body2">
                        <Tooltip title={collection.userName_chr}>
                          @{collection.userName_chr}
                        </Tooltip>
                      </Typography>
                    </Stack>
                    <Typography
                      color="grey"
                      noWrap
                      variant="body2"
                      sx={{
                        gridColumn: "span 5",
                        justifySelf: "right",
                      }}
                    >
                      {getFormattedDate(
                        new Date(collection.createdDateTime_dtm)
                      )}
                    </Typography>
                  </Paper>
                </Link>
              );
            })}
          </Stack>
        </React.Fragment>
      )}

      {!filter && <Typography variant="h4">Recently updated</Typography>}
      <Table
        setCurrentPublicCollection={setCurrentPublicCollection}
        publicCollections={publicCollections}
      />
    </Stack>
  );
}
