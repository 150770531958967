import { authAPI } from "services/apis.service";
import { GroupUser } from "../models";

export class GroupAdminService {
  #axios;
  constructor(axiosInstance) {
    this.#axios = axiosInstance;
  }

  async addUserToGroup(email, groupLicenseKey, organizationRole) {
    try {
      const { data } = await this.#axios.post(
        authAPI.createGroupUser,
        {
          email,
          groupLicenseKey,
          organizationRole,
        },
        {
          cache: false,
        }
      );
      return new GroupUser(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async removeUserFromGroup(users) {
    try {
      await this.#axios.post(
        authAPI.removeUserFromGroup,
        {
          userIds: users,
        },
        { cache: false }
      );
      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  async updateUsersRole(users, role) {
    try {
      await this.#axios.put(authAPI.updateUsersRole, {
        userIds: users,
        role,
      });
      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  async updateUsersLicenseLevel(users, licenseKey) {
    try {
      await this.#axios.put(authAPI.updateUsersLicenseLevel, {
        userIds: users,
        licenseLevelKey: licenseKey,
      });
      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  async bulkUploadUserCSV(file) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    try {
      await this.#axios.post(authAPI.bulkUpload, formData, {
        cache: false,
      });

      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      if (error.response != null) {
        const { data } = error.response;
        if (data != null && Array.isArray(data.errors)) {
          return {
            success: true,
            errors: error.response.data.errors,
          };
        }
      }
      return {
        success: false,
        error,
      };
    }
  }
}
