import { filter } from 'lodash';
import { axiosInstance } from "lib/axios";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toastError } from "utils/toast.util";

import {
  setSortModel as setSortModelContext,
  setFilterModel as setFilterModelContext,
  setTotalSize as setTotalSizeContext,
  setPaginationModel as setPaginationModelContext,
  setUsers,
  setAdminLoading,
  DEFAULT_PAGING,
} from "../context";
import { AdminService } from "../services";

export function useAdmin() {
  const dispatch = useDispatch();
  const adminService = new AdminService(axiosInstance);

  const {
    users,
    adminLoading,
    paginationModel,
    sortModel,
    filterModel,
    totalSize,
  } = useSelector((state) => state.admin);

  const isAdminLoading = useMemo(() => {
    return adminLoading > 0;
  }, [adminLoading]);

  function handleLoading(isLoading) {
    if (isLoading) {
      dispatch(setAdminLoading(adminLoading + 1));
    } else {
      dispatch(setAdminLoading(adminLoading > 0 ? adminLoading - 1 : 0));
    }
  }

  function setFilterModel(value) {
    dispatch(setFilterModelContext(value));
  }

  function setSortModel(value) {
    dispatch(setSortModelContext(value));
  }

  function setPaginationModel(value) {
    dispatch(setPaginationModelContext(value));
  }

  function setTotalSize(value) {
    dispatch(setTotalSizeContext(value));
  }

  function resetPage() {
    return {
      page: DEFAULT_PAGING.page,
      pageSize: paginationModel.pageSize,
    };
  }

  async function resetUsers() {
    setPaginationModel(resetPage());
    await getUsers(
      paginationModel.page,
      paginationModel.pageSize,
      filterModel.items[0],
      sortModel[0]
    );
  }

  async function getUsers(page, pageSize, filter, sort) {
    handleLoading(true);
    try {
      const usersData = await adminService.getUsers(
        page,
        pageSize,
        filter,
        sort
      );
      dispatch(setUsers(usersData.users));
      setTotalSize(usersData.total);
    } catch (error) {
      dispatch(setUsers([]));
      setTotalSize(0);
      setPaginationModel(DEFAULT_PAGING);
    } finally {
      handleLoading(false);
    }
  }

  async function updateUserStatus(user) {
    try {
      await adminService.changeUserStatus(user.id, !user.isDeleted);
      await getUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
    } catch (error) {
      toastError(`Unable to change user's status: @${user.userName}`);
    }
  }

  let newTab;

  async function impersonateUser(user) {
    try {
      const data = await adminService.impersonateUser(user.id);

      newTab = window.open(window.location.origin, "_blank");

      const loadHandler = () => {
        newTab.postMessage({ action: "setSessionStorage", payload: data }, "*");
        newTab.removeEventListener("load", loadHandler);
      };

      newTab.addEventListener("load", loadHandler);

      return () => {
        newTab.removeEventListener("load", loadHandler);
      };
    } catch (error) {
      toastError(`Unable to impersonate user: @${user.userName}`);
    }
  }

  async function createUser(email) {
    const response = await adminService.createUser(email);
    await getUsers(
      paginationModel.page,
      paginationModel.pageSize,
      filterModel.items[0],
      sortModel[0]
    );
    if (!response.success) {
      toastError(
        response.message != null
          ? response.message
          : `Unable to create user with ${email}`
      );
      return false;
    }
    return true;
  }

  async function createGroup(
    userId,
    organizationName,
    fullName,
    email,
    referenceNo,
    phoneNumber,
    city,
    state,
    country,
    planTypeData,
    expirationDate
  ) {
    const response = await adminService.setupGroup(
      userId,
      organizationName,
      fullName,
      email,
      referenceNo,
      phoneNumber,
      city,
      state,
      country,
      [...filter(planTypeData, (level) => level.noOfLicenses > 0)],
      expirationDate
    );
    console.log(response);
    if (!response.success) {
      toastError(`Could not create group for user: ${response.error.message}`);
      return false;
    }
    await getUsers(
      paginationModel.page,
      paginationModel.pageSize,
      filterModel.items[0],
      sortModel[0]
    );
    return true;
  }

  useEffect(() => {
    async function fetchData() {
      await getUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel.items[0],
        sortModel[0]
      );
    }
    fetchData();
  }, [paginationModel, filterModel, sortModel]);

  return {
    createGroup,
    createUser,
    filterModel,
    impersonateUser,
    isAdminLoading,
    paginationModel,
    resetUsers,
    setFilterModel,
    setSortModel,
    setPaginationModel,
    setTotalSize,
    sortModel,
    totalSize,
    updateUserStatus,
    users,
  };
}
