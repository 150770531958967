import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "msal";
import { Provider } from "react-redux";
import store from "context/store";
import { ThemeProvider } from "ThemeContext";

const msalInstance = new PublicClientApplication(msalConfig);

const intitalize = async () => {
  await msalInstance.initialize();
};

intitalize();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </MsalProvider>
);

reportWebVitals();
