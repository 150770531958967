import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { setupTheme } from "./theme";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export function useIsSystemDarkMode() {
  const darkThemeMq = useMemo(
    () => window.matchMedia("(prefers-color-scheme: dark)"),
    []
  );

  const [isSystemDarkMode, setIsSystemDarkMode] = useState(darkThemeMq.matches);

  useEffect(() => {
    const darkThemeMqListener = (event) => {
      setIsSystemDarkMode(event.matches);
    };
    darkThemeMq.addEventListener("change", darkThemeMqListener);
    return () => darkThemeMq.removeEventListener("change", darkThemeMqListener);
  }, [darkThemeMq]);

  return {
    isSystemDarkMode
  }
}

export const ThemeProvider = ({ children }) => {
  const { isSystemDarkMode } = useIsSystemDarkMode();

  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      return savedTheme;
    }
    return "system";
  };

  const [themePref, setThemePref] = useState(getInitialTheme);

  const theme = useMemo(() => {
    if (themePref === "system") {
      return setupTheme(isSystemDarkMode ? "dark" : "light");
    }
    return setupTheme(themePref);
  }, [themePref, isSystemDarkMode]);


  useEffect(() => {
    localStorage.setItem("theme", themePref);
  }, [themePref]);

  const toggleTheme = (newTheme) => {
    setThemePref(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ themePref, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
