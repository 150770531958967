import React from "react";
import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSummaryInfoData,
} from "context/features/dataSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TableRow from "./TableRow";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import PublicCollectionTable from "features/Collections/components/PublicCollectionTable";

export default function BookmarksTable({
  rows,
  setRows,
}) {
  const dispatch = useDispatch();
  const { bookmarkedData } = useSelector((state) => state.data);

  const location = useLocation();
  const isPublicCollectionSrc = location.pathname.includes("/public");

  const openSidebar = (data) => {
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    dispatch(setCollectionSourceId(data.collectionSourceID_ids));
    dispatch(setCollectionId(data.collectionID_lng));

    dispatch(
      setSummaryInfoData({
        publisher: data.sourceName_chr,
        title: data.title_chr,
        published_date: data.sourcePublishedDateTime_dtm,
        url: data.sourceUrl_chr,
      })
    );
    dispatch(setIsCitationAvailable(data.isCitationAvailable_ysn));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable_ysn));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  useEffect(() => {
    if (bookmarkedData.id && rows.length && bookmarkedData.switch) {
      const bookmarkedNewsData = rows.filter(
        (row) => row.sourceUrl_chr !== bookmarkedData.id
      );
      setRows(bookmarkedNewsData);
    }
  }, [
    bookmarkedData.collectionId,
    bookmarkedData.collectionSourceId,
    bookmarkedData.id,
  ]);

  return (
    <PublicCollectionTable rows={rows} />
  );
}
